export interface Product {
  id?: string;
  name: string;
  description?: string;
  unit: ProductUnitEnum;
  package_size: number;
  image?: string;
  mix_with_water?: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface ProductInventory extends Product {
  quantity_on_hand: number;
  par_level: number;
  max_quantity: number;
}

export interface InventoryProduct extends Product {
  quantity_on_hand?: number;
  max_quantity?: number;
  par_level?: number;
}

export enum ProductUnitEnum {
  GRAM = 'g',
  UNIT = 'unit',
}
