import { Component } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { InventoryListComponent } from '../inventory-list/inventory-list.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inventory-home',
  standalone: true,
  imports: [CommonModule, MainPageComponent, InventoryListComponent],
  templateUrl: './inventory-home.component.html',
  styleUrl: './inventory-home.component.scss',
  animations: []
})
export class InventoryHomeComponent {

}
