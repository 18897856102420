import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { Sale, SaleDetails, SaleItem } from '../_models/sales.models';
import { State } from '../_store/sale.reducer';
import { QueryService } from '../../shared/_services/query.service';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) { this.supabase = this.sbService.supabase }

  async getSales(config: State['query'] | undefined): Promise<Sale[]> {
    let query = this.supabase.from('sales').select('*');
    if (config) {
      query = this.queryService.applyQueryConfig(query, config);
    }
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Sales');
      throw error;
    };
    return data;
  }

  async getSaleById(id: string): Promise<Sale> {
    const { data, error } = await this.supabase.from('sales').select('*').eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Sale by Id');
      throw error;
    };
    return data;
  }

  async getSaleItemsById(id: string): Promise<SaleItem[]> {
    const { data, error } = await this.supabase.from('sale_items').select(`
      recipe_id,
      quantity,
      size,
      unit_price
    `).eq('sale_id', id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Sale Items by Id');
      throw error;
    };
    return data;
  }

  async getSaleDetails(id?: string, service_id?: string): Promise<SaleDetails> {
     const req = this.supabase.from('sales').select(`
      id,
      sold_at,
      sales_mode,
      customer:customers(id, name),
      customer_id,
      total_sold,
      machine_counter,
      machine:machines(id, name),
      machine_id,
      items:sale_items(recipe:recipes(id, name), recipe_id, quantity, size, unit_price)
    `);
    if(id) {
      req.eq('id', id).single();
    } else if (service_id) {
      req.eq('service_id', service_id).single();
    }
    const { data, error } = await req;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Sale Details by Id or Service Id');
      throw error;
    };
    return data as any as SaleDetails;
  }

  async upsertSale(sale: Sale) {
    const sale_details: Sale = {
      id: sale.id || undefined,
      sold_at: sale.sold_at,
      machine_id: sale.machine_id,
      service_id: sale.service_id,
      customer_id: sale.customer_id,
      sales_mode: sale.sales_mode,
      total_sold: sale.total_sold,
      machine_counter: sale.machine_counter
    };
    const sale_items_details: SaleItem[] = sale.items ? sale.items.map(item => ({
      sale_id: item.sale_id,
      recipe_id: item.recipe_id,
      quantity: item.quantity,
      size: item.size,
      unit_price: item.unit_price
    })) : [];
    const { data, error } = await this.supabase.rpc('upsert_full_sale', { sale_details, sale_items_details });
    if (error) {
      this.apiErrorService.handleApiError(error, 'Upsert Sale');
      throw error;
    };
    return data;
  }
}
