import { Action } from "@ngrx/store";

export interface NavigationAction {
  name: string;
  icon: string;
  url?: string;
  action?: Action;
}

export enum NavigationActionTypes {
  // Inventory Actions
  AddTransaction = '[Navigation] Add Transaction',
  AddProduct = '[Navigation] Add Product',
  AddWarehouse = '[Navigation] Add Warehouse',
  // Issue Actions
  ReportIssue = '[Navigation] Report Issue',
  ReportIssueByMachineId = '[Navigation] Report Issue By Machine Id',
  // Machine Actions
  AddMachine = '[Navigation] Add Machine',
  // Recipe Actions
  AddRecipe = '[Navigation] Add Recipe',
  // Sale Actions
  AddSale = '[Navigation] Add Sale',
  // Service Actions
  ServiceMachine = '[Navigation] Service Machine',
  ServiceMachineById = '[Navigation] Service Machine By Id',


}
