import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Tenant } from './tenant.model';

export const TenantActions = createActionGroup({
  source: 'Tenant',
  events: {
    'Load Tenants': emptyProps(),
    'Load Tenants Success': props<{ tenants: Tenant[] }>(),
    'Load Tenants Failure': props<{ error: any }>(),
    'Add Tenant': props<{ tenant: Tenant }>(),
    'Upsert Tenant': props<{ tenant: Tenant }>(),
    'Add Tenants': props<{ tenants: Tenant[] }>(),
    'Upsert Tenants': props<{ tenants: Tenant[] }>(),
    'Update Tenant': props<{ tenant: Update<Tenant> }>(),
    'Update Tenants': props<{ tenants: Update<Tenant>[] }>(),
    'Delete Tenant': props<{ id: string }>(),
    'Delete Tenants': props<{ ids: string[] }>(),
    'Clear Tenants': emptyProps(),
  }
});
