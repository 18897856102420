<app-card-list-item [routerLink]="['/services', 'service_guide', service.id, 'edit']">
  <div class="flex-shrink-0">
    <span [ngClass]="serviceStatusColor" class="block h-4 w-4 rounded-full bg-green-500 mr-2"></span>
  </div>
  <div class="flex-1 px-4">
    <div class="text-sm font-medium">{{ machine.name }}</div>
    <div class="text-xs text-gray-600">{{ service.started_at | date:'short' }}</div>
  </div>
  <div class="flex-shrink-0 text-gray-500">
    <i class="fas fa-chevron-right"></i>
  </div>
</app-card-list-item>
