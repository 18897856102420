import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { Observable, EMPTY, of, from } from 'rxjs';
import { MachineActions } from './machine.actions';
import { MachinesService } from '../_services/machines.service';


@Injectable()
export class MachineEffects {

  loadMachines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.loadMachines),
      concatMap(() =>
        from(this.service.getMachines()).pipe(
          map(machines => MachineActions.loadMachinesSuccess({ machines })),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    );
  });

  addMachine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.addMachine),
      concatMap((action) =>
        from(this.service.createMachine(action.machine)).pipe(
          map(_null => MachineActions.loadMachines()),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    );
  });

  getMachineById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.getMachineById),
      concatMap((action) =>
        from(this.service.getMachineById(action.id)).pipe(
          map(machine => MachineActions.getOneSuccess({ machine })),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    )
  });

  updateMachine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.updateMachine),
      concatMap((action) =>
        from(this.service.updateMachine(action.machine)).pipe(
          map(_null => MachineActions.purgeMachine({ id: action.machine.id as string })),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    )
  });

  getMachineRecipes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.getMachineRecipes),
      concatMap((action) =>
        from(this.service.getMachineRecipes(action.id)).pipe(
          map(recipes => MachineActions.getMachineRecipesSuccess({ recipes })),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    )
  });

  upsertMachineRecipes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MachineActions.upsertMachineRecipes),
      concatMap((action) =>
        from(this.service.upserMachineRecipes(action.recipes)).pipe(
          map(_null => MachineActions.apiSuccess()),
          catchError(error => of(MachineActions.apiFailure({ error })))
        )
      )
    )
  });

  constructor(private actions$: Actions, private service: MachinesService) {}
}
