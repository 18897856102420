import { Injectable } from '@angular/core';
import { NavigationAction, NavigationActionTypes } from '../_models/navigation.models';

@Injectable({
  providedIn: 'root'
})
export class MenuActionsService {
  populateMenu(actionItems: NavigationActionTypes[], id?: string): NavigationAction[] {
    let menu: NavigationAction[] = [];
    actionItems.forEach(action => menu.push(this.populateAction(action, id)));
    return menu;
  }

  populateAction(action: NavigationActionTypes, id?: string): NavigationAction {
    let navigationAction: NavigationAction = { name: '', icon: '' };
    switch (action) {
      // Inventory Actions
      case NavigationActionTypes.AddTransaction:
        navigationAction.name = 'Add Transaction';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/inventory/transactions/new';
        break;
      case NavigationActionTypes.AddProduct:
        navigationAction.name = 'Add Product';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/inventory/products/new';
        break;
      case NavigationActionTypes.AddWarehouse:
        navigationAction.name = 'Add Warehouse';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/inventory/warehouses/new';
        break;
      // Issue Actions
      case NavigationActionTypes.ReportIssue:
        navigationAction.name = 'Report Issue';
        navigationAction.icon = 'fa-triangle-exclamation';
        navigationAction.url = '/issues/new';
        break;
      case NavigationActionTypes.ReportIssueByMachineId:
        navigationAction.name = 'Report Issue By Machine Id';
        navigationAction.icon = 'fa-triangle-exclamation';
        // navigationAction.url = '/issues/new/' + id;
        break;
      // Machine Actions
      case NavigationActionTypes.AddMachine:
        navigationAction.name = 'Add Machine';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/machines/new';
        break;
      // Recipe Actions
      case NavigationActionTypes.AddRecipe:
        navigationAction.name = 'Add Recipe';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/recipes/new';
        break;
      // Sale Actions
      case NavigationActionTypes.AddSale:
        navigationAction.name = 'Add Sale';
        navigationAction.icon = 'fa-plus';
        navigationAction.url = '/sales/new';
        break;
      // Service Actions
      case NavigationActionTypes.ServiceMachine:
        navigationAction.name = 'Service Machine';
        navigationAction.icon = 'fa-gear';
        navigationAction.url = '/services/service_guide';
        break;
      case NavigationActionTypes.ServiceMachineById:
        navigationAction.name = 'Service Machine By Id';
        navigationAction.icon = 'fa-gear';
        // navigationAction.url = '/services/service_guide/' + id;
        break;
      default:
        break;
    }
    return navigationAction;
  }
}
