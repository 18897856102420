import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgZone, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ReactiveFormsModule, SelectControlValueAccessor } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyFieldSelectProps, FormlySelectModule } from '@ngx-formly/core/select';
import { take } from 'rxjs';
import { FormlyFieldProps } from '../form-field-wrapper/form-field-wrapper.component';


interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
  compareWith: (o1: any, o2: any) => boolean;
}

export interface FormlySelectFieldConfig extends FormlyFieldConfig<SelectProps> {
  type: 'select' | Type<FormlySelectComponent>;
}

@Component({
  selector: 'app-formly-select',
  standalone: true,
  imports: [CommonModule, FormlyModule, FormlySelectModule, ReactiveFormsModule],
  templateUrl: './formly-select.component.html',
  styleUrl: './formly-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySelectComponent extends FieldType<FieldTypeConfig<SelectProps>> {

  constructor(private ngZone: NgZone, hostContainerRef: ViewContainerRef) { super() }

  override defaultOptions = {
    props: {
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
    },
  };
  @ViewChild(SelectControlValueAccessor) set selectAccessor(s: any) {
    if (!s) {
      return;
    }

    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }

    s.writeValue = (value: any) => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable
          .asObservable()
          .pipe(take(1))
          .subscribe(() => {
            if (
              id !== s._idCounter &&
              s._getOptionId(value) === null &&
              s._elementRef.nativeElement.selectedIndex !== -1
            ) {
              writeValue(value);
            }
          });
      }
    };
  }
}
