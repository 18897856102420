import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Sale, SaleDetails, SaleItem } from '../_models/sales.models';
import { SaleActions } from './sale.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const salesFeatureKey = 'sales';

export interface State extends EntityState<Sale> {
  // additional entities state properties
  details: SaleDetails | null;
  loading: boolean;
  detailsLoading: boolean;
  error: any;
  query: QueryStore<Sale>;
}

export const adapter: EntityAdapter<Sale> = createEntityAdapter<Sale>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  items: [],
  details: null,
  loading: false,
  detailsLoading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 5,
    },
    order: {
      column: 'sold_at',
      order: Order.DESCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(SaleActions.loadSales, (state, action) => ({ ...state, loading: true, query: action.query ? action.query : state.query })),
  on(SaleActions.loadSalesSuccess,
    (state, action) => adapter.setAll(action.sales, { ...state, loading: false })
  ),
  on(SaleActions.getSaleById, (state, _action) => ({ ...state, loading: true })),
  on(SaleActions.updateSale, (state, _action) => ({ ...state, loading: true })),
  on(SaleActions.apiSuccess, (state, _action) => ({ ...state, loading: false })),
  on(SaleActions.apiFailure, (state, _action) => ({ ...state, loading: false, detailsLoading: false })),
  on(SaleActions.getSaleDetailsById, (state, _action) => ({ ...state, details: null, detailsLoading: true })),
  on(SaleActions.getSaleDetailsByServiceId, (state, _action) => ({ ...state, details: null, detailsLoading: true })),
  on(SaleActions.getOneSuccess, (state, action) => adapter.addOne(action.sale, { ...state, loading: false})),
  on(SaleActions.getSaleDetailsSuccess, (state, action) => ({ ...state, details: action.details, detailsLoading: false })),
  on(SaleActions.purgeSale, (state, action) => adapter.removeOne(action.id, { ...state, loading: false, details: null })),
  on(SaleActions.addSale,
    (state, action) => adapter.addOne(action.sale, state)
  ),
  on(SaleActions.upsertSale,
    (state, action) => adapter.upsertOne(action.sale, state)
  ),
  on(SaleActions.addSales,
    (state, action) => adapter.addMany(action.sales, state)
  ),
  on(SaleActions.upsertSales,
    (state, action) => adapter.upsertMany(action.sales, state)
  ),
  on(SaleActions.updateSales,
    (state, action) => adapter.updateMany(action.sales, state)
  ),
  on(SaleActions.deleteSale,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(SaleActions.deleteSales,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(SaleActions.clearSales,
    state => adapter.removeAll(state)
  ),
);

export const salesFeature = createFeature({
  name: salesFeatureKey,
  reducer,
  extraSelectors: ({ selectSalesState }) => ({
    ...adapter.getSelectors(selectSalesState)
  }),
});

export const {
  selectIds: selectSaleIds,
  selectEntities: selectSaleEntities,
  selectAll: selectSales,
  selectDetails: selectSaleDetails,
  selectLoading: selectSalesLoading,
  selectDetailsLoading: selectSaleDetailsLoading,
  selectTotal,
} = salesFeature;

export const selectSale = (id: string) => createSelector(
  selectSaleEntities,
  entities => entities[id]
);
