import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { RecipesListItemComponent } from '../recipes-list-item/recipes-list-item.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Observable, take } from 'rxjs';
import { Recipe } from '../../_models/recipes.models';
import { selectRecipes, selectRecipesLoading } from '../../_store/recipe.reducer';
import { RecipeActions } from '../../_store/recipe.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-recipes-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, RecipesListItemComponent, AppLoadingDirective],
  templateUrl: './recipes-list.component.html',
  styleUrl: './recipes-list.component.scss'
})
export class RecipesListComponent implements OnInit {
  recipes$: Observable<Recipe[]> = this.store.select(selectRecipes);
  loading$: Observable<boolean> = this.store.select(selectRecipesLoading);

  constructor(
    private store: Store
  ) {}

  ngOnInit(): void {
    this.recipes$.pipe(take(1)).subscribe(recipes => {
      if(recipes.length < 2) {
        this.store.dispatch(RecipeActions.loadRecipes())
      }
    });
  }
}
