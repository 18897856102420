@if (serviceMachineId) {
  <div *appLoading="loading$ | async"></div>
  @if ((loading$ | async) === false) {
    <div class="overflow-y-scroll max-h-[calc(100vh-12rem)] rounded-lg">
      <form [formGroup]="transactionForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md rounded-lg px-4 pt-6 pb-8 mb-4">
        <formly-form [form]="transactionForm" [fields]="fields" [options]="options" [model]="model"></formly-form>
        <app-submit-button>{{ isNew ? 'Create' : 'Save' }}</app-submit-button>
      </form>
    </div>
  }
} @else {
  <app-main-page>
    <div *appLoading="loading$ | async"></div>
    @if ((loading$ | async) === false) {
      <form [formGroup]="transactionForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md rounded-lg px-4 pt-6 pb-8 mb-4">
        <formly-form [form]="transactionForm" [fields]="fields" [options]="options" [model]="model"></formly-form>
        <app-submit-button>{{ isNew ? 'Create' : 'Save' }}</app-submit-button>
      </form>
    }
  </app-main-page>
}
