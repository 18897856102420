import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProductInventory } from '../../products/_models/products.models';

export const InventoryActions = createActionGroup({
  source: 'Inventory',
  events: {
    'Load Inventory': emptyProps(),
    'Load Inventory Success': props<{ inventory: ProductInventory[] }>(),
    'Load Inventory By Warehouse': props<{ warehouseId: string }>(),
    'Api Failure': props<{ error: any }>(),
  }
});
