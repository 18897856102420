<app-card-list-item [routerLink]="['/issues', issue.id]">
  <div class="flex-shrink-0">
    <span [ngClass]="issueStatusColor" class="block h-4 w-4 rounded-full bg-green-500 mr-2"></span>
  </div>
  <div class="flex-1 px-4">
    <div class="text-sm font-medium">{{ machineId ? (issue.service_issue.replaceAll('_', ' ') | titlecase) : machine.name }}</div>
    <div class="text-xs text-gray-600">{{ issue.created_at | date:'short' }}</div>
  </div>
  <div class="flex-shrink-0 text-gray-500">
    <i class="fas fa-chevron-right"></i>
  </div>
</app-card-list-item>
