import { AfterViewInit, Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MenubarComponent } from './navigation/_components/menubar/menubar.component';
import { HeaderComponent } from './navigation/_components/header/header.component';
import { Observable, filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { slideInAnimation } from './shared/_services/animations';
import { ModalsService } from './modals/_services/modals.service';
import { selectModalComponent } from './modals/_store/modal.reducer';
import { ModalActions } from './modals/_store/modal.actions';
import { ErrorModalComponent } from './modals/_components/error-modal/error-modal.component';
import { ModalComponentEnum } from './modals/_models/modal.models';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MenubarComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [slideInAnimation]
})
export class AppComponent implements AfterViewInit {
  @HostBinding('@routeAnimations') public animatePage = true;
  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer!: ViewContainerRef;

  title = 'Cafebec';
  currentUrl = '';
  hideRoutes = ['/login'];
  previousRank: number = -1;
  private touchStartX = 0;
  private touchEndX = 0;
  modalComponent$: Observable<any> = this.store.select(selectModalComponent).pipe(map(component => component ? this.modalsService.componentMappings[component] : null));

  constructor(private router: Router, private store: Store, private location: Location, private modalsService: ModalsService) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
  }

  ngAfterViewInit(): void {
    this.modalComponent$.subscribe(component => this.loadComponent(component));
    document.addEventListener('touchstart', function(e) {
      const isTextInput = (node: any) => { return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1; }
        if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
          e.stopImmediatePropagation();
          (document.activeElement as any).blur();
          setTimeout(() => {
            (document.activeElement as any).blur()
          }, 10);
        }
    }, false);
  }

  loadComponent(componentClass: any) {
    if (componentClass) {
      setTimeout(() => {
        this.modalContainer.clear();
        this.modalContainer.createComponent(componentClass);
      }, 1);
    }
  }

  get showMenu(): boolean {
    return !this.hideRoutes.includes(this.currentUrl);
  }

  prepareRoute(outlet: RouterOutlet) {
    const currentRouteData = outlet.activatedRouteData;

    const currentRank = currentRouteData['rank'] || 0;
    const previousRank = this.previousRank;

    previousRank !== currentRank ? this.previousRank = currentRank : null;

    const direction = currentRank < previousRank ? 'left' : 'right';

    return {
      value: currentRouteData['animation'],
      params: {
        offsetXEnter: direction === 'left' ? '-100' : '100',
        offsetXLeave: direction === 'left' ? '100' : '-100'
      }
    };
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
    this.touchEndX = this.touchStartX;
  }

  onTouchMove(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX;
  }

  onTouchEnd() {
    if (this.touchStartX - this.touchEndX > 100) {
      // Swipe left - Navigate forward
    } else if (this.touchEndX - this.touchStartX > 100) {
      // Swipe right - Navigate backward
      this.navigateBack();
    }
  }

  private navigateBack(): void {
    this.location.back();
  }
}
