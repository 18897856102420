<select
  *ngIf="props.multiple; else singleSelect"
  class="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-500 placeholder:text-gray-400 leading-tight focus:outline-none focus:shadow-md focus:ring-1"
  multiple
  [ngClass]="{'border-red-400': showError, 'border-gray-300': !showError}"
  [formControl]="formControl"
  [compareWith]="props.compareWith"
  [class.is-invalid]="showError"
  [formlyAttributes]="field"
  [attr.aria-describedby]="id + '-formly-validation-error'"
  [attr.aria-invalid]="showError"
>
  <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
    <ng-container *ngFor="let opt of opts">
      <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
        {{ opt.label }}
      </option>
      <ng-template #optgroup>
        <optgroup [label]="opt.label">
          <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
            {{ child.label }}
          </option>
        </optgroup>
      </ng-template>
    </ng-container>
  </ng-container>
</select>

<ng-template #singleSelect>
  <select
    class="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-500 placeholder:text-gray-400 leading-tight focus:outline-none focus:shadow-md focus:ring-1"
    [ngClass]="{'border-red-400': showError, 'border-gray-300': !showError}"
    [formControl]="formControl"
    [compareWith]="props.compareWith"
    [class.is-invalid]="showError"
    [formlyAttributes]="field"
    [attr.aria-describedby]="id + '-formly-validation-error'"
    [attr.aria-invalid]="showError"
  >
    <option *ngIf="props.placeholder" [ngValue]="undefined">{{ props.placeholder }}</option>
    <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
      <ng-container *ngFor="let opt of opts">
        <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
          {{ opt.label }}
        </option>
        <ng-template #optgroup>
          <optgroup [label]="opt.label">
            <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
              {{ child.label }}
            </option>
          </optgroup>
        </ng-template>
      </ng-container>
    </ng-container>
  </select>
</ng-template>
