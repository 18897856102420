import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { QueryService } from '../../shared/_services/query.service';
import { State } from '../_store/transaction.reducer';
import { Transaction, TransactionItem } from '../_models/transactions.model';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getTransactions(config: State['query'] | undefined): Promise<Transaction[]> {
    let query = this.supabase.from('transactions').select(fullTransactionSelect);
    if (config) {
      query = this.queryService.applyQueryConfig(query, config);
    }
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Transactions');
      throw error;
    };
    return data as any as Transaction[];
  }

  async getTransactionById(id: string): Promise<Transaction> {
    const { data, error } = await this.supabase.from('transactions').select(fullTransactionSelect).eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Transaction by Id');
      throw error;
    };
    return data as any as Transaction;
  }

  async getTransactionByServiceId(service_id: string): Promise<Transaction> {
    const { data, error } = await this.supabase.from('transactions').select(fullTransactionSelect).eq('service_id', service_id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Transaction by Service Id');
      throw error;
    };
    return data as any as Transaction;
  }

  async upsertTransaction(transaction: Transaction) {
    const { transaction_details, transaction_items_details } = this.mapTransactionToRequest(transaction);
    const { data, error } = await this.supabase.rpc('upsert_full_transaction', { transaction_details, transaction_items_details });
    if (error) {
      this.apiErrorService.handleApiError(error, 'Upsert Transaction');
      throw error;
    };
    return data;
  }

  mapTransactionToRequest(transaction: Transaction) {
    const transaction_details: Transaction = {
      id: transaction.id || undefined,
      transaction_date: transaction.transaction_date,
      source_warehouse_id: transaction.source_warehouse_id || undefined,
      destination_warehouse_id: transaction.destination_warehouse_id || undefined,
      supplier_id: transaction.supplier_id || undefined,
      customer_id: transaction.customer_id || undefined,
      service_id: transaction.service_id || undefined,
      transaction_type: transaction.transaction_type,
      created_at: transaction.created_at || undefined,
      updated_at: transaction.updated_at || undefined,
    };
    let transaction_items_details: TransactionItem[] = [];
    transaction_items_details = transaction.items?.map((ti, i) => ({
      id: i,
      product_id: ti.product_id,
      quantity: ti.quantity,
      price: ti.price || undefined
    })) || [];
    return { transaction_details, transaction_items_details };
  }
}

const fullTransactionSelect = `
    id,
    transaction_date,
    source_warehouse_id,
    source_warehouse: warehouses!transactions_source_warehouse_id_fkey(id, name),
    destination_warehouse_id,
    destination_warehouse: warehouses!transactions_destination_warehouse_id_fkey(id, name),
    supplier_id,
    customer_id,
    service_id,
    transaction_type,
    created_at,
    updated_at,
    items: transaction_items (
      id,
      transaction_id,
      product_id,
      product: products (id, name, package_size),
      quantity,
      price,
      created_at,
      updated_at
    )
  `;
