import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Recipe } from '../_models/recipes.models';
import { RecipeActions } from './recipe.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const recipesFeatureKey = 'recipes';

export interface State extends EntityState<Recipe> {
  // additional entities state properties
  loading: boolean;
  error: string | null;
  query: QueryStore<Recipe>;
}

export const adapter: EntityAdapter<Recipe> = createEntityAdapter<Recipe>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 100,
    },
    order: {
      column: 'name',
      order: Order.ASCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(RecipeActions.loadRecipes,
    (state, action) => ({ ...state, loading: true })
  ),
  on(RecipeActions.loadRecipesSuccess,
    (state, action) => adapter.setAll(action.recipes, {...state, loading: false })
  ),
  on(RecipeActions.getRecipeById, (state, _action) => ({ ...state, loading: true })),
  on(RecipeActions.updateRecipe, (state, _action) => ({ ...state, loading: true })),
  on(RecipeActions.getOneSuccess,
    (state, action) => adapter.addOne(action.recipe, { ...state, loading: false })
  ),
  on(RecipeActions.apiSuccess, (state, _action) => ({ ...state, loading: false })),
  on(RecipeActions.apiFailure, (state, _action) => ({ ...state, loading: false })),
  // on(RecipeActions.addRecipe,
  //   (state, action) => adapter.addOne(action.recipe, state)
  // ),
  on(RecipeActions.upsertRecipe,
    (state, action) => adapter.upsertOne(action.recipe, state)
  ),
  on(RecipeActions.addRecipes,
    (state, action) => adapter.addMany(action.recipes, state)
  ),
  on(RecipeActions.upsertRecipes,
    (state, action) => adapter.upsertMany(action.recipes, state)
  ),
  on(RecipeActions.updateRecipes,
    (state, action) => adapter.updateMany(action.recipes, state)
  ),
  on(RecipeActions.purgeRecipe,
    (state, action) => adapter.removeOne(action.id, {...state, loading: false,})
  ),
  on(RecipeActions.deleteRecipes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RecipeActions.clearRecipes,
    state => adapter.removeAll(state)
  ),
);

export const recipesFeature = createFeature({
  name: recipesFeatureKey,
  reducer,
  extraSelectors: ({ selectRecipesState }) => ({
    ...adapter.getSelectors(selectRecipesState)
  }),
});

export const {
  selectIds: selectRecipeIds,
  selectEntities: selectRecipeEntities,
  selectAll: selectRecipes,
  selectTotal,
  selectLoading: selectRecipesLoading,
  selectError: selectRecipesError,
  selectQuery: selectRecipesQuery
} = recipesFeature;

export const selectRecipe = (id: string) => createSelector(
  selectRecipeEntities,
  entities => entities[id]
)
