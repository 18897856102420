<app-main-page>
  @if(transaction && (loading$ | async) === false) {
    <app-card-lg [title]="transaction.transaction_type | titlecase">
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-calendar-day"></i>
        </div>
        <div>
          {{ transaction.transaction_date | date: 'short' }}
        </div>
      </div>
      <div *ngIf="transaction.destination_warehouse" class="flex text-gray-500 text-sm">
        <div class="w-12 text-center">
          <i class="fa-solid fa-arrow-right mr-1"></i>
          <i class="fa-solid fa-warehouse"></i>
        </div>
        <div>
          {{ transaction.destination_warehouse.name }}
        </div>
      </div>
    </app-card-lg>
    <div class="flex items-center mb-4">
      <app-submit-button class="flex-1 mr-4" [style]="ButtonStyle.PRIMARY" [routerLink]="['edit']">Edit</app-submit-button>
      <app-submit-button class="flex-1" [style]="ButtonStyle.DANGER" (click)="delete()">Delete</app-submit-button>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
      @for (item of transaction.items; track item) {
        <div class="bg-white shadow-sm rounded-lg p-3 w-full">
          <div>
            <div class="text-sm font-medium">{{ item.product?.name || 'Product' }}</div>
            <div class="flex justify-between align-center items-end">
              <div class="text-2xl text-gray-600 w-full">{{(item.product && item.product.package_size) ? ((item.quantity / item.product.package_size) | number: '1.0-2') : item.quantity + 'g'}}</div>
              <div *ngIf="item.price" class="text-2xl text-gray-400"> {{ item.price / 100 | currency }}</div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</app-main-page>
