import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { Observable, takeWhile } from 'rxjs';
import { ProductActions } from '../../_store/product.actions';
import { selectProduct, selectProductsLoading } from '../../_store/product.reducer';
import { Store } from '@ngrx/store';
import { Product } from '../../_models/products.models';
import { ButtonStyle } from '../../../shared/_models/styles.enums';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})
export class ProductComponent {
  id: Product['id'];
  product$!: Observable<Product | undefined>;
  product!: Product | undefined;
  loading$: Observable<boolean> = this.store.select(selectProductsLoading);

  constructor(private route: ActivatedRoute, private store: Store) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.product$ = this.store.select(selectProduct(this.id));
    this.product$.pipe(takeWhile(p => p == undefined, true)).subscribe(product => {
      if (product) {
        this.product = product;
      } else {
        this.store.dispatch(ProductActions.getProductById({ id: this.id as string }));
      }
    });
  }

  delete() {

  }

  get ButtonStyle() {
    return ButtonStyle;
  }
}
