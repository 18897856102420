<app-main-page>
  @if (warehouse && !(loading$ | async)) {
    <app-card-lg [title]="warehouse.name">
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-location-dot"></i>
        </div>
        <div>
          {{ warehouse.location }}
        </div>
      </div>
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-file-lines"></i>
        </div>
        <div>
          {{ warehouse.description }}
        </div>
      </div>
    </app-card-lg>
    <div class="flex items-center mb-4">
      <app-submit-button class="flex-1 mr-4" [style]="ButtonStyle.PRIMARY" [routerLink]="['edit']">Edit</app-submit-button>
      <app-submit-button class="flex-1" [style]="ButtonStyle.DANGER" (click)="delete()">Delete</app-submit-button>
    </div>
    <app-inventory-list
      [warehouseId]="warehouse.id"
    ></app-inventory-list>
  }
</app-main-page>
