import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { ButtonStyle } from '../../../_models/styles.enums';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';

@Component({
  selector: 'app-formly-repeat-section',
  standalone: true,
  imports: [CommonModule, FormlyModule, SubmitButtonComponent],
  templateUrl: './formly-repeat-section.component.html',
  styleUrl: './formly-repeat-section.component.scss'
})
export class FormlyRepeatSectionComponent extends FieldArrayType {

  scrollToBottom(): void {
    setTimeout(() => {
      const div = document.querySelector('#main-scroll');
      if (div) {
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 1);
  }

  get buttonStyle() {
    return ButtonStyle;
  }
}
