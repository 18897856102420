import { Injectable } from '@angular/core';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { SupabaseClient } from '@supabase/supabase-js';
import { State } from '../_store/service.reducer';
import { Service, ServiceStep } from '../_models/service.models';
import { QueryService } from '../../shared/_services/query.service';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) { this.supabase = this.sbService.supabase }

  async getServices(config: State['query'] | undefined): Promise<Service[]> {
    let query = this.supabase.from('services').select('*');
    if (config) {
      query = this.queryService.applyQueryConfig(query, config);
    }
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Services');
      throw error;
    };
    return data;
  }

  async getServiceById(id: string): Promise<Service> {
    const { data, error } = await this.supabase.from('services')
    .select(`
      id,
      machine_id,
      service_status,
      started_at,
      completed_at,
      steps:service_steps(id, name, step_data, started_at, completed_at)
    `).eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Service by Id');
      throw error;
    };
    return this.mapServiceData(data);
  }

  async upsertService(service: Service): Promise<string> {
    const service_details: Service = {
      id: service.id || undefined,
      machine_id: service.machine_id,
      service_status: service.service_status,
      started_at: service.started_at,
      completed_at: service.completed_at || undefined
    }
    const service_steps_details: ServiceStep[] = service.steps ? service.steps.map(step => ({
      id: step.id,
      name: step.name,
      step_data: { action: step.action, checklist: step.checklist, disableScroll: step.disableScroll || undefined },
      started_at: step.started_at,
      completed_at: step.completed_at
    })): [];
    const { data, error } = await this.supabase.rpc('upsert_full_service', { service_details, service_steps_details });
    if (error) {
      this.apiErrorService.handleApiError(error, 'Upsert Service');
      throw error;
    };
    return data.id;
  }

  mapServiceData(data: Service): Service {
    return {
      id: data.id,
      machine_id: data.machine_id,
      service_status: data.service_status,
      started_at: data.started_at,
      completed_at: data.completed_at,
      steps: data.steps ? data.steps.map(step => ({
        id: step.id,
        name: step.name,
        action: step.step_data?.action || undefined,
        checklist: step.step_data?.checklist || undefined,
        disableScroll: step.step_data?.disableScroll || undefined,
        started_at: step.started_at,
        completed_at: step.completed_at
      })) : []
    }
  }

}
