import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../_services/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  let service = inject(AuthService);
  let router = inject(Router);
  let redirectUrl: UrlTree = router.parseUrl('/login');
  const authd = await service.isUserAuthenticated();
  return  authd ? true : redirectUrl;
};

export const loggedOutGuard: CanActivateFn = async (route, state) => {
  let service = inject(AuthService);
  let router = inject(Router);
  let redirectUrl: UrlTree = router.parseUrl('/');
  const authd = await service.isUserAuthenticated();
  return  authd ? redirectUrl : true;
}
