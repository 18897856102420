import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';
import { NavigationActionTypes } from '../../../navigation/_models/navigation.models';

@Component({
  selector: 'app-inventory-dashboard',
  standalone: true,
  imports: [MainPageComponent, CardLgComponent, RouterModule],
  templateUrl: './inventory-dashboard.component.html',
  styleUrl: './inventory-dashboard.component.scss'
})
export class InventoryDashboardComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddWarehouse, NavigationActionTypes.AddProduct, NavigationActionTypes.AddTransaction]) }));
  }
}
