import { Component, Input } from '@angular/core';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { RouterModule } from '@angular/router';
import { Warehouse } from '../../_models/warehouses.models';

@Component({
  selector: 'app-warehouses-list-item',
  standalone: true,
  imports: [RouterModule, CardListItemComponent],
  templateUrl: './warehouses-list-item.component.html',
  styleUrl: './warehouses-list-item.component.scss'
})
export class WarehousesListItemComponent {
  @Input() warehouse!: Warehouse;
}
