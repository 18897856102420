<app-modal-backdrop>
  <div class="relative top-20 mx-4 p-5 border shadow-lg rounded-md bg-white">
    <div class="text-right" style="margin-top: -10px; margin-right: -10px">
      <button id="close-btn" class="text-gray-400 hover:text-gray-500" (click)="close()">
        <i class="fas fa-times text-xl"></i>
      </button>
    </div>
    <div class="mt-3 text-center">
      <h3 class="text-xl leading-6 font-semibold text-gray-900" *ngIf="data$ | async as data">{{ data.title || 'Error' }}</h3>
      <div class="mt-2 px-7 py-3" *ngIf="data$ | async as data">
        <p class="text-gray-600">{{ data.message || 'An error has occured' }}</p>
      </div>
      <div class="items-center px-4 py-3">
        <app-submit-button (click)="close()">Close</app-submit-button>
      </div>
    </div>
  </div>
</app-modal-backdrop>
