import { Injectable } from '@angular/core';
import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { environment } from '../../../environments/environment';
import { Tenant } from '../_store/tenant.model';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { ApiErrorService } from '../../shared/_services/api-error.service';

export const TENANT_TABLE = 'tenants';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getTenants(): Promise<Tenant[]> {
    const { data, error } = await this.supabase.from(TENANT_TABLE).select("*");
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Tenants');
      throw error;
    };
    return data;
  }
}
