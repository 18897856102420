<app-main-page>
  <!-- <app-card-lg [title]="'Product Map'"> -->
    <div *appLoading="(machineRecipesLoading$ | async) || (recipesLoading$ | async)"></div>
    @if(!(machineRecipesLoading$ | async) && !(recipesLoading$ | async)) {
      <form [formGroup]="recipesForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md rounded-lg px-4 pt-6 pb-8 mb-4">
        <formly-form [form]="recipesForm" [fields]="fields" [options]="options" [model]="model"></formly-form>
        <app-submit-button>Save</app-submit-button>
      </form>
    }
  <!-- </app-card-lg> -->
</app-main-page>
