import { Injectable } from '@angular/core';
import { ServiceStep, ServiceStepActions } from '../_models/service.models';

@Injectable({
  providedIn: 'root'
})
export class ServiceGuideService {

  constructor() { }

  getServiceGuide() {
    return this.serviceGuide;
  }

  serviceGuide: ServiceStep[] = [
    {
      id: 1,
      action: ServiceStepActions.IDENTIFY_MACHINE,
      name: 'Which Machine are you servicing?',
      disableScroll: true
    },
    {
      id: 2,
      name: 'Enter programming mode',
      checklist: [
        {
          id: 0,
          name: 'Open the machine using your Access Key'
        },
        {
          id: 1,
          name: 'Turn on the machine using the Bypass Key (plastic key)'
        },
        {
          id: 2,
          name: 'Press the red Programming Button 🅾️'
        },
        {
          id: 3,
          name: 'Navigate the menus using the assigned buttons',
          content: ['P1 - P3 to increase/decrease value', 'P5 Go to next value', 'P7 to Enter', 'P2 to Cancel / Return']
        },
        {
          id: 4,
          name: 'Enter Sales Code 00000 and press Enter'
        }
      ]
    },
    {
      id: 3,
      action: ServiceStepActions.RECORD_SALES,
      name: 'Record sales',
      disableScroll: true
    },
    {
      id: 4,
      name: 'Clear sales records',
      checklist: [
        {
          id: 0,
          name: 'Navigate to the Sales Code menu with P1 / P3 and press Enter'
        },
        {
          id: 1,
          name: 'Press Enter until you see the "Delete? - No" Menu'
        },
        {
          id: 2,
          name: 'Change it to "Yes"'
        },
        {
          id: 3,
          name: 'Press Enter again'
        },
        {
          id: 4,
          name: 'Wait until the Machine says "Clearing... OK!!!"'
        },
        {
          id: 5,
          name: 'Sales Data was reset and you can press Cancel/Return twice to get out of Service Mode'
        }
      ]
    },
    {
      id: 5,
      name: 'Refill',
      checklist: [
        {
          id: 0,
          name: 'Open the machine'
        },
        {
          id: 1,
          name: 'Refill all the powder canisters to par level'
        },
        {
          id: 2,
          name: 'Refill coffee to par level'
        },
        {
          id: 3,
          name: 'On 525, add cups and ensure at least 100 cups are present'
        },
        {
          id: 4,
          name: 'If running on a water tank, empty tank completely and refill tank to 100% capacity'
        },
      ]
    },
    {
      id: 6,
      action: ServiceStepActions.EDIT_INVENTORY,
      name: 'Edit Inventory',
      disableScroll: true
    },
    {
      id: 7,
      name: 'Clean Waste',
      checklist: [
        {
          id: 0,
          name: 'Remove the drain bucket and clean the wastewater'
        },
        {
          id: 1,
          name: 'Spray drain bucket walls 3 times with a bleaching agent to prevent odors'
        },
        {
          id: 2,
          name: 'Remove the coffee grounds bucket and clean its contents'
        },
      ]
    },
    {
      id: 8,
      name: 'Clean Mixing Bowls',
      checklist: [
        {
          id: 0,
          name: 'Look for clogs in the mixing bowls'
        },
        {
          id: 1,
          name: 'Rinse clogged bowls in hot water'
        },
        {
          id: 2,
          name: 'Re-attach bowls make sure they are properly fastened'
        }
      ]
    },
    {
      id: 9,
      name: 'Perform a wash',
      checklist: [
        {
          id: 0,
          name: 'Press the red maintenance button on the back of the machine'
        },
        {
          id: 1,
          name: 'Press P8 on the front panel to perform a wash cycle'
        },
        {
          id: 2,
          name: 'Check that the water flows smoothly. If not, remove and check the pipes.'
        }
      ]
    },
    {
      id: 10,
      name: 'Wipe inside',
      checklist: [
        {
          id: 0,
          name: 'Wipe starting from the top of the canisters all the way to the bottom part',
          content: ['When wiping near the mixing bowls and canister nozzles, ensure that not too much product ends up in the bowls and causes a clog']
        },
        {
          id: 1,
          name: 'Press P8 on the front panel to perform another wash cycle'
        },
      ]
    },
    {
      id: 11,
      name: 'Wipe outside',
      checklist: [
        {
          id: 0,
          name: 'Wipe the top and sides of the machine'
        },
        {
          id: 1,
          name: 'Wipe the card reader, ordering panel and serving area'
        },
      ]
    },
    {
      id: 12,
      name: 'Restart machine',
      checklist: [
        {
          id: 0,
          name: 'Close the bottom and top of the machine and wait for it to restart'
        },
        {
          id: 1,
          name: 'Ensure that there are no error codes showing on the screen'
        },
      ]
    },
    {
      id: 13,
      action: ServiceStepActions.REPORT_ISSUE,
      name: 'Report Issues'
    },
  ]
}
