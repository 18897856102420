import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';

import { environment } from '../environments/environment';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { authsFeature } from './auth/_store/auth.reducer';
import { AuthEffects } from './auth/_store/auth.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { tenantsFeature } from './tenants/_store/tenant.reducer';
import { TenantEffects } from './tenants/_store/tenant.effects';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule } from './shared/_modules/forms/forms.module';
import { machinesFeature } from './machines/_store/machine.reducer';
import { MachineEffects } from './machines/_store/machine.effects';
import { ProductEffects } from './products/_store/product.effects';
import { productsFeature } from './products/_store/product.reducer';
import { warehousesFeature } from './warehouses/_store/warehouse.reducer';
import { WarehouseEffects } from './warehouses/_store/warehouse.effects';
import { inventoryFeature } from './inventory/_store/inventory.reducer';
import { InventoryEffects } from './inventory/_store/inventory.effects';
import { recipesFeature } from './recipes/_store/recipe.reducer';
import { RecipeEffects } from './recipes/_store/recipe.effects';
import { salesFeature } from './sales/_store/sale.reducer';
import { SaleEffects } from './sales/_store/sale.effects';
import { ServiceEffects } from './services/_store/service.effects';
import { serviceFeature } from './services/_store/service.reducer';
import { issuesFeature } from './issues/_store/issue.reducer';
import { IssueEffects } from './issues/_store/issue.effects';
import { transactionsFeature } from './transactions/_store/transaction.reducer';
import { TransactionEffects } from './transactions/_store/transaction.effects';
import { navigationFeature } from './navigation/_store/navigation.reducer';
import { modalFeature } from './modals/_store/modal.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(),
    provideState(authsFeature),
    provideState(inventoryFeature),
    provideState(issuesFeature),
    provideState(machinesFeature),
    provideState(modalFeature),
    provideState(navigationFeature),
    provideState(productsFeature),
    provideState(tenantsFeature),
    provideState(recipesFeature),
    provideState(salesFeature),
    provideState(serviceFeature),
    provideState(transactionsFeature),
    provideState(warehousesFeature),
    provideEffects(AuthEffects),
    provideEffects(InventoryEffects),
    provideEffects(IssueEffects),
    provideEffects(MachineEffects),
    provideEffects(ProductEffects),
    provideEffects(TenantEffects),
    provideEffects(RecipeEffects),
    provideEffects(SaleEffects),
    provideEffects(ServiceEffects),
    provideEffects(TransactionEffects),
    provideEffects(WarehouseEffects),
    provideStoreDevtools({
      traceLimit: 75,
      trace: true,
    }),
    importProvidersFrom([FormlyModule.forRoot(), FormsModule])
  ]
};
