import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-backdrop',
  standalone: true,
  imports: [],
  templateUrl: './modal-backdrop.component.html',
  styleUrl: './modal-backdrop.component.scss'
})
export class ModalBackdropComponent {

}
