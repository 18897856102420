import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '../../_models/tabs.models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tab-navigation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tab-navigation.component.html',
  styleUrl: './tab-navigation.component.scss'
})
export class TabNavigationComponent {
  @Input() config: Tab[] = [];
  @Input() activeIndex: number = 0;
  @Output() activeIndexChange: EventEmitter<number> = new EventEmitter<number>();

  onIndexChange(index: number) {
    this.activeIndexChange.emit(index);
  }
}
