<ng-template #labelTemplate>
  <label class="block text-gray-700 text-sm font-bold mb-2 truncate text-ellipsis" *ngIf="props.label && props.hideLabel !== true" [attr.for]="id">
    {{ props.label }}
    <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
  </label>
</ng-template>

<div [class.form-floating]="props.labelPosition === 'floating'" [class.has-error]="showError" class="mb-4">
  <ng-container *ngIf="props.labelPosition !== 'floating'">
    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
  </ng-container>

  <ng-template #fieldComponent></ng-template>

  <ng-container *ngIf="props.labelPosition === 'floating'">
    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
  </ng-container>

  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <formly-validation-message
      id="{{ id }}-formly-validation-error"
      [field]="field"
      role="alert"
    ></formly-validation-message>
  </div>

  <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>
</div>
