import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ServiceActions } from './service.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Service } from '../_models/service.models';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const serviceFeatureKey = 'service';

export interface State extends EntityState<Service> {
  loading: boolean;
  currentId: string | null;
  error: any;
  query: QueryStore<Service>;
}

export const adapter: EntityAdapter<Service> = createEntityAdapter<Service>();

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
  currentId: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 10,
    },
    order: {
      column: 'started_at',
      order: Order.DESCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(ServiceActions.loadServices, (state, action) => ({ ...state, loading: true, query: action.query ? action.query : state.query })),
  on(ServiceActions.loadServicesSuccess, (state, action) => adapter.setAll(action.services, { ...state, loading: false })),
  on(ServiceActions.apiFailure, (state, action) => ({ ...state, loading: false, error: action.error })),
  on(ServiceActions.apiSuccess, (state, action) => ({ ...state, loading: false, currentId: action.id ? action.id : state.currentId })),
  on(ServiceActions.getServiceById, (state) => ({ ...state, loading: true })),
  on(ServiceActions.getOneSuccess, (state, action) => adapter.upsertOne(action.service, { ...state, currentId: action.service.id as string, loading: false })),
  on(ServiceActions.purgeService, (state, action) => adapter.removeOne(action.id, { ...state, loading: false })),
  on(ServiceActions.upsertService, (state) => ({ ...state, loading: true })),
);

export const serviceFeature = createFeature({
  name: serviceFeatureKey,
  reducer,
  extraSelectors: ({ selectServiceState }) => ({
    ...adapter.getSelectors(selectServiceState)
  }),
});

export const {
  selectIds: selectServiceIds,
  selectEntities: selectServiceEntities,
  selectAll: selectServices,
  selectCurrentId: selectServiceCurrentId,
  selectLoading: selectServicesLoading,
  selectError: selectServiceError,
  selectTotal
} = serviceFeature;

export const selectService = (id: string) => createSelector(
  selectServiceEntities,
  (services) => services[id]
);

