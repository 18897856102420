import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { IssuesListItemComponent } from '../issues-list-item/issues-list-item.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Issue } from '../../_models/issues.model';
import { Store } from '@ngrx/store';
import { selectIssues, selectIssuesLoading } from '../../_store/issue.reducer';
import { Machine } from '../../../machines/_models/machine.models';
import { selectMachineEntities, selectMachinesLoading } from '../../../machines/_store/machine.reducer';
import { Operator, Order, QueryStore } from '../../../shared/_models/query.models';
import { IssueActions } from '../../_store/issue.actions';
import { MachineActions } from '../../../machines/_store/machine.actions';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'app-issues-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, IssuesListItemComponent, AppLoadingDirective],
  templateUrl: './issues-list.component.html',
  styleUrl: './issues-list.component.scss'
})
export class IssuesListComponent implements OnInit, OnDestroy {
  @Input() machineId: string | undefined;
  issues$: Observable<Issue[]> = this.store.select(selectIssues);
  machineEntities$: Observable<Dictionary<Machine>> = this.store.select(selectMachineEntities);
  machineEntities!: Dictionary<Machine>;
  loading$: Observable<boolean> = this.store.select(selectIssuesLoading);
  machinesLoading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    let config: QueryStore<Issue> | undefined;
    config = {
      filters: [],
      pagination: { limit: 10, page: 1},
      order: { column: 'created_at', order: Order.DESCENDING }
    }
    if (this.machineId) {
      config.filters.push({ operator: Operator.Equal, column: 'machine_id', value: this.machineId });
    }
    this.store.dispatch(IssueActions.loadIssues({ query: config }));
    this.machineEntities$.pipe(take(1)).subscribe(machines => {
      if (Object.keys(machines).length < 2 && !this.machineId) {
        this.store.dispatch(MachineActions.loadMachines());
      }
    });
    this.machineEntities$.pipe(takeUntil(this.destroy$)).subscribe(machines => this.machineEntities = machines);
  }

  getMachine(id: string): Machine {
    return this.machineEntities[id] as Machine;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
