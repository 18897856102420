import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { AuthActions } from './auth.actions';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions, private service: AuthService, private router: Router) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap((action) =>
        from(this.service.login(action.login)).pipe(
          map((data) => {
            this.router.navigate(['/']);
            return AuthActions.loginSuccess({ login: action.login });
          }),
          catchError((error) => of(AuthActions.loginError({ error })))
        )
      )
    )
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() =>
        from(this.service.logout()).pipe(
          map(() => {
            this.router.navigate(['/login']);
            return AuthActions.logoutSuccess();
          }),
          catchError((error) => of(AuthActions.logoutError({ error })))
        )
      )
    )
  });

  signup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signup),
      concatMap((action) =>
      from(this.service.signup(action.signup)).pipe(
        map((data) => AuthActions.signupSuccess({ signup: action.signup })),
        catchError((error) => of(AuthActions.signupError({ error })))
      ))
    )
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadUser),
      concatMap(() =>
        from(this.service.getUser()).pipe(
          map((user) => AuthActions.loadUserSuccess({ user })),
          catchError((error) => of(AuthActions.loadUserFailure({ error })))
        )
      )
    )
  });

}
