import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { MachineRecipe, MachineRecipeForm, MachineRecipeFormModel, MachineRecipeSubmit } from '../../_models/machine.models';
import { Observable, Subject, repeat, take, takeUntil, takeWhile } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectMachineRecipes, selectMachineRecipesLoading } from '../../_store/machine.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { Recipe } from '../../../recipes/_models/recipes.models';
import { selectRecipes, selectRecipesLoading } from '../../../recipes/_store/recipe.reducer';
import { RecipeActions } from '../../../recipes/_store/recipe.actions';
import { MachineActions } from '../../_store/machine.actions';

@Component({
  selector: 'app-machine-recipes-edit',
  standalone: true,
  imports: [CommonModule, MainPageComponent, CardLgComponent, FormlyModule, ReactiveFormsModule, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './machine-recipes-edit.component.html',
  styleUrl: './machine-recipes-edit.component.scss'
})
export class MachineRecipesEditComponent implements OnInit, OnDestroy{
  id: string | null = null;
  machineRecipes: MachineRecipe[] = [];
  machineRecipes$: Observable<MachineRecipe[]> = this.store.select(selectMachineRecipes);
  machineRecipesLoading$: Observable<boolean> = this.store.select(selectMachineRecipesLoading);
  recipes$: Observable<Recipe[]> = this.store.select(selectRecipes);
  recipesLoading$: Observable<boolean> = this.store.select(selectRecipesLoading);
  private destroy$ = new Subject<void>();

  recipesForm = new FormGroup({});
  options: FormlyFormOptions = {};
  model: MachineRecipeFormModel = {recipes: []};
  fields: FormlyFieldConfig[] = [
    {
      key: 'recipes',
      type: 'repeat',
      props: {
        addText: 'Add Product Map',
        label: 'Product Map'
      },
      fieldArray: {
        fieldGroupClassName: 'flex flex-wrap',
        fieldGroup: [
          {
            key: 'machine_reference',
            type: 'input',
            className: 'basis-1/2 pr-4',
            props: {
              label: 'Reference',
              placeholder: 'Ex: P(x)',
              required: true
            }
          },
          {
            key: 'price',
            type: 'input',
            className: 'basis-1/2',
            props: {
              type: 'number',
              label: 'Price',
              placeholder: '1.75'
            }
          },
          {
            key: 'recipe_id',
            type: 'select',
            className: 'basis-full',
            props: {
              label: 'Product',
              options: this.recipes$,
              valueProp: 'id',
              labelProp: 'name',
              required: true
            },
          },
          {
            key: 'id',
            type: 'input',
            expressions: {
              hide: () => true
            }
          },
        ]
      }
    }
  ];

  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.store.dispatch(RecipeActions.loadRecipes());
    this.id = this.route.snapshot.paramMap.get('id');
    this.store.dispatch(MachineActions.getMachineRecipes({ id: this.id as string }));
    this.machineRecipes$.pipe(takeUntil(this.destroy$)).subscribe(mrs => {
      if (mrs.length > 0) {
        setTimeout(() => {
          const machineRecipesForm = mrs.map(mr => ({
            machine_reference: mr.machine_reference,
            recipe_id: mr.recipe.id as string,
            price: mr.price / 100,
            id: mr.id
          }));
          this.model = { recipes: machineRecipesForm };
          this.recipesForm.patchValue(this.model);
        })
      }
    });
  }

  onSubmit(model: MachineRecipeFormModel) {
    if(this.recipesForm.valid) {
      const recipes: MachineRecipeSubmit[] = model.recipes.map((recipe, index) => ({
        id: recipe.id || null,
        machine_reference: recipe.machine_reference,
        recipe_id: recipe.recipe_id,
        price: recipe.price * 100,
        machine_id: this.id as string,
        recipe_order: index,
      }));
      this.store.dispatch(MachineActions.upsertMachineRecipes({ recipes }));
      this.recipesLoading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
        if (!loading) {
          setTimeout(() => {
            const url = `/machines/${this.id}`;
            this.router.navigate([url]);
          }, 200);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
