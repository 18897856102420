import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps, FormlyModule } from '@ngx-formly/core';

interface BooleanProps extends FormlyFieldProps {}

export interface FormlyBooleanFieldConfig extends FormlyFieldConfig<BooleanProps> {
  type: 'boolean';
}

@Component({
  selector: 'app-formly-boolean',
  standalone: true,
  imports: [FormlyModule, ReactiveFormsModule, CommonModule],
  templateUrl: './formly-boolean.component.html',
  styleUrl: './formly-boolean.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyBooleanComponent extends FieldType<FieldTypeConfig<BooleanProps>>{

}
