import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuActionsService } from '../../_services/menu-actions.service';
import { NavigationActions } from '../../_store/navigation.actions';
import { NavigationActionTypes } from '../../_models/navigation.models';

@Component({
  selector: 'app-more',
  standalone: true,
  imports: [MainPageComponent, CardLgComponent, RouterModule],
  templateUrl: './more.component.html',
  styleUrl: './more.component.scss'
})
export class MoreComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddRecipe, NavigationActionTypes.AddSale, NavigationActionTypes.ServiceMachine, NavigationActionTypes.ReportIssue]) }));
  }

}
