import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Store } from '@ngrx/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Sale } from '../../_models/sales.models';
import { selectSales, selectSalesLoading } from '../../_store/sale.reducer';
import { SaleActions } from '../../_store/sale.actions';
import { SalesListItemComponent } from '../sales-list-item/sales-list-item.component';
import { selectMachineEntities, selectMachinesLoading } from '../../../machines/_store/machine.reducer';
import { Dictionary } from '@ngrx/entity';
import { Machine } from '../../../machines/_models/machine.models';
import { MachineActions } from '../../../machines/_store/machine.actions';
import { Operator, Order, QueryStore } from '../../../shared/_models/query.models';

@Component({
  selector: 'app-sales-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, SalesListItemComponent, AppLoadingDirective],
  templateUrl: './sales-list.component.html',
  styleUrl: './sales-list.component.scss'
})
export class SalesListComponent implements OnInit, OnDestroy {
  @Input() machineId: string | undefined;

  sales$: Observable<Sale[]> = this.store.select(selectSales);
  machineEntities$: Observable<Dictionary<Machine>> = this.store.select(selectMachineEntities);
  machineEntities!: Dictionary<Machine>;
  loading$: Observable<boolean> = this.store.select(selectSalesLoading);
  machinesLoading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    let config: QueryStore<Sale> | undefined;
    config = {
      filters: [],
      pagination: { limit: 10, page: 1},
      order: { column: 'sold_at', order: Order.DESCENDING }
    }
    if (this.machineId) {
      config.filters.push({ operator: Operator.Equal, column: 'machine_id', value: this.machineId });
    }
    this.store.dispatch(SaleActions.loadSales({ query: config }));
    this.machineEntities$.pipe(take(1)).subscribe(machines => {
      if (Object.keys(machines).length < 2 && !this.machineId) {
        this.store.dispatch(MachineActions.loadMachines());
      }
    });
    this.machineEntities$.pipe(takeUntil(this.destroy$)).subscribe(machines => this.machineEntities = machines);
  }

  getMachine(id: string): Machine {
    return this.machineEntities[id] as Machine;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
