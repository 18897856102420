import { Routes } from '@angular/router';
import { HomeDashboardComponent } from './dashboards/_components/home-dashboard/home-dashboard.component';
import { LoginComponent } from './auth/_components/login/login.component';
import { SignupComponent } from './auth/_components/signup/signup.component';
import { authGuard, loggedOutGuard } from './auth/_guards/auth.guard';
import { MachinesHomeComponent } from './machines/_components/machines-home/machines-home.component';
import { MachineComponent } from './machines/_components/machine/machine.component';
import { MachineEditComponent } from './machines/_components/machine-edit/machine-edit.component';
import { InventoryHomeComponent } from './inventory/_components/inventory-home/inventory-home.component';
import { ProductsHomeComponent } from './products/_components/products-home/products-home.component';
import { ProductComponent } from './products/_components/product/product.component';
import { ProductEditComponent } from './products/_components/product-edit/product-edit.component';
import { WarehousesHomeComponent } from './warehouses/_components/warehouses-home/warehouses-home.component';
import { WarehouseEditComponent } from './warehouses/_components/warehouse-edit/warehouse-edit.component';
import { WarehouseComponent } from './warehouses/_components/warehouse/warehouse.component';
import { InventoryDashboardComponent } from './inventory/_components/inventory-dashboard/inventory-dashboard.component';
import { ServiceGuideComponent } from './services/_components/service-guide/service-guide.component';
import { MoreComponent } from './navigation/_components/more/more.component';
import { RecipesHomeComponent } from './recipes/_components/recipes-home/recipes-home.component';
import { RecipeEditComponent } from './recipes/_components/recipe-edit/recipe-edit.component';
import { RecipeComponent } from './recipes/_components/recipe/recipe.component';
import { MachineRecipesEditComponent } from './machines/_components/machine-recipes-edit/machine-recipes-edit.component';
import { SalesHomeComponent } from './sales/_components/sales-home/sales-home.component';
import { SaleEditComponent } from './sales/_components/sale-edit/sale-edit.component';
import { SaleComponent } from './sales/_components/sale/sale.component';
import { ServicesHomeComponent } from './services/_components/services-home/services-home.component';
import { IssuesHomeComponent } from './issues/_components/issues-home/issues-home.component';
import { IssueEditComponent } from './issues/_components/issue-edit/issue-edit.component';
import { IssueComponent } from './issues/_components/issue/issue.component';
import { TransactionsHomeComponent } from './transactions/_components/transactions-home/transactions-home.component';
import { TransactionEditComponent } from './transactions/_components/transaction-edit/transaction-edit.component';
import { TransactionComponent } from './transactions/_components/transaction/transaction.component';

export const routes: Routes = [
  { path: '', component: HomeDashboardComponent, canActivate: [authGuard], data: { animation: 'home', rank: 1 } },
  { path: 'login', component: LoginComponent, canActivate: [loggedOutGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [loggedOutGuard] },
  { path: 'inventory', component: InventoryDashboardComponent, canActivate: [authGuard], data: { animation: 'inventory', rank: 2 } },
  { path: 'inventory/product-inventory', component: InventoryHomeComponent, canActivate: [authGuard], data: { animation: 'product-inventory', rank: 10 } },
  { path: 'machines', component: MachinesHomeComponent, canActivate: [authGuard], data: { animation: 'machine', rank: 3 }},
  { path: 'machines/new', component: MachineEditComponent, canActivate: [authGuard], data: { animation: 'machine-edit', rank: 11 } },
  { path: 'machines/:id', component: MachineComponent, canActivate: [authGuard], data: { animation: 'machine-page', rank: 10 } },
  { path: 'machines/:id/edit', component: MachineEditComponent, canActivate: [authGuard], data: { animation: 'machine-edit', rank: 11 } },
  { path: 'machines/:id/recipes/edit', component: MachineRecipesEditComponent, canActivate: [authGuard], data: { animation: 'machine-recipes-edit', rank: 12 } },
  { path: 'inventory/products', component: ProductsHomeComponent, canActivate: [authGuard], data: { animation: 'products', rank: 12 } },
  { path: 'inventory/products/new', component: ProductEditComponent, canActivate: [authGuard], data: { animation: 'product-edit', rank: 14 } },
  { path: 'inventory/products/:id', component: ProductComponent, canActivate: [authGuard], data: { animation: 'product-page', rank: 13 } },
  { path: 'inventory/products/:id/edit', component: ProductEditComponent, canActivate: [authGuard], data: { animation: 'product-edit', rank: 14 } },
  { path: 'inventory/warehouses', component: WarehousesHomeComponent, canActivate: [authGuard], data: { animation: 'warehouses', rank: 13 } },
  { path: 'inventory/warehouses/new', component: WarehouseEditComponent, canActivate: [authGuard], data: { animation: 'warehouse-edit', rank: 16 } },
  { path: 'inventory/warehouses/:id', component: WarehouseComponent, canActivate: [authGuard], data: { animation: 'warehouse-page', rank: 15 } },
  { path: 'inventory/warehouses/:id/edit', component: WarehouseEditComponent, canActivate: [authGuard], data: { animation: 'warehouse-edit', rank: 16 } },
  { path: 'inventory/transactions', component: TransactionsHomeComponent, canActivate: [authGuard], data: { animation: 'transactions', rank: 17 } },
  { path: 'inventory/transactions/new', component: TransactionEditComponent, canActivate: [authGuard], data: { animation: 'transaction-edit', rank: 19 } },
  { path: 'inventory/transactions/:id', component: TransactionComponent, canActivate: [authGuard], data: { animation: 'transaction-page', rank: 18 } },
  { path: 'inventory/transactions/:id/edit', component: TransactionEditComponent, canActivate: [authGuard], data: { animation: 'transaction-edit', rank: 19 } },
  { path: 'services/service_guide', component: ServiceGuideComponent, canActivate: [authGuard], data: { animation: 'service-guide', rank: 25 } },
  { path: 'services/service_guide/:serviceId/edit', component: ServiceGuideComponent, canActivate: [authGuard], data: { animation: 'service-guide', rank: 25 } },
  { path: 'more', component: MoreComponent, canActivate: [authGuard], data: { animation: 'more', rank: 30 }},
  { path: 'recipes', component: RecipesHomeComponent, canActivate: [authGuard], data: { animation: 'recipes', rank: 40 }},
  { path: 'recipes/new', component: RecipeEditComponent, canActivate: [authGuard], data: { animation: 'recipe-edit', rank: 42 }},
  { path: 'recipes/:id', component: RecipeComponent, canActivate: [authGuard], data: { animation: 'recipe', rank: 41 }},
  { path: 'recipes/:id/edit', component: RecipeEditComponent, canActivate: [authGuard], data: { animation: 'recipe-edit', rank: 42 }},
  { path: 'sales', component: SalesHomeComponent, canActivate: [authGuard], data: { animation: 'sales', rank: 50 }},
  { path: 'sales/new', component: SaleEditComponent, canActivate: [authGuard], data: { animation: 'sale-edit', rank: 52 }},
  { path: 'sales/:id', component: SaleComponent, canActivate: [authGuard], data: { animation: 'sale', rank: 51 }},
  { path: 'sales/:id/edit', component: SaleEditComponent, canActivate: [authGuard], data: { animation: 'sale-edit', rank: 52 }},
  { path: 'services', component: ServicesHomeComponent, canActivate: [authGuard], data: { animation: 'services', rank: 60 }},
  { path: 'issues', component: IssuesHomeComponent, canActivate: [authGuard], data: { animation: 'issues', rank: 70 }},
  { path: 'issues/new', component: IssueEditComponent, canActivate: [authGuard], data: { animation: 'issues-edit', rank: 72 }},
  { path: 'issues/:id', component: IssueComponent, canActivate: [authGuard], data: { animation: 'issues-page', rank: 71 }},
  { path: 'issues/:id/edit', component: IssueEditComponent, canActivate: [authGuard], data: { animation: 'issues-edit', rank: 72 }},
];

