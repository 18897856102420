import { createFeature, createReducer, on } from '@ngrx/store';
import { ModalActions } from './modal.actions';
import { ModalComponentEnum, ModalData } from '../_models/modal.models';

export const modalFeatureKey = 'modal';

export interface State {
  component: ModalComponentEnum | null;
  data: ModalData | null;
}

export const initialState: State = {
  component: null,
  data: null,
};

export const reducer = createReducer(
  initialState,
  on(ModalActions.showModal, (state, { component, data }) => ({ ...state, component, data })),
  on(ModalActions.hideModal, () => initialState)
);

export const modalFeature = createFeature({
  name: modalFeatureKey,
  reducer,
});

export const {
  selectModalState,
  selectComponent: selectModalComponent,
  selectData: selectModalData,
} = modalFeature;

