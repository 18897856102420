import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectEntities } from '../../../auth/_store/auth.reducer';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { User } from '@supabase/supabase-js';
import { AuthActions } from '../../../auth/_store/auth.actions';
import { HeaderBackButtonComponent } from '../header-back-button/header-back-button.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [HeaderBackButtonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  readonly users$: Observable<Dictionary<User>> = this.store.select(selectEntities);
  constructor(private store: Store) {}

  ngOnInit(): void {

  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  get showBackButton() {
    return false;
  }

  get showUserButton() {
    return false;
  }
}
