<app-main-page>
  <div *appLoading="loading$ | async"></div>
  @if (!(loading$ | async)) {
    <form [formGroup]="machineForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
      <formly-form [form]="machineForm" [fields]="fields" [model]="model"></formly-form>
      <app-submit-button>{{ isNew ? 'Create' : 'Save' }}</app-submit-button>
    </form>
  }
</app-main-page>

