import { createFeature, createReducer, on } from '@ngrx/store';
import { InventoryActions } from './inventory.actions';
import { ProductInventory } from '../../products/_models/products.models';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const inventoryFeatureKey = 'inventory';

export interface State {
  inventory: ProductInventory[];
  loading: boolean;
  error: string | null;
  query: QueryStore<ProductInventory>;
}

export const initialState: State = {
  inventory: [],
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 10,
    },
    order: {
      column: 'name',
      order: Order.ASCENDING
    }
  }
};

export const reducer = createReducer(
  initialState,
  on(InventoryActions.loadInventory, state => ({ ...state, loading: true })),
  on(InventoryActions.loadInventoryByWarehouse, state => ({ ...state, loading: true })),
  on(InventoryActions.loadInventorySuccess, (state, action) => ({ ...state, inventory: action.inventory, loading: false })),
  on(InventoryActions.apiFailure, (state, action) => ({ ...state, error: action.error, loading: false })),
);

export const inventoryFeature = createFeature({
  name: inventoryFeatureKey,
  reducer,
});

export const {
  selectInventory,
  selectLoading: selectInventoryLoading,
  selectError: selectInventoryError,
  selectQuery: selectInventoryQuery
} = inventoryFeature;

