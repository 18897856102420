import { Component } from '@angular/core';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Warehouse } from '../../_models/warehouses.models';
import { Observable, takeWhile } from 'rxjs';
import { selectWarehouse, selectWarehousesLoading } from '../../_store/warehouse.reducer';
import { Store } from '@ngrx/store';
import { WarehouseActions } from '../../_store/warehouse.actions';
import { ButtonStyle } from '../../../shared/_models/styles.enums';
import { InventoryListComponent } from '../../../inventory/_components/inventory-list/inventory-list.component';

@Component({
  selector: 'app-warehouse',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent, InventoryListComponent],
  templateUrl: './warehouse.component.html',
  styleUrl: './warehouse.component.scss'
})
export class WarehouseComponent {
  id: Warehouse['id'];
  warehouse$!: Observable<Warehouse | undefined>;
  warehouse!: Warehouse | undefined;
  loading$: Observable<boolean> = this.store.select(selectWarehousesLoading);

  constructor(private route: ActivatedRoute, private store: Store) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.warehouse$ = this.store.select(selectWarehouse(this.id));
    this.warehouse$.pipe(takeWhile(w => w == undefined, true)).subscribe(warehouse => {
      if (warehouse) {
        this.warehouse = warehouse;
      } else {
        this.store.dispatch(WarehouseActions.getWarehouseById({ id: this.id as string }));
      }
    });
  }

  delete() {

  }

  get ButtonStyle() {
    return ButtonStyle;
  }
}
