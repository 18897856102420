import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Transaction, TransactionForm, TransactionItem, TransactionItemForm, TransactionTypeEnum } from '../../_models/transactions.model';
import { Observable, Subject, combineLatest, takeUntil, takeWhile } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectProducts } from '../../../products/_store/product.reducer';
import { selectTransaction, selectTransactionByServiceId, selectTransactionsLoading } from '../../_store/transaction.reducer';
import { Product } from '../../../products/_models/products.models';
import { selectWarehouses } from '../../../warehouses/_store/warehouse.reducer';
import { WarehouseActions } from '../../../warehouses/_store/warehouse.actions';
import { ProductActions } from '../../../products/_store/product.actions';
import { DateService } from '../../../shared/_services/date.service';
import { TransactionActions } from '../../_store/transaction.actions';
import { PriceService } from '../../../shared/_services/price.service';

@Component({
  selector: 'app-transaction-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './transaction-edit.component.html',
  styleUrl: './transaction-edit.component.scss'
})
export class TransactionEditComponent implements OnInit, OnDestroy {
  @Input() serviceMachineId: string | undefined = undefined;
  @Input() serviceId: string | null | undefined = undefined;
  @Input() newService: boolean | undefined = undefined;
  @Output() onTransactionSave = new EventEmitter<any>();
  id: string | null = null;
  isNew: boolean = false;

  transaction$!: Observable<Transaction | undefined>;
  products$: Observable<Product[]> = this.store.select(selectProducts);
  products: Product[] = [];
  warehouses$: Observable<any[]> = this.store.select(selectWarehouses);
  loading$: Observable<boolean> = this.store.select(selectTransactionsLoading);
  private destroy$ = new Subject<void>();

  transactionForm = new FormGroup({});
  options: FormlyFormOptions = {};
  model: TransactionForm = {
    transaction_date: this.dateService.getLocalDate(),
    transaction_type: TransactionTypeEnum.PURCHASE,
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'id',
      type: 'input',
      hide: true
    },
    {
      key: 'transaction_type',
      type: 'select',
      props: {
        label: 'Transaction Type',
        placeholder: 'Select transaction type',
        required: true,
        options: [
          { label: 'Purchase', value: TransactionTypeEnum.PURCHASE },
          { label: 'Sale', value: TransactionTypeEnum.SALE },
          { label: 'Transfer', value: TransactionTypeEnum.TRANSER },
          { label: 'Adjustment', value: TransactionTypeEnum.ADJUSTMENT }
        ]
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => (this.serviceId !== null && this.serviceId !== undefined)
      }
    },
    {
      key: 'transaction_date',
      type: 'input',
      props: {
        type: 'datetime-local',
        label: 'Transaction Date',
        placeholder: 'Enter transaction date',
        required: true,
      }
    },
    {
      key: 'source_warehouse_id',
      type: 'select',
      props: {
        label: 'Source Warehouse',
        placeholder: 'Select source warehouse',
        required: true,
        options: this.warehouses$,
        valueProp: 'id',
        labelProp: 'name'
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => ([TransactionTypeEnum.PURCHASE].includes(field.model.transaction_type))
      }
    },
    {
      key: 'destination_warehouse_id',
      type: 'select',
      props: {
        label: 'Destination Warehouse',
        placeholder: 'Select destination warehouse',
        required: true,
        options: this.warehouses$,
        valueProp: 'id',
        labelProp: 'name'
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => ([TransactionTypeEnum.SALE, TransactionTypeEnum.ADJUSTMENT].includes(field.model.transaction_type))
      }
    },
    {
      key: 'items',
      type: 'repeat',
      props: {
        addText: 'Add Product',
        label: 'Products',
      },
      fieldArray: {
        fieldGroupClassName: 'flex flex-wrap',
        fieldGroup: [
          {
            key: 'product_id',
            type: 'select',
            className: 'w-full',
            props: {
              label: 'Product',
              placeholder: 'Select product',
              required: true,
              options: this.products$,
              valueProp: 'id',
              labelProp: 'name'
            }
          },
          {
            key: 'quantity',
            type: 'input',
            className: 'w-2/3',
            props: {
              type: 'number',
              label: 'Quantity',
              placeholder: 'Enter quantity',
              required: true
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => field.model?.product_id === undefined,
            }
          },
          {
            key: 'quantity_type',
            type: 'select',
            className: 'w-1/3',
            props: {
              label: 'Type',
              required: true
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => field.model?.product_id === undefined,
              'props.options': (field: FormlyFieldConfig) => {
                if(field.model?.product_id === undefined) return [];
                const product = this.products.find(product => product.id === field.model.product_id);
                return product ? [
                  { label: 'Package(s)', value: 'package' },
                  { label: product.unit, value: product.unit },
                ] : [];
              }
            }
          },
          {
            key: 'price',
            type: 'input',
            className: 'w-full',
            props: {
              type: 'number',
              label: 'Total Price',
              placeholder: 'Enter total price',
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => (field.parent?.parent?.parent?.model.transaction_type !== TransactionTypeEnum.PURCHASE)
            }
          },
        ]
      }
    }
  ];

  constructor(private route: ActivatedRoute, private store: Store, private location: Location, private dateService: DateService, private priceService: PriceService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if(this.id) {
        this.loadExistingTransaction(this.id);
      } else if (this.serviceId && !this.newService) {
        this.loadExistingTransaction(undefined, this.serviceId);
      } else {
        this.isNew = true;
      }
    });
    if(this.serviceId && this.newService) {
      this.model = {
        ...this.model,
        transaction_type: TransactionTypeEnum.SALE,
        items: []
      }
    }
    this.store.dispatch(WarehouseActions.loadWarehouses());
    this.store.dispatch(ProductActions.loadProducts());
    this.products$.subscribe(products => this.products = products);
  }

  loadExistingTransaction(id?: string, serviceId?: string) {
    if (id) {
      this.store.dispatch(TransactionActions.getTransactionById({ id }));
      this.transaction$ = this.store.select(selectTransaction(id));
    } else if (serviceId) {
      this.store.dispatch(TransactionActions.getTransactionByServiceId({ service_id: serviceId }));
      this.transaction$ = this.store.select(selectTransactionByServiceId(serviceId));
    }
    combineLatest([this.transaction$, this.products$]).pipe(takeUntil(this.destroy$)).subscribe(([transaction, products]) => {
      if(transaction && products) {
        this.model = {
          ...transaction,
          transaction_date: this.dateService.getLocalDate(transaction.transaction_date),
          items: transaction.items?.map(item => (this.mapTransactionItemToForm(item))) || []
        };
      }
    });
  }

  onSubmit(model: TransactionForm) {
    if(this.transactionForm.valid) {
      if(this.serviceId) {
        model.service_id = this.serviceId;
      }
      model.items = model.items?.map(item => ({
        ...item,
        price: item.price ? this.priceService.priceToInt(item.price) : undefined,
        quantity: this.mapPackageSizeToQuantity(item).quantity
      }));
      this.store.dispatch(TransactionActions.upsertTransaction({ transaction: { ...model, transaction_date: this.dateService.getUTCDate(model.transaction_date) }}));
      this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
        if(!loading) {
          if(this.serviceId) {
            this.onTransactionSave.emit();
          } else {
            this.location.back();
          }
        }
      });
    }
  }

  mapPackageSizeToQuantity(item: TransactionItemForm): TransactionItem {
    const product = this.productForItem(item);
    if (product && item.quantity_type === 'package') {
      item.quantity = item.quantity * product.package_size;
    }
    return item;
  }

  mapTransactionItemToForm(item: TransactionItem): TransactionItemForm {
    const product = this.productForItem(item);
    if(product && item.quantity % product.package_size === 0) {
      return {
        ...item,
        quantity: item.quantity / product.package_size,
        quantity_type: 'package',
        price: item.price ? item.price / 100 : undefined
      }
    } else {
      return {
        ...item,
        quantity_type: product?.unit || 'g',
        price: item.price ? item.price / 100 : undefined
      }
    }
  }

  productForItem(item: TransactionItem): Product | undefined {
    return this.products.find(product => product.id === item.product_id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }



}
