import { Recipe, SizeEnum } from "../../recipes/_models/recipes.models";

export interface Machine {
  id?: string;
  name: string;
  manufacturer?: string;
  model?: string;
  location?: string;
  size?: SizeEnum;
  sales_mode?: SalesModeEnum;
  status?: MachineStatusEnum;
  customer_id?: string;
  created_at?: string;
  updated_at?: string;
}

export enum MachineStatusEnum {
  ACTIVE,
  ISSUE,
  INACTIVE,
  DELETED
}

export enum SalesModeEnum {
  BILLABLE_TO_CLIENT = 'BILLABLE_TO_CLIENT',
  CREDIT_CARD = 'CREDIT_CARD',
  FREE_VEND = 'FREE_VEND',
}

export interface MachineRecipe {
  id?: string;
  recipe_order: number;
  machine_reference: string;
  recipe: Recipe;
  machine_id: Machine['id'];
  price: number;
}

export interface MachineRecipeFormModel {
  recipes: MachineRecipeForm[];
}

export interface MachineRecipeForm {
  machine_reference: string;
  recipe_id: string;
  price: number;
  id?: string | null;
}

export interface MachineRecipeSubmit extends MachineRecipeForm {
  id: string | null;
  recipe_order: number;
  machine_id: string;
}
