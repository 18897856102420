<h2 class="text-white text-xl mb-4">Any issues identified during service?</h2>
<div class="flex flex-wrap mb-4">
  @for(issue of IssueEnum | keyvalue; track issue) {
    <div
      (click)="toggleIssue(issue.value)"
      [ngClass]="isIssueSelected(issue.value) ? 'bg-orange-300': 'bg-white'"
      class="px-2 py-1 mb-2 mr-2 shadow-sm font-semibold bg-white text-gray-600 rounded-lg">
      {{ issue.value.replaceAll('_', ' ') | titlecase }}
    </div>
  }
</div>
@if(serviceIssues.length > 0) {
  <h2 class="text-white text-xl mb-4">Were the following issues resolved during service?</h2>
  <div class="flex flex-wrap">
    @for(issue of serviceIssues; track issue) {
      <div
        (click)="toggleSelectedIssue(issue.service_issue)"
        [ngClass]="isIssueResolved(issue.service_issue) ? 'bg-green-300': 'bg-white'"
        class="px-2 py-1 mb-2 mr-2 shadow-sm font-semibold bg-white text-gray-600 rounded-lg">
        {{ issue.service_issue.replaceAll('_', ' ') | titlecase }}
      </div>
    }
  </div>
}
