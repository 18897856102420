import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { ServicesListItemComponent } from '../services-list-item/services-list-item.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Service } from '../../_models/service.models';
import { selectServicesLoading, selectServices } from '../../_store/service.reducer';
import { Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { selectMachineEntities, selectMachinesLoading } from '../../../machines/_store/machine.reducer';
import { Machine } from '../../../machines/_models/machine.models';
import { Operator, Order, QueryStore } from '../../../shared/_models/query.models';
import { ServiceActions } from '../../_store/service.actions';
import { MachineActions } from '../../../machines/_store/machine.actions';

@Component({
  selector: 'app-services-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, ServicesListItemComponent, AppLoadingDirective],
  templateUrl: './services-list.component.html',
  styleUrl: './services-list.component.scss'
})
export class ServicesListComponent implements OnInit, OnDestroy{
  @Input() machineId: string | undefined;

  services$: Observable<Service[]> = this.store.select(selectServices)
  machineEntities$: Observable<Dictionary<Machine>> = this.store.select(selectMachineEntities);
  machineEntities!: Dictionary<Machine>;
  loading$: Observable<boolean> = this.store.select(selectServicesLoading);
  machinesLoading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    let config: QueryStore<Service> | undefined;
    config = {
      filters: [],
      pagination: { limit: 5, page: 1 },
      order: { column: 'started_at', order: Order.DESCENDING }
    }
    if(this.machineId) {
      config.filters.push({ operator: Operator.Equal, column: 'machine_id', value: this.machineId });
    }
    this.store.dispatch(ServiceActions.loadServices({ query: config }));
    this.machineEntities$.pipe(take(1)).subscribe(machines => {
      if (Object.keys(machines).length < 2 && !this.machineId) {
        this.store.dispatch(MachineActions.loadMachines());
      }
    });
    this.machineEntities$.pipe(takeUntil(this.destroy$)).subscribe(machines => this.machineEntities = machines);
  }

  getMachine(id: string): Machine {
    return this.machineEntities[id] as Machine;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
