<app-main-page>
  <div *appLoading="loading$ | async"></div>
  @if (saleDetails$ | async; as sale) {
    <app-card-lg [title]="'Sale'">
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-server"></i>
        </div>
        <div>
          {{ sale.machine.name }} | {{ sale.customer.name }}
        </div>
      </div>
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-calendar-day"></i>
        </div>
        <div>
          {{ sale.sold_at | date:'short' }}
        </div>
      </div>
    </app-card-lg>
    <div class="flex items-center my-4">
      <app-submit-button class="flex-1 mr-4" [style]="ButtonStyle.PRIMARY" [routerLink]="['edit']">Edit</app-submit-button>
      <app-submit-button class="flex-1" [style]="ButtonStyle.DANGER" (click)="delete()">Delete</app-submit-button>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-3 mb-4 flex justify-between items-center">
      <div class="text-lg">Total Revenue</div>
      <div class="text-2xl text-green-600">{{ getTotalRevenue(sale.items) | currency }}</div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div class="bg-white shadow-sm rounded-lg p-3 flex items-center">
        <div class="">
          <div class="text-sm font-medium">Machine Counter</div>
          <div class="text-2xl text-gray-600">{{sale.machine_counter}}</div>
        </div>
      </div>
      <div class="bg-white shadow-sm rounded-lg p-3 items-center">
        <div>
          <div class="text-sm font-medium">Total Sales</div>
        </div>
        <div class="flex justify-between">
          <div class="text-2xl text-gray-600">{{sale.total_sold}}</div>
        </div>
      </div>
      @for(item of sale.items; track item) {
        <div class="bg-white shadow-sm rounded-lg p-3 w-full">
          <div>
            <div class="text-sm font-medium">{{item.machine_reference ? item.machine_reference + ' | ' : ''}}{{item.recipe?.name}}</div>
            <div class="flex justify-between align-center items-end">
              <div class="text-2xl text-gray-600 w-full">{{item.quantity}}</div>
              <div class="text-2xl text-gray-400"> {{item.unit_price / 100 | currency}}</div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</app-main-page>
