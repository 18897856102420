import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps, FormlyModule } from '@ngx-formly/core';

interface TextAreaProps extends FormlyFieldProps {
  cols?: number;
  rows?: number;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<FormlyTextareaComponent>;
}

@Component({
  selector: 'app-formly-textarea',
  standalone: true,
  imports: [FormlyModule, ReactiveFormsModule, CommonModule],
  templateUrl: './formly-textarea.component.html',
  styleUrl: './formly-textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTextareaComponent extends FieldType<FieldTypeConfig<TextAreaProps>> {
  override defaultOptions = {
    props: {
      cols: 1,
      rows: 2
    }
  }
}
