import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Transaction } from '../_models/transactions.model';
import { TransactionActions } from './transaction.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const transactionsFeatureKey = 'transactions';

export interface State extends EntityState<Transaction> {
  // additional entities state properties
  loading: boolean;
  error: any;
  query: QueryStore<Transaction>;
}

export const adapter: EntityAdapter<Transaction> = createEntityAdapter<Transaction>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 5,
    },
    order: {
      column: 'transaction_date',
      order: Order.DESCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(TransactionActions.loadTransactions,
    (state, action) => ({ ...state, loading: true, query: action.query ? action.query : state.query })
  ),
  on(TransactionActions.loadTransactionsSuccess, (state, action) => adapter.setAll(action.transactions, { ...state, loading: false })),
  on(TransactionActions.apiSuccess, (state) => ({ ...state, loading: false })),
  on(TransactionActions.apiFailure, (state, action) => ({ ...state, loading: false, error: action.error })),
  on(TransactionActions.getTransactionById, (state, _action) => ({ ...state, loading: true })),
  on(TransactionActions.getTransactionByServiceId, (state, _action) => ({ ...state, loading: true })),
  on(TransactionActions.getOneSuccess, (state, action) => adapter.upsertOne(action.transaction, { ...state, loading: false })),
  on(TransactionActions.purgeTransaction, (state, action) => adapter.removeOne(action.id, { ...state, loading: false })),

  on(TransactionActions.addTransaction,
    (state, action) => adapter.addOne(action.transaction, state)
  ),
  // on(TransactionActions.upsertTransaction,
  //   (state, action) => adapter.upsertOne(action.transaction, state)
  // ),
  on(TransactionActions.addTransactions,
    (state, action) => adapter.addMany(action.transactions, state)
  ),
  on(TransactionActions.upsertTransactions,
    (state, action) => adapter.upsertMany(action.transactions, state)
  ),
  on(TransactionActions.updateTransaction,
    (state, action) => adapter.updateOne(action.transaction, state)
  ),
  on(TransactionActions.updateTransactions,
    (state, action) => adapter.updateMany(action.transactions, state)
  ),
  on(TransactionActions.deleteTransaction,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TransactionActions.deleteTransactions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TransactionActions.clearTransactions,
    state => adapter.removeAll(state)
  ),
);

export const transactionsFeature = createFeature({
  name: transactionsFeatureKey,
  reducer,
  extraSelectors: ({ selectTransactionsState }) => ({
    ...adapter.getSelectors(selectTransactionsState)
  }),
});

export const {
  selectIds: selectTransactionsIds,
  selectEntities: selectTransactionsEntities,
  selectAll: selectTransactions,
  selectLoading: selectTransactionsLoading,
  selectError: selectTransactionsError,
  selectTotal,
} = transactionsFeature;

export const selectTransaction = (id: string) => createSelector(
  selectTransactionsEntities,
  (transactions) => transactions[id]
);

export const selectTransactionByServiceId = (service_id: string) => createSelector(
  selectTransactions,
  (transactions) => transactions.find(t => t.service_id === service_id)
);
