import { Component, Input } from '@angular/core';
import { ButtonStyle } from '../../../_models/styles.enums';

@Component({
  selector: 'app-submit-button',
  standalone: true,
  imports: [],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss'
})
export class SubmitButtonComponent {
  @Input() style: ButtonStyle = ButtonStyle.PRIMARY;

  get styling(): string {
    let s = '';
    switch (this.style) {
      case ButtonStyle.PRIMARY:
        s = 'bg-blue-900 hover:bg-blue-800';
        break;
      case ButtonStyle.SECONDARY:
        s = 'bg-blue-700 hover:bg-blue-600';
        break;
      case ButtonStyle.SUCCESS:
        s = 'bg-green-400 hover:bg-green-300';
        break;
      case ButtonStyle.WARN:
        s = 'bg-orange-400 hover:bg-orange-300';
        break;
      case ButtonStyle.DANGER:
        s = 'bg-red-400 hover:bg-red-300';
        break;
      case ButtonStyle.INFO:
        s = 'bg-blue-400 hover:bg-blue-300';
        break;
      default:
        s = 'bg-blue-900 hover:bg-blue-800';
        break;
    }
    return s;
  }
}
