import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-lg',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-lg.component.html',
  styleUrl: './card-lg.component.scss'
})
export class CardLgComponent {
 @Input() title: string = '';
 @Input() statusColor: string = '';
}
