import { Component } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { ServicesListComponent } from '../services-list/services-list.component';

@Component({
  selector: 'app-services-home',
  standalone: true,
  imports: [MainPageComponent, ServicesListComponent],
  templateUrl: './services-home.component.html',
  styleUrl: './services-home.component.scss'
})
export class ServicesHomeComponent {

}
