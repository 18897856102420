import { Component, Input } from '@angular/core';
import { Product } from '../../_models/products.models';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';

@Component({
  selector: 'app-products-list-item',
  standalone: true,
  imports: [RouterModule, CardListItemComponent],
  templateUrl: './products-list-item.component.html',
  styleUrl: './products-list-item.component.scss'
})
export class ProductsListItemComponent {
  @Input() product!: Product;
}
