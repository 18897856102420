import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Recipe, RecipeForm } from '../_models/recipes.models';

export const RecipeActions = createActionGroup({
  source: 'Recipe',
  events: {
    'Load Recipes': emptyProps(),
    'Load Recipes Success': props<{ recipes: Recipe[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Recipe By Id': props<{ id: string }>(),
    'Get One Success': props<{ recipe: Recipe }>(),
    'Add Recipe': props<{ recipe: RecipeForm }>(),
    'Update Recipe': props<{ recipe: RecipeForm }>(),
    'Purge Recipe': props<{ id: string }>(),

    'Upsert Recipe': props<{ recipe: Recipe }>(),
    'Add Recipes': props<{ recipes: Recipe[] }>(),
    'Upsert Recipes': props<{ recipes: Recipe[] }>(),
    'Update Recipes': props<{ recipes: Update<Recipe>[] }>(),
    'Delete Recipe': props<{ id: string }>(),
    'Delete Recipes': props<{ ids: string[] }>(),
    'Clear Recipes': emptyProps(),
  }
});
