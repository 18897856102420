import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Product } from '../_models/products.models';

export const ProductActions = createActionGroup({
  source: 'Product',
  events: {
    'Load Products': emptyProps(),
    'Load Products Success': props<{ products: Product[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Product By Id': props<{ id: string }>(),
    'Get One Success': props<{ product: Product }>(),
    'Add Product': props<{ product: Product }>(),
    'Update Product': props<{ product: Product }>(),
    'Purge Product': props<{ id: string }>(),

    'Upsert Product': props<{ product: Product }>(),
    'Add Products': props<{ products: Product[] }>(),
    'Upsert Products': props<{ products: Product[] }>(),
    'Update Products': props<{ products: Update<Product>[] }>(),
    'Delete Product': props<{ id: string }>(),
    'Delete Products': props<{ ids: string[] }>(),
    'Clear Products': emptyProps(),
  }
});
