import { Injectable } from '@angular/core';
import { AuthChangeEvent, AuthSession, Session, SupabaseClient, User } from '@supabase/supabase-js';
import { Login, Signup } from '../_store/auth.model';
import { SupabaseService } from '../../shared/_services/supabase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private supabase: SupabaseClient;
  _session: AuthSession | null = null;

  constructor(
    private sbService: SupabaseService
  ) {
    this.supabase = this.sbService.supabase;
    this.authChanges((_, session) => { this._session = session; });
  }

  async login(login: Login) {
    const { data, error } = await this.supabase.auth.signInWithPassword({
      email: login.email,
      password: login.password
    });
    if (error) {
      throw error;
    }
    return data;
  }

  async logout() {
    const { error } = await this.supabase.auth.signOut();
    if(error) throw error;
  }

  async signup(signup: Signup) {
    const { data, error } = await this.supabase.auth.signUp({
      email: signup.email,
      password: signup.password
    });
    if (error) throw error;
    return data;
  }

  async getUser(): Promise<User> {
    const { data, error } = await this.supabase.auth.getUser();
    if(error) throw error;
    return data.user;
  }

  async getSession(): Promise<Session | null> {
    const { data, error } = await this.supabase.auth.getSession();
    if(error) throw error;
    return data.session;
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  async isUserAuthenticated(): Promise<boolean> {
    return await this.getSession() ? true : false;
  }

}
