<div class="flex flex-col">
  @for(machine of (machines$ | async); track machine) {
    <div
      (click)="selectMachine(machine.id)"
      class="active:bg-gray-300 bg-gray-200 rounded-lg h-12 p-3 mb-4 shadow-xs flex items-center transition-all duration-150">
      <div class="flex-1 px-4">
        <div
        [ngClass]="{'text-gray-700': machineId !== machine.id, 'text-gray-800': machineId === machine.id}"
        class="text-sm font-medium">{{ machine.name }}</div>
        </div>
        <div class="flex-shrink-0 text-lg text-gray-500">
          <i
          [style.opacity]="machineId !== machine.id ? 0 : 1"
          class="fas fa-square-check transition-opacity text-green-500 duration-300"></i>
        </div>
    </div>
  }
</div>
