<app-main-page>
  @if (machine && !(loading$ | async)) {
    <app-card-lg [title]="machine.name">
      <div class="flex justify-between">
        <div class="flex text-gray-500 text-sm">
          <div class="w-6 text-center">
            <i class="fa-solid fa-industry"></i>
          </div>
          <div>
            {{ machine.manufacturer }} | {{ machine.model }}
          </div>
        </div>
        <div *ngIf="machine.size" class="flex text-gray-500 text-sm">
          <div>
            {{ machine.size }}
          </div>
          <div class="w-6 text-center">
            <i class="fa-solid fa-mug-hot"></i>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex text-gray-500 text-sm">
          <div class="w-6 text-center">
            <i class="fa-solid fa-location-dot"></i>
          </div>
          <div>
            {{ machine.location }}
          </div>
        </div>
        <div *ngIf="machine.sales_mode" class="flex text-gray-500 text-sm">
          <div>
            {{ machine.sales_mode.replaceAll('_', ' ')  | titlecase }}
          </div>
          <div class="w-6 text-center">
            <i class="fa-solid fa-cash-register"></i>
          </div>
        </div>
      </div>
    </app-card-lg>
    <div class="flex items-center mb-4">
      <app-submit-button class="flex-1 mr-4" [style]="ButtonStyle.PRIMARY" [routerLink]="['edit']">Edit</app-submit-button>
      <app-submit-button class="flex-1" [style]="ButtonStyle.DANGER" (click)="delete()">Delete</app-submit-button>
    </div>
    <app-tab-navigation
      [config]="config"
      [activeIndex]="tab"
      (activeIndexChange)="activeIndexChange($event)"
    ></app-tab-navigation>
    <div [@tabSlideAnimation]="tab">
      <app-sales-list *ngIf="tab === 0" [machineId]="machine.id"></app-sales-list>
      <app-services-list *ngIf="tab === 1" [machineId]="machine.id"></app-services-list>
      <app-issues-list *ngIf="tab === 2" [machineId]="machine.id"></app-issues-list>
      <app-machine-recipes *ngIf="tab === 3" [routerLink]="['recipes/edit']"></app-machine-recipes>
    </div>
  }
  <div class="pb-12"></div>
</app-main-page>
