import { Component } from '@angular/core';

@Component({
  selector: 'app-full-page',
  standalone: true,
  imports: [],
  templateUrl: './full-page.component.html',
  styleUrl: './full-page.component.scss'
})
export class FullPageComponent {

}
