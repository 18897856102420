import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Machine, MachineStatusEnum, SalesModeEnum } from '../../_models/machine.models';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { Store } from '@ngrx/store';
import { MachineActions } from '../../_store/machine.actions';
import { Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { selectMachine, selectMachinesLoading } from '../../_store/machine.reducer';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { CommonModule } from '@angular/common';
import { SizeEnum } from '../../../recipes/_models/recipes.models';

@Component({
  selector: 'app-machine-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './machine-edit.component.html',
  styleUrl: './machine-edit.component.scss'
})
export class MachineEditComponent implements OnInit, OnDestroy {
  id: string | null = null;
  isNew: boolean = false;

  machine$!: Observable<Machine | undefined>;
  loading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  private destroy$ = new Subject<void>();

  machineForm = new FormGroup({});
  model: Machine = {
    name: '',
    manufacturer: '',
    model: '',
    location: '',
    status: MachineStatusEnum.ACTIVE,
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Machine Name',
        placeholder: 'Enter machine name',
        required: true
      }
    },
    {
      key: 'manufacturer',
      type: 'input',
      props: {
        label: 'Manufacturer',
        placeholder: 'Enter manufacturer',
        required: true
      }
    },
    {
      key: 'model',
      type: 'input',
      props: {
        label: 'Model',
        placeholder: 'Enter model',
        required: true
      }
    },
    {
      key: 'location',
      type: 'input',
      props: {
        label: 'Location',
        placeholder: 'Enter location',
        required: true
      }
    },
    {
      key: 'sales_mode',
      type: 'select',
      props: {
        label: 'Sales Mode',
        options: [
          { label: 'Billable to Client', value: SalesModeEnum.BILLABLE_TO_CLIENT },
          { label: 'Credit Card', value: SalesModeEnum.CREDIT_CARD },
          { label: 'Free Vend', value: SalesModeEnum.FREE_VEND }
        ]
      }
    },
    {
      key: 'size',
      type: 'select',
      props: {
        label: 'Beverage Size',
        options: [{ label: '8oz', value: SizeEnum['8OZ'] }, { label: '10oz', value: SizeEnum['10OZ'] }, { label: '12oz', value: SizeEnum['12OZ'] }],
      }
    }
  ]


  constructor(private route: ActivatedRoute, private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.isNew = urlSegments.some(segment => segment.path === 'new');
      this.id = this.isNew ? null : this.route.snapshot.paramMap.get('id');
      if (this.id) {
        this.machine$ = this.store.select(selectMachine(this.id));
        this.machine$.pipe(takeWhile(m => m == undefined, true)).subscribe(machine => {
          if (machine) {
            setTimeout(() => {
              this.machineForm.patchValue({
                name: machine.name,
                manufacturer: machine.manufacturer,
                model: machine.model,
                location: machine.location,
                size: machine.size,
                sales_mode: machine.sales_mode
              });
            });
          } else {
            this.store.dispatch(MachineActions.getMachineById({ id: this.id as string }));
          }
        });
      }
    });
  }

  onSubmit(machine: Machine) {
    if (this.machineForm.valid && this.isNew) {
      this.store.dispatch(MachineActions.addMachine({ machine }));
    } else if (this.machineForm.valid && this.id) {
      this.store.dispatch(MachineActions.updateMachine({ machine: { ...machine, id: this.id }}));
    }
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      if (!loading) {
        const url = this.id ? `/machines/${this.id}` : '/machines'
        this.router.navigate([url]);
      }
    })
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

}
