import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyInputComponent } from '../../_components/forms/formly-input/formly-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule as FormlyCoreSelectModule } from '@ngx-formly/core/select';
import { FormFieldWrapperComponent } from '../../_components/forms/form-field-wrapper/form-field-wrapper.component';
import { FormlyRepeatSectionComponent } from '../../_components/forms/formly-repeat-section/formly-repeat-section.component';
import { FormlySelectComponent } from '../../_components/forms/formly-select/formly-select.component';
import { FormlyTextareaComponent } from '../../_components/forms/formly-textarea/formly-textarea.component';
import { FormlyBooleanComponent } from '../../_components/forms/formly-boolean/formly-boolean.component';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyCoreSelectModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'form-field',
          component: FormFieldWrapperComponent,
        },
      ],
      types: [
        {
          name: 'input',
          component: FormlyInputComponent,
          wrappers: ['form-field'],
        },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        {
          name: 'select',
          component: FormlySelectComponent,
          wrappers: ['form-field']
        },
        {
          name: 'enum',
          extends: 'select'
        },
        {
          name: 'repeat',
          component: FormlyRepeatSectionComponent
        },
        {
          name: 'textarea',
          component: FormlyTextareaComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'boolean',
          component: FormlyBooleanComponent
        }
      ],
    })
  ]
})
export class FormsModule { }
