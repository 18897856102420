import { Injectable } from '@angular/core';
import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {
  private _supabase: SupabaseClient
  constructor() {
    this._supabase = createClient(environment.supabaseConfig.url, environment.supabaseConfig.key);
  }

  get supabase() {
    return this._supabase;
  }

}
