import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ClickOutsideDirective } from '../../../shared/_directives/click-outside.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenubarButtonComponent } from '../menubar-button/menubar-button.component';
import { Router } from '@angular/router';
import { NavigationAction } from '../../_models/navigation.models';
import { Observable, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectMenu } from '../../_store/navigation.reducer';
import { NavigationActions } from '../../_store/navigation.actions';

@Component({
  selector: 'app-menubar',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, MenubarButtonComponent],
  templateUrl: './menubar.component.html',
  styleUrl: './menubar.component.scss',
  animations: [
    trigger('rotate', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(135deg)' })),
      transition('default <=> rotated', animate('250ms ease-out')),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
  ],
})
export class MenubarComponent {
  menu$: Observable<NavigationAction[] | null> = this.store.select(selectMenu);
  showMenu: boolean = false;

  constructor(
    private router: Router,
    private store: Store
  ) {
    this.router.events
      .pipe(filter(event => event.constructor.name === 'NavigationStart'))
      .subscribe(() => this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: null })));
  }

  onActionSelected(action: NavigationAction) {
    if (action.url) {
      // Navigate to the URL
      this.router.navigateByUrl(action.url);
    } else if (action.action) {
      // Dispatch the NgRx action
      // this.store.dispatch(action.ngrxAction);
    }
    this.showMenu = false;
  }

  toggleMenu(event: MouseEvent) {
    event.stopPropagation();
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    if (this.showMenu) this.showMenu = false;
  }
}
