import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor() { }

  priceToInt(price: number): number {
    return price * 100;
  }

  priceToFloat(price: number): number {
    return price / 100;
  }
}
