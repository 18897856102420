@if (serviceMachineId) {
  <div *appLoading="(salesLoading$ | async) || (machinesLoading$ | async)"></div>
  @if (!(salesLoading$ | async) && !(machinesLoading$ | async)) {
    <div class="overflow-y-scroll overflow-x-hidden max-h-[calc(100vh-12rem)] rounded-lg">
      <form [formGroup]="saleForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md flex flex-col w-full rounded-lg px-4 pt-6 pb-8 mb-4 mr-4">
        <formly-form [form]="saleForm" [fields]="fields" [options]="options" [model]="model"></formly-form>
        <app-submit-button>{{ isNew ? 'Create' : 'Save' }}</app-submit-button>
      </form>
    </div>
  }
} @else {
  <app-main-page>
    <div *appLoading="(salesLoading$ | async) || (machinesLoading$ | async)"></div>
    @if (!(salesLoading$ | async) && !(machinesLoading$ | async)) {
      <form [formGroup]="saleForm" (ngSubmit)="onSubmit(model)" class="bg-white shadow-md rounded-lg px-4 pt-6 pb-8 mb-4">
        <formly-form [form]="saleForm" [fields]="fields" [options]="options" [model]="model"></formly-form>
        <app-submit-button>{{ isNew ? 'Create' : 'Save' }}</app-submit-button>
      </form>
    }
  </app-main-page>
}
