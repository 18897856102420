import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Issue } from '../_models/issues.model';
import { QueryStore } from '../../shared/_models/query.models';

export const IssueActions = createActionGroup({
  source: 'Issue/API',
  events: {
    'Load Issues': props<{ query?: QueryStore<Issue> }>(),
    'Load Issues Success': props<{ issues: Issue[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Issue by Id': props<{ id: string }>(),
    'Get One Success': props<{ issue: Issue }>(),
    'Add Issue': props<{ issue: Issue }>(),
    'Update Issue': props<{ issue: Issue }>(),
    'Upsert Issue': props<{ issue: Issue }>(),
    'Purge Issue': props<{ id: string }>(),
    'Delete Issue': props<{ id: string }>(),

    'Add Issues': props<{ issues: Issue[] }>(),
    'Upsert Issues': props<{ issues: Issue[] }>(),
    'Update Issues': props<{ issues: Update<Issue>[] }>(),
    'Delete Issues': props<{ ids: string[] }>(),
    'Clear Issues': emptyProps(),
  }
});
