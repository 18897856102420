import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { ProductsListItemComponent } from '../products-list-item/products-list-item.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Observable, take } from 'rxjs';
import { Product } from '../../_models/products.models';
import { selectProducts, selectProductsLoading } from '../../_store/product.reducer';
import { Store } from '@ngrx/store';
import { ProductActions } from '../../_store/product.actions';

@Component({
  selector: 'app-products-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, ProductsListItemComponent, AppLoadingDirective],
  templateUrl: './products-list.component.html',
  styleUrl: './products-list.component.scss'
})
export class ProductsListComponent {
  products$: Observable<Product[]> = this.store.select(selectProducts);
  loading$: Observable<boolean> = this.store.select(selectProductsLoading);

  constructor(
    private store: Store
  ) { this.store.dispatch(ProductActions.loadProducts()); }

}
