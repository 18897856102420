import { Injectable } from '@angular/core';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { SupabaseClient } from '@supabase/supabase-js';
import { Machine, MachineRecipe, MachineRecipeSubmit } from '../_models/machine.models';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class MachinesService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getMachines(): Promise<Machine[]> {
    const { data, error } = await this.supabase.from('machines').select('*');
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Machines');
      throw error;
    };
    return data;
  }

  async getMachineById(id: string): Promise<Machine> {
    const { data, error } = await this.supabase.from('machines').select('*').eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Machine by Id');
      throw error;
    };
    return data;
  }

  async createMachine(machine: Machine) {
    const { data, error } = await this.supabase.from('machines').insert(machine);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Create Machine');
      throw error;
    };
    return data;
  }

  async updateMachine(machine: Machine) {
    const { data, error } = await this.supabase.from('machines').update(machine).eq('id', machine.id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Udpate Machine');
      throw error;
    };
    return data;
  }

  async getMachineRecipes(id: string): Promise<MachineRecipe[]> {
    const { data, error } = await this.supabase.from('machine_recipes')
    .select(`
      id,
      recipe_order,
      machine_reference,
      machine_id,
      price,
      recipe:recipe_id(id,name)
    `).eq('machine_id', id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Machine Recipes');
      throw error;
    };
    return data as any as MachineRecipe[];
  }

  async upserMachineRecipes(recipes_data: MachineRecipeSubmit[]) {
    const { data, error } = await this.supabase.rpc('upsert_machine_recipes', { recipes_data });
    if (error) {
      this.apiErrorService.handleApiError(error, 'Upser Machine Recipes');
      throw error;
    };
    return data;
  }
}
