<input
  *ngIf="type !== 'number'; else numberTmp"
  [type]="type"
  [formControl]="formControl"
  class="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-500 placeholder:text-gray-400 leading-tight focus:outline-none focus:shadow-md focus:ring-1"
  [formlyAttributes]="field"
  [ngClass]="{'border-red-400': showError, 'border-gray-300': !showError}"
  [attr.aria-describedby]="id + '-formly-validation-error'"
  [attr.aria-invalid]="showError"
/>
<ng-template #numberTmp>
  <input
    type="number"
    inputmode="decimal"
    [formControl]="formControl"
    class="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-500 placeholder:text-gray-400 leading-tight focus:outline-none focus:shadow-md focus:ring-1"
    [formlyAttributes]="field"
    [ngClass]="{'border-red-400': showError, 'border-gray-300': !showError}"
    [class.is-invalid]="showError"
    [attr.aria-describedby]="id + '-formly-validation-error'"
    [attr.aria-invalid]="showError"
  />
</ng-template>
