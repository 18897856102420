import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { Sale } from '../../_models/sales.models';
import { Machine } from '../../../machines/_models/machine.models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sales-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, CardListItemComponent],
  templateUrl: './sales-list-item.component.html',
  styleUrl: './sales-list-item.component.scss'
})
export class SalesListItemComponent {
  @Input() sale!: Sale;
  @Input() machine!: Machine;
  @Input() machineId: string | undefined;
}
