<app-card-list-item [routerLink]="['products', product.id]">
  <!-- <div class="flex-shrink-0">
    <span class="block h-4 w-4 rounded-full bg-green-500 mr-2"></span>
  </div> -->
  <div class="flex-1 px-4">
    <div class="text-sm font-medium">{{ product.name }}</div>
    <div class="text-xs text-gray-600">Quantity on hand: {{ product.quantity_on_hand / product.package_size | number:'1.0-1' }} packages ({{ product.package_size }}{{ product.unit }})</div>
    <div class="text-xs text-gray-600">Par level: {{ product.par_level / product.package_size | number:'1.0-1' }}</div>
    <div class="relative mt-2">
      <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
        <div
          [style.width]="(product.quantity_on_hand / product.par_level) * 100 + '%'"
          [ngClass]="{
            'bg-red-500': (product.quantity_on_hand / product.par_level) < 0.3,
            'bg-orange-500': (product.quantity_on_hand / product.par_level) >= 0.3 && (product.quantity_on_hand / product.par_level) < 0.7,
            'bg-green-500': (product.quantity_on_hand / product.par_level) >= 0.7
          }"
          class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
        ></div>
      </div>
    </div>
  </div>
  <!-- <div class="flex-shrink-0 text-gray-500">
    <i class="fas fa-chevron-right"></i>
  </div> -->
</app-card-list-item>
