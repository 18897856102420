import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { QueryService } from '../../shared/_services/query.service';
import { State } from '../_store/recipe.reducer';
import { Recipe, RecipeForm } from '../_models/recipes.models';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  private supabase: SupabaseClient;
  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getRecipes(config: State['query']): Promise<Recipe[]> {
    let query = this.supabase.from('recipes')
    .select(`
      id,
      name,
      description,
      size,
      recipe_products(id, product_quantity, water_quantity, sequence_order, product:products(id, name, unit, package_size, mix_with_water))
    `);
    query = this.queryService.applyQueryConfig(query, config);
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Recipes');
      throw error;
    };
    return data as any as Recipe[];
  }

  async getRecipeById(id: string): Promise<Recipe> {
    const { data, error } = await this.supabase.from('recipes')
    .select(`
      id,
      name,
      description,
      size,
      recipe_products(id, product_quantity, water_quantity, sequence_order, product:products(id, name, unit, package_size, mix_with_water))
    `)
    .eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Recipe by Id');
      throw error;
    };
    return data as any as Recipe;
  }

  async upsertRecipe(recipe: RecipeForm) {
    const { recipe_details, product_details } = this.mapRecipeToRequest(recipe);
    const { data, error } = await this.supabase
    .rpc('upsert_full_recipe', {
      product_details,
      recipe_details
    });
    if (error) {
      this.apiErrorService.handleApiError(error, 'Upsert Recipe');
      throw error;
    };
    return data;
  }

  mapRecipeToRequest(recipe: RecipeForm) {
    const recipe_details: Recipe = {
      id: recipe.id || undefined,
      name: recipe.name,
      description: recipe.description || undefined,
      size: recipe.size
    }
    let product_details: RecipeForm['recipe_products'] = [];
    product_details = recipe.recipe_products.map((rp, i) => ({
      product_id: rp.product_id,
      product_quantity: rp.product_quantity,
      water_quantity: rp.water_quantity || undefined,
      sequence_order: i
    }));
    return { recipe_details, product_details };
  }
}
