import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { Observable, EMPTY, of, from } from 'rxjs';
import { IssueActions } from './issue.actions';
import { IssuesService } from '../_services/issues.service';
import { Store } from '@ngrx/store';
import { selectIssuesQuery } from './issue.reducer';


@Injectable()
export class IssueEffects {

  loadIssues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueActions.loadIssues),
      concatMap((action) =>
        from(this.service.getIssues(action.query)).pipe(
          map(issues => IssueActions.loadIssuesSuccess({ issues })),
          catchError(error => of(IssueActions.apiFailure({ error })))
        )
      )
    );
  });

  getIssueById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueActions.getIssueById),
      concatMap((action) =>
        from(this.service.getIssueById(action.id)).pipe(
          map(issue => IssueActions.getOneSuccess({ issue })),
          catchError(error => of(IssueActions.apiFailure({ error })))
        )
      )
    );
  });

  addIssue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueActions.addIssue),
      withLatestFrom(this.store.select(selectIssuesQuery)),
      concatMap(([action, query]) =>
        from(this.service.createIssue(action.issue)).pipe(
          map(() => IssueActions.loadIssues({ query })),
          catchError(error => of(IssueActions.apiFailure({ error })))
        )
      )
    );
  });

  updateIssue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueActions.updateIssue),
      concatMap((action) =>
        from(this.service.updateIssue(action.issue)).pipe(
          map(() => IssueActions.getOneSuccess({ issue: action.issue })),
          catchError(error => of(IssueActions.apiFailure({ error })))
        )
      )
    );
  });

  deleteIssue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueActions.deleteIssue),
      concatMap((action) =>
        from(this.service.deleteIssue(action.id)).pipe(
          map(() => IssueActions.purgeIssue({ id: action.id })),
          catchError(error => of(IssueActions.apiFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private service: IssuesService, private store: Store) {}
}
