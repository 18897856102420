import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';
import { Transaction } from '../../_models/transactions.model';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { selectTransaction, selectTransactionsLoading } from '../../_store/transaction.reducer';
import { TransactionActions } from '../../_store/transaction.actions';
import { ButtonStyle } from '../../../shared/_models/styles.enums';
import { CommonModule } from '@angular/common';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';

@Component({
  selector: 'app-transaction',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent],
  templateUrl: './transaction.component.html',
  styleUrl: './transaction.component.scss'
})
export class TransactionComponent implements OnInit {
  id: string | undefined;
  transaction$!: Observable<Transaction | undefined>;
  transaction!: Transaction | undefined;
  loading$: Observable<boolean> = this.store.select(selectTransactionsLoading);

  constructor(private route: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.transaction$ = this.store.select(selectTransaction(this.id));
    this.store.dispatch(TransactionActions.getTransactionById({ id: this.id as string }));
    this.transaction$.pipe(takeWhile(t => t == undefined, true)).subscribe(transaction => {
      if (transaction) {
        this.transaction = transaction;
      }
    });
  }

  delete() {

  }

  get ButtonStyle() {
    return ButtonStyle;
  }
}
