import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { TransactionsListItemComponent } from '../transactions-list-item/transactions-list-item.component';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Transaction } from '../../_models/transactions.model';
import { selectTransactions, selectTransactionsLoading } from '../../_store/transaction.reducer';
import { TransactionActions } from '../../_store/transaction.actions';
import { Operator, Order, QueryStore } from '../../../shared/_models/query.models';

@Component({
  selector: 'app-transactions-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, TransactionsListItemComponent, AppLoadingDirective],
  templateUrl: './transactions-list.component.html',
  styleUrl: './transactions-list.component.scss'
})
export class TransactionsListComponent implements OnInit, OnDestroy {
  @Input() warehouseId: string | undefined;

  transactions$: Observable<Transaction[]> = this.store.select(selectTransactions);
  loading$: Observable<boolean> = this.store.select(selectTransactionsLoading);
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    let config: QueryStore<Transaction> | undefined;
    config = {
      filters: [],
      pagination: { limit: 10, page: 1},
      order: { column: 'transaction_date', order: Order.DESCENDING }
    }
    // if (this.warehouseId) {
    //   config.filters.push({ operator: Operator.Equal, column: 'warehouse_id', value: this.warehouseId });
    // }
    this.store.dispatch(TransactionActions.loadTransactions({ query: config }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
