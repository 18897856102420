import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { Warehouse } from '../../_models/warehouses.models';
import { selectWarehouse, selectWarehousesLoading } from '../../_store/warehouse.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WarehouseActions } from '../../_store/warehouse.actions';

@Component({
  selector: 'app-warehouse-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './warehouse-edit.component.html',
  styleUrl: './warehouse-edit.component.scss'
})
export class WarehouseEditComponent implements OnInit, OnDestroy {
  id: string | null = null;
  isNew: boolean = false;

  warehouse$!: Observable<Warehouse | undefined>;
  loading$: Observable<boolean> = this.store.select(selectWarehousesLoading);
  private destroy$ = new Subject<void>();

  warehouseForm = new FormGroup({});
  model: Warehouse = {
    name: '',
    description: '',
    location: '',
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Warehouse Name',
        placeholder: 'Enter warehouse name',
        required: true
      }
    },
    {
      key: 'description',
      type: 'input',
      props: {
        label: 'Description',
        placeholder: 'Enter description',
        required: true
      }
    },
    {
      key: 'location',
      type: 'input',
      props: {
        label: 'Location',
        placeholder: 'Location',
        required: true
      }
    },
  ]


  constructor(private route: ActivatedRoute, private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.isNew = urlSegments.some(segment => segment.path === 'new');
      this.id = this.isNew ? null : this.route.snapshot.paramMap.get('id');
      if (this.id) {
        this.warehouse$ = this.store.select(selectWarehouse(this.id));
        this.warehouse$.pipe(takeWhile(w => w == undefined, true)).subscribe(warehouse => {
          if (warehouse) {
            setTimeout(() => {
              this.warehouseForm.patchValue({...warehouse});
            });
          } else {
            this.store.dispatch(WarehouseActions.getWarehouseById({ id: this.id as string }));
          }
        });
      }
    });
  }

  onSubmit(warehouse: Warehouse) {
    if (this.warehouseForm.valid && this.isNew) {
      this.store.dispatch(WarehouseActions.addWarehouse({ warehouse }));
    } else if (this.warehouseForm.valid && this.id) {
      this.store.dispatch(WarehouseActions.updateWarehouse({ warehouse: { ...warehouse, id: this.id }}));
    }
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      if (!loading) {
        const url = this.id ? `/inventory/warehouses/${this.id}` : '/inventory/warehouses'
        this.router.navigate([url]);
      }
    })
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
