import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { TenantActions } from './tenant.actions';
import { TenantService } from '../_services/tenant.service';


@Injectable()
export class TenantEffects {

  loadTenants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TenantActions.loadTenants),
      concatMap(() =>
        from(this.service.getTenants()).pipe(
          map(data => TenantActions.loadTenantsSuccess({ tenants: data })),
          catchError(error => of(TenantActions.loadTenantsFailure({ error }))))
      )
    );
  });


  constructor(private actions$: Actions, private service: TenantService) {}
}
