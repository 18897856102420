import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-menubar-button',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './menubar-button.component.html',
  styleUrl: './menubar-button.component.scss'
})
export class MenubarButtonComponent {
  @Input() link: string = '';
  @Input() icon: string = '';
  @Input() text: string = '';
}
