import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { Observable, EMPTY, of, from } from 'rxjs';
import { ServiceActions } from './service.actions';
import { ServicesService } from '../_services/services.service';


@Injectable()
export class ServiceEffects {

  loadServices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceActions.loadServices),
      concatMap((action) =>
        from(this.service.getServices(action.query)).pipe(
          map(services => ServiceActions.loadServicesSuccess({ services })),
          catchError(error => of(ServiceActions.apiFailure({ error })))
        )
      )
    );
  });

  upsertService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceActions.upsertService),
      concatMap((action) =>
        from(this.service.upsertService(action.service)).pipe(
          map((id) => ServiceActions.apiSuccess({ id })),
          catchError(error => of(ServiceActions.apiFailure({ error })))
        )
      )
    );
  });

  getServiceById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceActions.getServiceById),
      concatMap((action) =>
        from(this.service.getServiceById(action.id)).pipe(
          map((service) => ServiceActions.getOneSuccess({ service })),
          catchError(error => of(ServiceActions.apiFailure({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private service: ServicesService) {}
}
