import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { IssuesListComponent } from '../issues-list/issues-list.component';
import { Store } from '@ngrx/store';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';
import { NavigationAction, NavigationActionTypes } from '../../../navigation/_models/navigation.models';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';

@Component({
  selector: 'app-issues-home',
  standalone: true,
  imports: [MainPageComponent, IssuesListComponent],
  templateUrl: './issues-home.component.html',
  styleUrl: './issues-home.component.scss'
})
export class IssuesHomeComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.ReportIssue]) }));
  }
}
