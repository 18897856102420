import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Machine } from '../../_models/machine.models';
import { selectMachines, selectMachinesLoading } from '../../_store/machine.reducer';
import { MachineActions } from '../../_store/machine.actions';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';

@Component({
  selector: 'app-machines-list-select',
  standalone: true,
  imports: [CommonModule, CardListItemComponent],
  templateUrl: './machines-list-select.component.html',
  styleUrl: './machines-list-select.component.scss'
})
export class MachinesListSelectComponent {
  @Input() machineId: Machine['id'];
  @Output() onSelectMachine = new EventEmitter<Machine['id']>();
  machines$: Observable<Machine[]> = this.store.select(selectMachines);
  loading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  selected: Machine['id'] = '';

  constructor(
    private store: Store
  ) {}

  ngOnInit(): void {
    this.machines$.pipe(take(1)).subscribe(machines => {
      if(machines.length < 2) {
        this.store.dispatch(MachineActions.loadMachines());
      }
    });
  }

  selectMachine(id: Machine['id']) {
    this.selected = id;
    this.onSelectMachine.emit(id);
  }
}
