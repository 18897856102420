export interface Service {
  id?: string;
  machine_id?: string;
  service_status: ServiceStatusEnum;
  started_at: string;
  completed_at?: string;
  steps?: ServiceStep[]
}

export enum ServiceStatusEnum {
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED'
}

export interface ServiceStep {
  id: number;
  action?: ServiceStepActions
  service_id?: string;
  name: string;
  disableScroll?: boolean;
  started_at?: string;
  completed_at?: string;
  checklist?: ServiceChecklistItem[];
  step_data?: {
    action?: ServiceStepActions, checklist?: ServiceChecklistItem[], disableScroll?: boolean
  };
}

export enum ServiceStepActions {
  IDENTIFY_MACHINE,
  EDIT_INVENTORY,
  RECORD_SALES,
  REPORT_ISSUE
}

export interface ServiceChecklistItem {
  id: number;
  name: string;
  content?: string[];
  isChecked?: boolean;
}
