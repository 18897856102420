import { Component } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthActions } from '../../_store/auth.actions';
import { RouterLink } from '@angular/router';
import { Login } from '../../_store/auth.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, CardLgComponent, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  submitted = false;

  constructor(private store: Store) {}

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.store.dispatch(AuthActions.login({ login: this.loginForm.value as Login }));
    }
  }

  hasError(controlName: keyof typeof this.loginForm.controls, errorName: string): boolean {
    return this.loginForm.controls[controlName].hasError(errorName) && (this.loginForm.controls[controlName].touched || this.submitted);
  }

}
