import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Product } from '../_models/products.models';
import { ProductActions } from './product.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const productsFeatureKey = 'products';

export interface State extends EntityState<Product> {
  // additional entities state properties
  loading: boolean;
  error: string | null;
  query: QueryStore<Product>;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 20,
    },
    order: {
      column: 'name',
      order: Order.ASCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(ProductActions.loadProducts,
    (state, action) => ({ ...state, loading: true })
  ),
  on(ProductActions.loadProductsSuccess,
    (state, action) => adapter.setAll(action.products, {...state, loading: false })
  ),
  on(ProductActions.getProductById, (state, _action) => ({ ...state, loading: true })),
  on(ProductActions.updateProduct, (state, _action) => ({ ...state, loading: true })),
  on(ProductActions.getOneSuccess,
    (state, action) => adapter.addOne(action.product, { ...state, loading: false })
  ),
  on(ProductActions.apiSuccess, (state, _action) => ({ ...state, loading: false })),
  on(ProductActions.apiFailure, (state, _action) => ({ ...state, loading: false })),
  on(ProductActions.addProduct,
    (state, action) => adapter.addOne(action.product, state)
  ),
  on(ProductActions.upsertProduct,
    (state, action) => adapter.upsertOne(action.product, state)
  ),
  on(ProductActions.addProducts,
    (state, action) => adapter.addMany(action.products, state)
  ),
  on(ProductActions.upsertProducts,
    (state, action) => adapter.upsertMany(action.products, state)
  ),
  on(ProductActions.updateProducts,
    (state, action) => adapter.updateMany(action.products, state)
  ),
  on(ProductActions.purgeProduct,
    (state, action) => adapter.removeOne(action.id, {...state, loading: false,})
  ),
  on(ProductActions.deleteProducts,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ProductActions.clearProducts,
    state => adapter.removeAll(state)
  ),
);

export const productsFeature = createFeature({
  name: productsFeatureKey,
  reducer,
  extraSelectors: ({ selectProductsState }) => ({
    ...adapter.getSelectors(selectProductsState)
  }),
});

export const {
  selectIds: selectProductIds,
  selectEntities: selectProductEntities,
  selectAll: selectProducts,
  selectTotal,
  selectLoading: selectProductsLoading,
  selectError: selectProductsError,
  selectQuery: selectProductsQuery
} = productsFeature;

export const selectProduct = (id: string) => createSelector(
  selectProductEntities,
  entities => entities[id]
)
