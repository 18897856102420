<div class="flex mb-4">
  @for (tab of config; track $index) {
    <div
      (click)="onIndexChange(tab.index)"
      [ngClass]="tab.index === activeIndex ? 'bg-gray-600' : 'bg-gray-400'"
      class="flex-auto text-center text-white rounded-lg text-sm font-medium p-1 mr-2 transition-all delay-200 last-of-type:mr-0">
      {{ tab.label }}
    </div>
  }
</div>
