<app-card-lg [title]="'Product Map'">
  <div *appLoading="(recipesLoading$ | async)"></div>
  @if(!(recipesLoading$ | async)) {
    <div class="grid grid-cols-2 gap-4">
      @for (recipe of (recipes$ | async); track recipe) {
        <div class="bg-gray-100  rounded-lg p-3 flex items-center shadow-sm">
          <div class="flex-shrink-0 bg-gray-300 text-center p-2 rounded-l-lg" style="margin: -0.75rem; margin-right: .75rem;">
            <div class="font-semibold">{{ recipe.machine_reference }}</div>
            <div class="text-xs">{{ recipe.price / 100 | currency}}</div>
          </div>
          <div class="line-clamp-2">
            <div class="text-sm font-medium">{{ recipe.recipe.name }}</div>
          </div>
        </div>
      }
    </div>
  }
</app-card-lg>
