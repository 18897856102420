import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Transaction } from '../_models/transactions.model';
import { QueryStore } from '../../shared/_models/query.models';

export const TransactionActions = createActionGroup({
  source: 'Transaction',
  events: {
    'Load Transactions': props<{ query?: QueryStore<Transaction> }>(),
    'Load Transactions Success': props<{ transactions: Transaction[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Transaction By Id': props<{ id: string }>(),
    'Get Transaction By Service Id': props<{ service_id: string }>(),
    'Get One Success': props<{ transaction: Transaction }>(),
    'Add Transaction': props<{ transaction: Transaction }>(),
    'Upsert Transaction': props<{ transaction: Transaction }>(),
    'Purge Transaction': props<{ id: string }>(),


    'Add Transactions': props<{ transactions: Transaction[] }>(),
    'Upsert Transactions': props<{ transactions: Transaction[] }>(),
    'Update Transaction': props<{ transaction: Update<Transaction> }>(),
    'Update Transactions': props<{ transactions: Update<Transaction>[] }>(),
    'Delete Transaction': props<{ id: string }>(),
    'Delete Transactions': props<{ ids: string[] }>(),
    'Clear Transactions': emptyProps(),
  }
});
