import { Machine } from "../../machines/_models/machine.models";

export interface Issue {
  id?: string;
  machine_id: string;
  machine?: Machine;
  service_id?: string;
  service_issue: IssueEnum;
  details?: string;
  issue_status: IssueStatusEnum;
  created_at?: string;
  updated_at?: string;
}

export enum IssueEnum {
  CLOG = 'CLOG',
  LOW_PRODUCT = 'LOW_PRODUCT',
  LOW_WATER = 'LOW_WATER',
  PAYMENT_SYSTEM = 'PAYMENT_SYSTEM',
  BREW_GROUP = 'BREW_GROUP',
  PLUMBING = 'PLUMBING',
  ELECTRICAL = 'ELECTRICAL'
}

export enum IssueStatusEnum {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  CLOSED_WONT_FIX = 'CLOSED_WONT_FIX'
}

export interface NewIssue extends Omit<Issue, 'id' | 'machine' | 'created_at' | 'updated_at'> {}

