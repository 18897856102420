import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalActions } from '../_store/modal.actions';
import { Observable, Subject } from 'rxjs';
import { ErrorModalComponent } from '../_components/error-modal/error-modal.component';
import { ModalComponentEnum } from '../_models/modal.models';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  private modalDecision = new Subject<boolean>();
  modalDecision$: Observable<boolean> = this.modalDecision.asObservable();

  constructor(private store: Store) { }

  open(component: any, data: any = null) {
    this.store.dispatch(ModalActions.showModal({ component, data }));
  }

  close() {
    this.store.dispatch(ModalActions.hideModal());
  }

  confirm() {
    this.modalDecision.next(true);
    this.close();
  }

  reject() {
    this.modalDecision.next(false);
    this.close();
  }

  componentMappings = {
    [ModalComponentEnum.ERROR] : ErrorModalComponent,
  }
}
