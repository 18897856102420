import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Warehouse } from '../_models/warehouses.models';

export const WarehouseActions = createActionGroup({
  source: 'Warehouse',
  events: {
    'Load Warehouses': emptyProps(),
    'Load Warehouses Success': props<{ warehouses: Warehouse[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Warehouse By Id': props<{ id: string }>(),
    'Get One Success': props<{ warehouse: Warehouse }>(),
    'Add Warehouse': props<{ warehouse: Warehouse }>(),
    'Update Warehouse': props<{ warehouse: Warehouse }>(),
    'Purge Warehouse': props<{ id: string }>(),

    'Upsert Warehouse': props<{ warehouse: Warehouse }>(),
    'Add Warehouses': props<{ warehouses: Warehouse[] }>(),
    'Upsert Warehouses': props<{ warehouses: Warehouse[] }>(),
    'Update Warehouses': props<{ warehouses: Update<Warehouse>[] }>(),
    'Delete Warehouse': props<{ id: string }>(),
    'Delete Warehouses': props<{ ids: string[] }>(),
    'Clear Warehouses': emptyProps(),
  }
});
