import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Warehouse } from '../_models/warehouses.models';
import { WarehouseActions } from './warehouse.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const warehousesFeatureKey = 'warehouses';

export interface State extends EntityState<Warehouse> {
  // additional entities state properties
  loading: boolean;
  error: string | null;
  query: QueryStore<Warehouse>;
}

export const adapter: EntityAdapter<Warehouse> = createEntityAdapter<Warehouse>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 10,
    },
    order: {
      column: 'name',
      order: Order.ASCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(WarehouseActions.loadWarehouses,
    (state, action) => ({ ...state, loading: true })
  ),
  on(WarehouseActions.loadWarehousesSuccess,
    (state, action) => adapter.setAll(action.warehouses, {...state, loading: false })
  ),
  on(WarehouseActions.getWarehouseById, (state, _action) => ({ ...state, loading: true })),
  on(WarehouseActions.updateWarehouse, (state, _action) => ({ ...state, loading: true })),
  on(WarehouseActions.getOneSuccess,
    (state, action) => adapter.addOne(action.warehouse, { ...state, loading: false })
  ),
  on(WarehouseActions.apiSuccess, (state, _action) => ({ ...state, loading: false })),
  on(WarehouseActions.apiFailure, (state, _action) => ({ ...state, loading: false })),
  on(WarehouseActions.addWarehouse,
    (state, action) => adapter.addOne(action.warehouse, state)
  ),
  on(WarehouseActions.upsertWarehouse,
    (state, action) => adapter.upsertOne(action.warehouse, state)
  ),
  on(WarehouseActions.addWarehouses,
    (state, action) => adapter.addMany(action.warehouses, state)
  ),
  on(WarehouseActions.upsertWarehouses,
    (state, action) => adapter.upsertMany(action.warehouses, state)
  ),
  on(WarehouseActions.updateWarehouses,
    (state, action) => adapter.updateMany(action.warehouses, state)
  ),
  on(WarehouseActions.purgeWarehouse,
    (state, action) => adapter.removeOne(action.id, {...state, loading: false,})
  ),
  on(WarehouseActions.deleteWarehouses,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(WarehouseActions.clearWarehouses,
    state => adapter.removeAll(state)
  ),
);

export const warehousesFeature = createFeature({
  name: warehousesFeatureKey,
  reducer,
  extraSelectors: ({ selectWarehousesState }) => ({
    ...adapter.getSelectors(selectWarehousesState)
  }),
});

export const {
  selectIds: selectWarehouseIds,
  selectEntities: selectWarehouseEntities,
  selectAll: selectWarehouses,
  selectTotal,
  selectLoading: selectWarehousesLoading,
  selectError: selectWarehousesError,
  selectQuery: selectWarehousesQuery
} = warehousesFeature;

export const selectWarehouse = (id: string) => createSelector(
  selectWarehouseEntities,
  entities => entities[id]
)
