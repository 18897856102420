<!-- Bottom Menu Bar -->
<div class="fixed z-10 inset-x-0 bottom-0 left-0 right-0 bg-blue-900 shadow-lg py-1 pb-safe-bottom">
  <div class="flex justify-between items-center max-w-screen-xl mx-auto px-4 relative">

    <div class="flex justify-around flex-1">
      <app-menubar-button [icon]="'fa-home'" [text]="'Home'" [link]="'/'"/>
      <app-menubar-button [icon]="'fa-warehouse'" [text]="'Inventory'" [link]="'/inventory'"/>
      <app-menubar-button [icon]="'fa-server'" [text]="'Machines'" [link]="'/machines'"/>
      <app-menubar-button [icon]="'fa-bars'" [text]="'More'" [link]="'/more'"/>
    </div>

    <div *ngIf="(menu$ | async)" (click)="toggleMenu($event)" class="cursor-pointer fixed bottom-16 right-3 mb-safe-bottom z-30">
      <div class="flex items-center justify-center h-12 w-12 box-content border-blue-900 border-2 bg-blue-900 rounded-full text-white absolute bottom-0 right-0">
        <i [@rotate]="showMenu ? 'rotated' : 'default'" class="fas fa-plus text-2xl"></i>
      </div>
      <div #menuBar [class.active]="showMenu" (clickOutside)="closeMenu()" class="slide-up-menu w-max z-6 absolute bottom-16 right-0 rounded-t-3xl py-2 transition-transform duration-300">
        <ul class="">
          <li *ngFor="let action of menu$ | async" (click)="onActionSelected(action)" class="flex items-center py-2 my-2 px-6 bg-white rounded-md">
            <a class="flex items-center space-x-3">
              <i [ngClass]="action.icon" class="fas text-gray-600"></i>
              <span class="text-gray-800">{{ action.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showMenu" [@fadeInOut] class="fixed inset-0 bg-black bg-opacity-50 z-8" (click)="toggleMenu($event)"></div>

<!-- <div #menuBar [class.active]="showMenu" (clickOutside)="closeMenu()" [style.bottom]="showMenu ? '2rem' : '-100%'" class="slide-up-menu z-9 fixed bottom-0 left-0 right-0 bg-white rounded-t-3xl py-4 pb-16 transform translate-y-0 transition-transform duration-300">
  <ul class="divide-y divide-gray-200">
    <li *ngFor="let action of navigationActions" (click)="onActionSelected(action)" class="flex items-center py-4 px-6 hover:bg-gray-200">
      <a class="flex items-center space-x-3">
        <i [ngClass]="action.icon" class="fas text-gray-600"></i>
        <span class="text-gray-800">{{ action.name }}</span>
      </a>
    </li>
  </ul>
</div> -->
