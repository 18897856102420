import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { RecipesListComponent } from '../recipes-list/recipes-list.component';
import { Store } from '@ngrx/store';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';
import { NavigationActionTypes } from '../../../navigation/_models/navigation.models';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';

@Component({
  selector: 'app-recipes-home',
  standalone: true,
  imports: [MainPageComponent, CardLgComponent, RecipesListComponent],
  templateUrl: './recipes-home.component.html',
  styleUrl: './recipes-home.component.scss'
})
export class RecipesHomeComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddRecipe]) }));
  }
}
