import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { RecipeActions } from './recipe.actions';
import { selectRecipesQuery } from './recipe.reducer';
import { RecipesService } from '../_services/recipes.service';
import { Store } from '@ngrx/store';


@Injectable()
export class RecipeEffects {

  loadRecipes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipeActions.loadRecipes),
      withLatestFrom(this.store.select(selectRecipesQuery)),
      concatMap(([, query]) =>
        from(this.service.getRecipes(query)).pipe(
          map(recipes => RecipeActions.loadRecipesSuccess({ recipes })),
          catchError(error => of(RecipeActions.apiFailure({ error })))
        )
      )
    );
  });

  addRecipe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipeActions.addRecipe),
      concatMap((action) =>
        from(this.service.upsertRecipe(action.recipe)).pipe(
          map(_null => RecipeActions.loadRecipes()),
          catchError(error => of(RecipeActions.apiFailure({ error })))
        )
      )
    );
  });

  getRecipeById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipeActions.getRecipeById),
      concatMap((action) =>
        from(this.service.getRecipeById(action.id)).pipe(
          map(recipe => RecipeActions.getOneSuccess({ recipe })),
          catchError(error => of(RecipeActions.apiFailure({ error })))
        )
      )
    )
  });

  updateRecipe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecipeActions.updateRecipe),
      concatMap((action) =>
        from(this.service.upsertRecipe(action.recipe)).pipe(
          map(_null => RecipeActions.purgeRecipe({ id: action.recipe.id as string })),
          catchError(error => of(RecipeActions.apiFailure({ error })))
        )
      )
    )
  });

  constructor(private actions$: Actions, private service: RecipesService, private store: Store) {}
}
