<div class="flex items-center space-x-2 mb-2">
  <button (click)="toggleCheck()" class="focus:outline-none">
    <div [ngClass]="item.isChecked ? 'bg-green-500' : 'bg-gray-200'"
         class="p-1 rounded-lg transition-all duration-700">
      <svg [ngClass]="item.isChecked ? 'text-white' : 'text-gray-400'"
           class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
    </div>
  </button>
  <span (click)="toggleCheck()" [ngClass]="item.isChecked ? 'line-through opacity-50' : 'text-white opacity-100'" class="text-lg text-white transition-all duration-700">{{ item.name }}</span>
</div>
@if(item.content) {
  <ul (click)="toggleCheck()" [ngClass]="item.isChecked ? 'line-through opacity-50' : 'text-white opacity-100'" class="text-md ml-14 mb-4 text-white transition-all duration-700 fa-ul">
    @for (line of item.content; track line) {
      <li class="mb-2"><i class="fa-li fa fa-play"></i>{{ line }}</li>
    }
  </ul>
}
