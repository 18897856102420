<div class="mb-3">
  <h2 *ngIf="props.label" class="text-xl font-semibold mb-4">{{ props.label }}</h2>
  <p *ngIf="props.description">{{ props.description }}</p>

  <div *ngFor="let field of field.fieldGroup; let i = index" class="rounded-lg bg-gray-200 p-3 mb-4">
    <div class="flex justify-end mb-2">
      <button class="w-6 h-6 bg-red-400 hover:bg-red-300 flex items-center justify-center rounded text-white text-sm" type="button" (click)="remove(i)"><i class="fa-solid fa-xmark"></i></button>
    </div>
    <formly-field class="" [field]="field"></formly-field>
  </div>
  <div>
    <app-submit-button [style]="buttonStyle.INFO" (click)="add();scrollToBottom();$event.preventDefault()">{{ props['addText'] }}</app-submit-button>
    <!-- <button class="btn btn-primary" type="button" >{{ props['addText'] }}</button> -->
  </div>
</div>
