import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { SaleActions } from './sale.actions';
import { SalesService } from '../_services/sales.service';


@Injectable()
export class SaleEffects {

  loadSales$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.loadSales),
      concatMap((action) =>
        from(this.service.getSales(action.query)).pipe(
          map(sales => SaleActions.loadSalesSuccess({ sales })),
          catchError(error => of(SaleActions.apiFailure({ error })))
        )
      )
    );
  });

  addSale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.addSale),
      concatMap((action) =>
        from(this.service.upsertSale(action.sale)).pipe(
          map(() => SaleActions.loadSales({})),
          catchError(error => of(SaleActions.apiFailure({ error })))
        )
      )
    );
  });

  getSaleById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.getSaleById),
      concatMap((action) =>
        from(this.service.getSaleById(action.id)).pipe(
          map(sale => SaleActions.getOneSuccess({ sale })),
          catchError(error => of(SaleActions.apiFailure({ error })))
        )
      )
    );
  });

  updateSale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.updateSale),
      concatMap((action) =>
        from(this.service.upsertSale(action.sale)).pipe(
          map(() => SaleActions.purgeSale({ id: action.sale.id as string })),
          catchError(error => of(SaleActions.apiFailure({ error })))
        )
      )
    );
  });

  getSaleDetailsById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.getSaleDetailsById),
      concatMap(action =>
        from(this.service.getSaleDetails(action.id)).pipe(
          map((details) => SaleActions.getSaleDetailsSuccess({ details })),
          catchError(error => of(SaleActions.apiFailure({ error })))
        ))
    );
  });

  getSaleDetailsByServiceId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaleActions.getSaleDetailsByServiceId),
      concatMap(action =>
        from(this.service.getSaleDetails(undefined, action.service_id)).pipe(
          map((details) => SaleActions.getSaleDetailsSuccess({ details })),
          catchError(error => of(SaleActions.apiFailure({ error })))
        ))
    );
  });

  constructor(private actions$: Actions, private service: SalesService) {}
}
