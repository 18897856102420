import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { ProductActions } from './product.actions';
import { ProductsService } from '../_services/products.service';
import { Store } from '@ngrx/store';
import { selectProductsQuery } from './product.reducer';


@Injectable()
export class ProductEffects {


  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadProducts),
      withLatestFrom(this.store.select(selectProductsQuery)),
      concatMap(([, query]) =>
        from(this.service.getProducts(query)).pipe(
          map(products => ProductActions.loadProductsSuccess({ products })),
          catchError(error => of(ProductActions.apiFailure({ error })))
        )
      )
    );
  });

  addProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.addProduct),
      concatMap((action) =>
        from(this.service.createProduct(action.product)).pipe(
          map(_null => ProductActions.loadProducts()),
          catchError(error => of(ProductActions.apiFailure({ error })))
        )
      )
    );
  });

  getProductById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.getProductById),
      concatMap((action) =>
        from(this.service.getProductById(action.id)).pipe(
          map(product => ProductActions.getOneSuccess({ product })),
          catchError(error => of(ProductActions.apiFailure({ error })))
        )
      )
    )
  });

  updateProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.updateProduct),
      concatMap((action) =>
        from(this.service.updateProduct(action.product)).pipe(
          map(_null => ProductActions.purgeProduct({ id: action.product.id as string })),
          catchError(error => of(ProductActions.apiFailure({ error })))
        )
      )
    )
  });


  constructor(private actions$: Actions, private service: ProductsService, private store: Store) {}
}
