<app-card-list-item [routerLink]="['/sales', sale.id]">
  <!-- <div class="flex-shrink-0"> -->
    <!-- <span class="block h-4 w-4 rounded-full bg-green-500 mr-2"></span> Green Dot for OK -->
    <!-- Replace bg-green-500 with bg-orange-500 for 'Pay Attention' and bg-red-500 for 'Needs Service' -->
  <!-- </div> -->
  <div class="flex-1 px-4">
    <div class="text-sm font-medium">{{ machineId ? 'Total sales: ' + sale.total_sold : machine.name }}</div>
    <div class="text-xs text-gray-600">{{ sale.sold_at | date:'short' }}</div>
  </div>
  <div class="flex-shrink-0 text-gray-500">
    <i class="fas fa-chevron-right"></i>
  </div>
</app-card-list-item>
