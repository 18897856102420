import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { QueryService } from '../../shared/_services/query.service';
import { Warehouse } from '../_models/warehouses.models';
import { State } from '../_store/warehouse.reducer';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class WarehousesService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getWarehouses(config: State['query']): Promise<Warehouse[]> {
    let query = this.supabase.from('warehouses').select('*');
    query = this.queryService.applyQueryConfig(query, config);
    query = query.filter('warehouse_type', 'eq', 'REGULAR');
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Warehouses');
      throw error;
    };
    return data;
  }

  async getWarehouseById(id: string): Promise<Warehouse> {
    const { data, error } = await this.supabase.from('warehouses').select('*').eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Warehouse by Id');
      throw error;
    };
    return data;
  }

  async createWarehouse(warehouse: Warehouse) {
    const { data, error } = await this.supabase.from('warehouses').insert(warehouse);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Create Warehouse');
      throw error;
    };
    return data;
  }

  async updateWarehouse(warehouse: Warehouse) {
    const { data, error } = await this.supabase.from('warehouses').update(warehouse).eq('id', warehouse.id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Update Warehouse');
      throw error;
    };
    return data;
  }
}
