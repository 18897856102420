import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { WarehousesListComponent } from '../warehouses-list/warehouses-list.component';
import { Store } from '@ngrx/store';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';
import { NavigationActionTypes } from '../../../navigation/_models/navigation.models';

@Component({
  selector: 'app-warehouses-home',
  standalone: true,
  imports: [MainPageComponent, CardLgComponent, WarehousesListComponent],
  templateUrl: './warehouses-home.component.html',
  styleUrl: './warehouses-home.component.scss'
})
export class WarehousesHomeComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddWarehouse]) }));
  }
}
