export interface ModalComponent {
  onConfirm?: () => void;
  onReject?: () => void;
}

export interface ModalData {
  title?: string;
  message?: string;
  confirm?: string;
  reject?: string;
}

export enum ModalComponentEnum {
  ERROR = 'Error',
}
