import { Component, OnDestroy, OnInit } from '@angular/core';
import { FullPageComponent } from '../../../shared/_components/full-page/full-page.component';
import { ServiceGuideService } from '../../_services/service-guide.service';
import { Service, ServiceChecklistItem, ServiceStatusEnum, ServiceStep, ServiceStepActions } from '../../_models/service.models';
import { CommonModule, Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { MachinesListSelectComponent } from '../../../machines/_components/machines-list-select/machines-list-select.component';
import { Machine } from '../../../machines/_models/machine.models';
import { ServiceChecklistComponent } from '../service-checklist/service-checklist.component';
import { SaleEditComponent } from '../../../sales/_components/sale-edit/sale-edit.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SaleActions } from '../../../sales/_store/sale.actions';
import { ServiceActions } from '../../_store/service.actions';
import { selectService, selectServiceCurrentId, selectServicesLoading } from '../../_store/service.reducer';
import { TransactionEditComponent } from '../../../transactions/_components/transaction-edit/transaction-edit.component';
import { ServiceIssuesComponent } from '../../../issues/_components/service-issues/service-issues.component';

@Component({
  selector: 'app-service-guide',
  standalone: true,
  imports: [CommonModule, FullPageComponent, MachinesListSelectComponent, ServiceChecklistComponent, SaleEditComponent, TransactionEditComponent, ServiceIssuesComponent],
  templateUrl: './service-guide.component.html',
  styleUrl: './service-guide.component.scss',
  animations: [
    trigger('slideUpDownAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: '100vh', transform: `translateY({{offsetEnter}}%)` }),
        animate('800ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ], { params: { offsetEnter: 200, offsetLeave: -200 }}),
      transition(':leave', [
        style({ opacity: 1, height: '100vh', transform: 'translateY(0%)' }),
        animate('500ms ease-in-out', style({ opacity: 0, transform: `translateY({{offsetLeave}}%)` }))
      ], { params: { offsetEnter: 200, offsetLeave: -200 }})
    ])
  ]
})
export class ServiceGuideComponent implements OnInit, OnDestroy {
  private currentId$: Observable<string | null> = this.store.select(selectServiceCurrentId);
  private service$: Observable<Service | undefined> | undefined;
  loading$: Observable<boolean> = this.store.select(selectServicesLoading);
  id: string | null = null;
  isNewSale: boolean = false;
  isNewTransaction: boolean = false;
  isNewIssues: boolean = false;
  serviceGuide: ServiceStep[] = [];
  currentStep: number = 1;
  animationStep:string = 'slideDown';
  machineId: Machine['id'] = undefined;
  serviceStart = new Date(Date.now()).toISOString();
  private touchStartY = 0;
  private touchEndY = -1;
  private destroy$ = new Subject<void>();

  constructor(private guideService: ServiceGuideService, private route: ActivatedRoute, private store: Store, private location: Location) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('serviceId');
    if (this.id) {
      this.store.dispatch(ServiceActions.getServiceById({ id: this.id }));
      this.service$ = this.store.select(selectService(this.id));
      this.service$.pipe(takeUntil(this.destroy$)).subscribe(service => {
        if (service) {
          this.machineId = service.machine_id;
          this.serviceStart = service.started_at;
          this.currentStep = 2;
          if(service.steps) {
            this.serviceGuide = JSON.parse(JSON.stringify(service.steps));
            this.isNewSale = service.steps[2].completed_at ? false : true;
            this.isNewTransaction = service.steps[5].completed_at ? false : true;
            this.isNewIssues = service.steps[12].completed_at ? false : true;
          }
        }
      });
    } else {
      this.serviceGuide = this.guideService.getServiceGuide();
      this.serviceGuide[this.currentStep - 1].started_at = new Date(Date.now()).toISOString();
      this.isNewSale = true;
      this.isNewTransaction = true;
      this.isNewIssues = true;
    }
    this.currentId$.pipe(takeUntil(this.destroy$)).subscribe(id => this.id = id);
  }

  goToPreviousStep() {
    this.animationStep = 'slideUp';
    setTimeout(() => {
      if(this.previousStep) {
        this.currentStep = this.previousStep as number;
      }
    }, 10);

  }

  goToNextStep() {
    if (
      !this.machineId ||
      (this.serviceGuide[this.currentStep - 1].action === ServiceStepActions.RECORD_SALES && this.isNewSale) ||
      (this.serviceGuide[this.currentStep - 1].action === ServiceStepActions.EDIT_INVENTORY && this.isNewTransaction)
    ) {
      return;
    }
    this.animationStep = 'slideDown';
    setTimeout(() => {
      if(this.nextStep) {
        // Store step completed date
        this.serviceGuide[this.currentStep - 1].completed_at = new Date(Date.now()).toISOString();
        this.currentStep = this.nextStep as number;
        if(!this.serviceGuide[this.currentStep - 1].started_at) {
          // Generate started at date if step not started yet
          this.serviceGuide[this.currentStep - 1].started_at = new Date(Date.now()).toISOString();
        }
      }
      // Reactivate after testing
      this.upsertService();
    }, 10);
  }

  upsertService(complete?: boolean) {
    let service: Service = {
      id: this.id ? this.id : undefined,
      machine_id: this.machineId,
      service_status: complete ? ServiceStatusEnum.COMPLETED : ServiceStatusEnum.IN_PROGRESS,
      started_at: this.serviceStart,
      completed_at: complete ? new Date(Date.now()).toISOString() : undefined
    }
    let serviceSteps: ServiceStep[] = this.serviceGuide.map(step => ({
      id: step.id,
      name: step.name,
      started_at: step.started_at,
      completed_at: step.completed_at,
      action: step.action ? step.action : undefined,
      checklist: step.checklist ? step.checklist : undefined,
      disableScroll: step.disableScroll ? step.disableScroll : undefined,
    }));
    service.steps = serviceSteps;
    this.store.dispatch(ServiceActions.upsertService({ service }));
  }

  get previousStep(): number | boolean {
    return (this.currentStep > 2 || (this.currentStep > 1 && !this.machineId)) ? this.currentStep - 1 : false;
  }

  get nextStep(): number | boolean {
    return this.currentStep < this.serviceGuide.length ? this.currentStep + 1 : false;
  }

  get provideOffsets() {
    let offsets = { offsetEnter: 200, offsetLeave: -200 };
    if(this.animationStep === 'slideUp') {
      offsets = { offsetEnter: -200, offsetLeave: 200 }
    }
    return offsets;
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartY = event.changedTouches[0].screenY;
    this.touchEndY = this.touchStartY;
  }

  onTouchMove(event: TouchEvent) {
    this.touchEndY = event.changedTouches[0].screenY;
  }

  onTouchEnd() {
    if(this.serviceGuide[this.currentStep - 1].disableScroll) return;
    if (this.touchStartY - this.touchEndY > 60) {
      this.goToNextStep();
    } else if (this.touchEndY - this.touchStartY > 60) {
      this.goToPreviousStep();
    }
  }

  onSaleSave(e: any) {
    this.isNewSale = false;
    this.goToNextStep();
  }

  onTransactionSave(e: any) {
    this.isNewTransaction = false;
    this.goToNextStep();
  }

  selectMachine(id: Machine['id']) {
    this.machineId = id;
    setTimeout(() => {
      this.goToNextStep();
    }, 600);
  }

  checklistChange(checklist: ServiceChecklistItem[]) {
    this.serviceGuide[this.currentStep - 1].checklist = checklist;
    if(this.allItemsChecked) {
      setTimeout(() => {
        this.goToNextStep();
      }, 600);
    }
  }

  completeService() {
    this.upsertService(true);
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      if(!loading) {
        this.location.back();
      }
    });
  }

  ngOnDestroy(): void {
    this.serviceGuide = [];
    this.destroy$.next();
    this.destroy$.complete();
  }

  get allItemsChecked () {
    return this.serviceGuide[this.currentStep - 1].checklist?.every(i => i.isChecked === true);
  }

  get serviceSteps() {
    return ServiceStepActions;
  }
}
