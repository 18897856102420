<div class="pt-safe-top">
  <div class="py-16 bg-slate-100 min-h-screen">
    <app-card-lg [title]="'Welcome Back.'">
      <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
        <div class="mb-5">
          <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
          <input type="text" id="email" name="email" formControlName="email" autocomplete="email" required class="bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg shadow-sm focus:ring-blue-500 block w-full p-2.5" placeholder="name@cafebec.ca" [class.border-red-500]="hasError('email', 'required') || hasError('email', 'email')">
          <div *ngIf="hasError('email', 'required')" class="text-red-500 text-xs">Email is required</div>
          <div *ngIf="hasError('email', 'email')" class="text-red-500 text-xs">Invalid email format</div>
        </div>
        <div class="mb-2">
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
          <input type="password" id="password" name="password" autocomplete="current-password" formControlName="password" required class="bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg shadow-sm focus:ring-blue-500 block w-full p-2.5">
          <div *ngIf="hasError('password', 'required')" class="text-red-500 text-xs">Password is required</div>
        </div>
        <div class="mb-4 text-xs font-medium underline underline-offset-4 text-gray-600 float-right"><a [routerLink]="''">Forgot password?</a></div>
        <button type="submit" class="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg shadow-sm text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
      </form>
    </app-card-lg>
  </div>
</div>
