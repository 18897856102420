import { Injectable } from '@angular/core';
import { ModalsService } from '../../modals/_services/modals.service';
import { ModalComponentEnum } from '../../modals/_models/modal.models';
import { PostgrestError, SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {

  private supabase: SupabaseClient;

  constructor(private modalsService: ModalsService, private sbService: SupabaseService) {
    this.supabase = this.sbService.supabase;
  }

  public handleApiError(error: PostgrestError, context: string = 'API') {
    console.error(error);
    this.modalsService.open(ModalComponentEnum.ERROR, { title: 'API Error', message: `Status: ${ error.code }, ${ error.message }. Context: ${ context }.` });
    this.logApiError(error, context);
    throw error;
  }

  async logApiError(err: PostgrestError, context: string = 'API') {
    const { data, error } = await this.supabase.from('api_errors').insert({ context, error: err });
    if (error) throw error;
    return data;
  }
}
