import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Auth, Login, Signup } from './auth.model';
import { User } from '@supabase/supabase-js';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Login': props<{ login: Login }>(),
    'Login Success': props<{ login: Login }>(),
    'Login Error': props<{ error: any }>(),
    'Logout': emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Error': props<{ error: any }>(),
    'Signup': props<{ signup: Signup }>(),
    'Signup Success': props<{ signup: Signup}>(),
    'Signup Error': props<{ error: any }>(),
    'Load User': emptyProps(),
    'Load User Success': props<{ user: User }>(),
    'Load User Failure': props<{ error: any }>(),
    // 'Load Auths': props<{ auths: Auth[] }>(),
    // 'Add Auth': props<{ auth: Auth }>(),
    // 'Upsert Auth': props<{ auth: Auth }>(),
    // 'Add Auths': props<{ auths: Auth[] }>(),
    // 'Upsert Auths': props<{ auths: Auth[] }>(),
    // 'Update Auth': props<{ auth: Update<Auth> }>(),
    // 'Update Auths': props<{ auths: Update<Auth>[] }>(),
    // 'Delete Auth': props<{ id: string }>(),
    // 'Delete Auths': props<{ ids: string[] }>(),
    // 'Clear Auths': emptyProps(),
  }
});
