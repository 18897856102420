import { Component } from '@angular/core';

@Component({
  selector: 'app-card-list-item',
  standalone: true,
  imports: [],
  templateUrl: './card-list-item.component.html',
  styleUrl: './card-list-item.component.scss'
})
export class CardListItemComponent {

}
