import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Machine, MachineRecipe, MachineRecipeSubmit } from '../_models/machine.models';

export const MachineActions = createActionGroup({
  source: 'Machine',
  events: {
    'Load Machines': emptyProps(),
    'Load Machines Success': props<{ machines: Machine[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Machine By Id': props<{ id: string }>(),
    'Get One Success': props<{ machine: Machine }>(),
    'Add Machine': props<{ machine: Machine }>(),
    'Update Machine': props<{ machine: Machine }>(),
    'Purge Machine': props<{ id: string }>(),
    'Get Machine Recipes': props<{ id: string }>(),
    'Get Machine Recipes Success': props<{ recipes: MachineRecipe[] }>(),
    'UpsertMachineRecipes': props< {recipes: MachineRecipeSubmit[] }>(),
    'Change Tab': props<{ tab: number }>(),

    'Upsert Machine': props<{ machine: Machine }>(),
    'Add Machines': props<{ machines: Machine[] }>(),
    'Upsert Machines': props<{ machines: Machine[] }>(),
    'Update Machines': props<{ machines: Update<Machine>[] }>(),
    'Delete Machine': props<{ id: string }>(),
    'Delete Machines': props<{ ids: string[] }>(),
    'Clear Machines': emptyProps(),
  }
});
