import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Issue, IssueEnum, IssueStatusEnum } from '../../_models/issues.model';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIssues } from '../../_store/issue.reducer';
import { IssueActions } from '../../_store/issue.actions';
import { Operator, Order, QueryStore } from '../../../shared/_models/query.models';

@Component({
  selector: 'app-service-issues',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './service-issues.component.html',
  styleUrl: './service-issues.component.scss'
})
export class ServiceIssuesComponent implements OnInit {
  @Input() serviceMachineId : string | undefined = undefined;
  @Input() serviceId: string | null | undefined = undefined;
  @Input() newService: boolean | undefined = undefined;
  @Output() onIssuesSave = new EventEmitter<any>();

  issues$: Observable<Issue[]> = this.store.select(selectIssues);

  serviceIssues: Issue[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.issues$.subscribe(issues => {
      this.serviceIssues = issues.filter(issue => issue.service_id === this.serviceId);
    });
    if(this.serviceId && !this.newService) {
      let query: QueryStore<Issue> = {
        filters: [{ column: 'service_id', value: this.serviceId, operator: Operator.Equal }],
        pagination: { page: 1, limit: 100 },
        order: { column: 'created_at', order: Order.DESCENDING },
      }
      this.store.dispatch(IssueActions.loadIssues({ query }));
    }
  }

  toggleIssue(issue: IssueEnum) {
    if (this.serviceIssues.some(i => i.service_issue === issue)) {
      this.removeIssue(issue);
    } else {
      this.addIssue(issue);
    }
  }

  addIssue(issue: IssueEnum) {
    let newIssue: Issue = {
      machine_id: this.serviceMachineId as string,
      service_id: this.serviceId as string,
      service_issue: issue,
      issue_status: IssueStatusEnum.OPEN
    }
    this.store.dispatch(IssueActions.addIssue({ issue: newIssue }));
  }

  removeIssue(issue: IssueEnum) {
    const i = this.serviceIssues.find(i => i.service_issue === issue);
    if(i) {
      this.store.dispatch(IssueActions.deleteIssue({ id: i.id as string }));
    }
  }

  toggleSelectedIssue(issueType: IssueEnum) {
    // If the issue in serviceIssues is open, resolve it and vice versa
    const index = this.serviceIssues.findIndex(i => i.service_issue === issueType);
    if(index === -1) return;
    if (this.serviceIssues[index].issue_status === IssueStatusEnum.OPEN) {
      this.resolveIssue(index);
    } else if (this.serviceIssues[index].issue_status === IssueStatusEnum.RESOLVED) {
      this.reopenIssue(index);
    }
  }

  resolveIssue(index: number) {
    this.store.dispatch(IssueActions.updateIssue({ issue: { ...this.serviceIssues[index], issue_status: IssueStatusEnum.RESOLVED } }));
  }

  reopenIssue(index: number) {
    this.store.dispatch(IssueActions.updateIssue({ issue: { ...this.serviceIssues[index], issue_status: IssueStatusEnum.OPEN } }));
  }

  isIssueSelected(issueType: IssueEnum) {
    return this.serviceIssues.some(i => i.service_issue === issueType);
  }

  isIssueResolved(issueType: IssueEnum) {
    return this.serviceIssues.some(i => i.service_issue === issueType && i.issue_status === IssueStatusEnum.RESOLVED);
  }

  get IssueEnum() {
    return IssueEnum;
  }
}
