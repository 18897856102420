import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { Observable, EMPTY, of, from } from 'rxjs';
import { WarehouseActions } from './warehouse.actions';
import { WarehousesService } from '../_services/warehouses.service';
import { Store } from '@ngrx/store';
import { selectWarehousesQuery } from './warehouse.reducer';


@Injectable()
export class WarehouseEffects {

  loadWarehouses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseActions.loadWarehouses),
      withLatestFrom(this.store.select(selectWarehousesQuery)),
      concatMap(([, query]) =>
        from(this.service.getWarehouses(query)).pipe(
          map(warehouses => WarehouseActions.loadWarehousesSuccess({ warehouses })),
          catchError(error => of(WarehouseActions.apiFailure({ error })))
        )
      )
    );
  });

  addWarehouse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseActions.addWarehouse),
      concatMap((action) =>
        from(this.service.createWarehouse(action.warehouse)).pipe(
          map(_null => WarehouseActions.loadWarehouses()),
          catchError(error => of(WarehouseActions.apiFailure({ error })))
        )
      )
    );
  });

  getWarehouseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseActions.getWarehouseById),
      concatMap((action) =>
        from(this.service.getWarehouseById(action.id)).pipe(
          map(warehouse => WarehouseActions.getOneSuccess({ warehouse })),
          catchError(error => of(WarehouseActions.apiFailure({ error })))
        )
      )
    )
  });

  updateWarehouse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseActions.updateWarehouse),
      concatMap((action) =>
        from(this.service.updateWarehouse(action.warehouse)).pipe(
          map(_null => WarehouseActions.purgeWarehouse({ id: action.warehouse.id as string })),
          catchError(error => of(WarehouseActions.apiFailure({ error })))
        )
      )
    )
  });


  constructor(private actions$: Actions, private service: WarehousesService, private store: Store) {}
}
