import { Injectable } from '@angular/core';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { SupabaseClient } from '@supabase/supabase-js';
import { Product } from '../_models/products.models';
import { State } from '../_store/product.reducer';
import { QueryService } from '../../shared/_services/query.service';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private supabase: SupabaseClient;
  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getProducts(config: State['query']): Promise<Product[]> {
    let query = this.supabase.from('products').select('*');
    query = this.queryService.applyQueryConfig(query, config);
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Products');
      throw error;
    };
    return data;
  }

  async getProductById(id: string): Promise<Product> {
    const { data, error } = await this.supabase.from('products').select('*').eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Product by Id');
      throw error;
    };
    return data;
  }

  async createProduct(product: Product) {
    const { data, error } = await this.supabase.from('products').insert(product);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Create Product');
      throw error;
    };
    return data;
  }

  async updateProduct(product: Product) {
    const { data, error } = await this.supabase.from('products').update(product).eq('id', product.id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Update Product');
      throw error;
    };
    return data;
  }

}
