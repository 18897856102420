import { Component, Input } from '@angular/core';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { Machine } from '../../_models/machine.models';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-machines-list-item',
  standalone: true,
  imports: [RouterModule, CardListItemComponent],
  templateUrl: './machines-list-item.component.html',
  styleUrl: './machines-list-item.component.scss'
})
export class MachinesListItemComponent {
 @Input() machine!: Machine;
}
