import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-header-back-button',
  standalone: true,
  imports: [],
  templateUrl: './header-back-button.component.html',
  styleUrl: './header-back-button.component.scss'
})
export class HeaderBackButtonComponent implements OnInit {
  hasParent: boolean = false;
  private history: string[] = [];
  private routeSegments: UrlSegment[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        // Initially, the route points to root, we need to traverse down to the current child
        let currentRoute = this.route;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }
        return currentRoute;
      })
    ).subscribe((currentRoute: ActivatedRoute) => {
      // Safely check if the current route has a parent
      this.hasParent = currentRoute.snapshot.url.length > 1;
      this.routeSegments = currentRoute.snapshot.url;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    })
  }

  navigateBack() {
    this.routeSegments.pop();
    this.location.back();
    // const routeMap = this.routeSegments.map(segment => segment.path);
    // this.router.navigate(routeMap);
  }

}
