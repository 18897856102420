<app-main-page>
  <app-card-lg [title]="'Welcome'">
  <p>
    Welcome to Cafebec's App! <br>
    Let me know what important information you want to see here.
  </p>
  <p>I can add some reports, quick insights that might need our attention like open issues, latest sales etc...</p>
  </app-card-lg>
</app-main-page>

