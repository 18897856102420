import { Component } from '@angular/core';
import { ModalBackdropComponent } from '../modal-backdrop/modal-backdrop.component';
import { CommonModule } from '@angular/common';
import { ModalsService } from '../../_services/modals.service';
import { Store } from '@ngrx/store';
import { selectModalData } from '../../_store/modal.reducer';
import { Observable } from 'rxjs';
import { ModalData } from '../../_models/modal.models';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';

@Component({
  selector: 'app-error-modal',
  standalone: true,
  imports: [CommonModule, ModalBackdropComponent, SubmitButtonComponent],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {

  data$: Observable<ModalData | null> = this.store.select(selectModalData);

  constructor(private modalService: ModalsService, private store: Store) { }

  close() {
    this.modalService.close();
  }
}
