<app-main-page>
  <div *appLoading="(loading$ | async) && !preventPageLoad"></div>
  @if (issue$ | async; as issue) {
    <app-card-lg [title]="issue.service_issue.replaceAll('_', ' ') | titlecase" [statusColor]="issueStatusColor">
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-server"></i>
        </div>
        <div>
          {{ issue.machine?.name }}
        </div>
      </div>
      <div class="flex text-gray-500 text-sm">
        <div class="w-6 text-center">
          <i class="fa-solid fa-calendar-day"></i>
        </div>
        <div>
          {{ issue.created_at | date:'short' }}
        </div>
      </div>
    </app-card-lg>
    <div class="flex items-center my-4">
      <app-submit-button class="flex-1 mr-4" [style]="ButtonStyle.PRIMARY" [routerLink]="['edit']">Edit</app-submit-button>
      <app-submit-button class="flex-1" [style]="ButtonStyle.DANGER" (click)="delete()">Delete</app-submit-button>
    </div>
    @if (issue.issue_status === IssueStatusEnum.OPEN) {
      <div class="flex items-center my-4">
        <app-submit-button class="flex-1" [style]="ButtonStyle.SUCCESS" (click)="markAsResolved()">
          <span *ngIf="loading$ | async; else showText">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
            <ng-template #showText>Mark as resolved</ng-template>
        </app-submit-button>
      </div>
    }
    @if (issue.issue_status !== IssueStatusEnum.OPEN) {
      <div class="flex items-center my-4">
        <app-submit-button class="flex-1" [style]="ButtonStyle.WARN" (click)="reopen()">
          <span *ngIf="loading$ | async; else showText">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
          <ng-template #showText>Reopen</ng-template>
        </app-submit-button>
      </div>
    }
    <div class="bg-white shadow-sm rounded-lg p-3 mb-4">
      <div class="text-sm font-medium">Details</div>
      <div class="text-gray-600">{{ issue.details }}</div>
    </div>
  }
</app-main-page>
