import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Machine } from '../../_models/machine.models';
import { selectMachine, selectMachineTab, selectMachinesLoading } from '../../_store/machine.reducer';
import { Observable, takeWhile } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MachineActions } from '../../_store/machine.actions';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { ButtonStyle } from '../../../shared/_models/styles.enums';
import { MachineRecipesComponent } from '../machine-recipes/machine-recipes.component';
import { SalesListComponent } from '../../../sales/_components/sales-list/sales-list.component';
import { ServicesListComponent } from '../../../services/_components/services-list/services-list.component';
import { IssuesListComponent } from '../../../issues/_components/issues-list/issues-list.component';
import { TabNavigationComponent } from '../../../shared/_components/tab-navigation/tab-navigation.component';
import { Tab } from '../../../shared/_models/tabs.models';
import { tabSlideAnimation } from '../../../shared/_services/animations';

@Component({
  selector: 'app-machine',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent, MachineRecipesComponent, SalesListComponent, ServicesListComponent, IssuesListComponent, TabNavigationComponent],
  templateUrl: './machine.component.html',
  styleUrl: './machine.component.scss',
  animations: [tabSlideAnimation]
})
export class MachineComponent implements OnInit {
  id: Machine['id'];
  machine$!: Observable<Machine | undefined>;
  machine!: Machine | undefined;
  loading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  tab$: Observable<number> = this.store.select(selectMachineTab);
  tab = 0;

  config: Tab[] = [
    { label: 'Sales', index: 0 },
    { label: 'Services', index: 1 },
    { label: 'Issues', index: 2 },
    { label: 'Product Map', index: 3 }
  ];

  constructor(private route: ActivatedRoute, private store: Store) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.machine$ = this.store.select(selectMachine(this.id));
    this.store.dispatch(MachineActions.getMachineById({ id: this.id as string }));
    this.machine$.pipe(takeWhile(m => m == undefined, true)).subscribe(machine => {
      if (machine) {
        this.machine = machine;
      }
    });
    this.tab$.subscribe(tab => this.tab = tab);
  }

  delete() {

  }

  activeIndexChange(index: number) {
    this.store.dispatch(MachineActions.changeTab({ tab: index }));
  }

  get ButtonStyle() {
    return ButtonStyle;
  }

}
