import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Machine, MachineRecipe } from '../_models/machine.models';
import { MachineActions } from './machine.actions';

export const machinesFeatureKey = 'machines';

export interface State extends EntityState<Machine> {
  // additional entities state properties
  recipes: MachineRecipe[];
  loading: boolean;
  recipesLoading: boolean;
  error: any;
  tab: number;
}

export const adapter: EntityAdapter<Machine> = createEntityAdapter<Machine>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  recipes: [],
  loading: false,
  recipesLoading: false,
  error: null,
  tab: 0
});

export const reducer = createReducer(
  initialState,
  on(MachineActions.loadMachines, (state, _action) => ({ ...state, loading: true })),
  on(MachineActions.loadMachinesSuccess,
    (state, action) => adapter.setAll(action.machines, {...state, loading: false })
  ),
  on(MachineActions.getMachineById, (state, _action) => ({ ...state, loading: true })),
  on(MachineActions.updateMachine, (state, _action) => ({ ...state, loading: true })),
  on(MachineActions.getOneSuccess,
    (state, action) => adapter.addOne(action.machine, { ...state, loading: false })
  ),
  on(MachineActions.apiSuccess, (state, _action) => ({ ...state, loading: false, recipesLoading: false })),
  on(MachineActions.apiFailure, (state, _action) => ({ ...state, loading: false, recipesLoading: false })),
  on(MachineActions.getMachineRecipes, (state, _action) => ({ ...state, recipesLoading: true })),
  on(MachineActions.getMachineRecipesSuccess, (state, action) => ({ ...state, recipesLoading: false, recipes: action.recipes })),
  on(MachineActions.upsertMachineRecipes, (state, action) => ({ ...state, recipesLoading: true })),
  on(MachineActions.upsertMachine,
    (state, action) => adapter.upsertOne(action.machine, state)
  ),
  on(MachineActions.addMachines,
    (state, action) => adapter.addMany(action.machines, state)
  ),
  on(MachineActions.upsertMachines,
    (state, action) => adapter.upsertMany(action.machines, state)
  ),
  on(MachineActions.updateMachines,
    (state, action) => adapter.updateMany(action.machines, state)
  ),
  on(MachineActions.deleteMachine,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(MachineActions.purgeMachine,
    (state, action) => adapter.removeOne(action.id, { ...state, loading: false })
  ),
  on(MachineActions.deleteMachines,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(MachineActions.clearMachines,
    state => adapter.removeAll(state)
  ),
  on(MachineActions.changeTab, (state, action) => ({ ...state, tab: action.tab }))
);

export const machinesFeature = createFeature({
  name: machinesFeatureKey,
  reducer,
  extraSelectors: ({ selectMachinesState }) => ({
    ...adapter.getSelectors(selectMachinesState)
  }),
});

export const {
  selectIds: selectMachineIds,
  selectEntities: selectMachineEntities,
  selectAll: selectMachines,
  selectTotal: selectMachinesTotal,
  selectLoading: selectMachinesLoading,
  selectRecipesLoading: selectMachineRecipesLoading,
  selectRecipes: selectMachineRecipes,
  selectTab: selectMachineTab
} = machinesFeature;

export const selectMachine = (id: string) => createSelector(
  selectMachineEntities,
  entities => entities[id]
);
