import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { MachinesListComponent } from '../machines-list/machines-list.component';
import { Store } from '@ngrx/store';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';
import { NavigationAction, NavigationActionTypes } from '../../../navigation/_models/navigation.models';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';

@Component({
  selector: 'app-machines-home',
  standalone: true,
  imports: [MainPageComponent, MachinesListComponent],
  templateUrl: './machines-home.component.html',
  styleUrl: './machines-home.component.scss'
})
export class MachinesHomeComponent implements OnInit {

  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddMachine, NavigationActionTypes.ServiceMachine, NavigationActionTypes.ReportIssue]) }));
  }
}
