import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { QueryService } from '../../shared/_services/query.service';
import { ProductInventory } from '../../products/_models/products.models';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private supabase: SupabaseClient;
  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) {
    this.supabase = this.sbService.supabase;
  }

  async getInventoryByWarehouse(warehouseId: string) {
    const { data, error } = await this.supabase
      .from('inventory')
      .select(
        `
        quantity_on_hand,
        par_level,
        max_quantity,
        product:products (
          id,
          name,
          package_size,
          unit
        )
        `
      )
      .eq('warehouse_id', warehouseId)
      .order('created_at', { ascending: false });
      if (error) {
        this.apiErrorService.handleApiError(error, 'Get Inventory by Warehouse Id');
        throw error;
      };
    return (data as any).map((i: any) => {
      return {
        name: i.product.name,
        unit: i.product.unit,
        package_size: i.product.package_size,
        quantity_on_hand: i.quantity_on_hand,
        par_level: i.par_level,
        max_quantity: i.max_quantity
      };
    }) as any as ProductInventory[];
  }

  async getTotalQuantityByProduct() {
    const { data, error } = await this.supabase.rpc('get_total_inventory_by_product');
    if (error) {
      if (error) {
        this.apiErrorService.handleApiError(error, 'Get Total Inventory by Product');
        throw error;
      };
    }
    return data;
  }
}
