import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceChecklistItemComponent } from '../service-checklist-item/service-checklist-item.component';
import { ServiceChecklistItem } from '../../_models/service.models';

@Component({
  selector: 'app-service-checklist',
  standalone: true,
  imports: [ServiceChecklistItemComponent],
  templateUrl: './service-checklist.component.html',
  styleUrl: './service-checklist.component.scss'
})
export class ServiceChecklistComponent {
  @Output() checklistChange = new EventEmitter<ServiceChecklistItem[]>();
  @Input() checklist!: ServiceChecklistItem[];

  checklistItemChange(checklistItem: ServiceChecklistItem) {
    let newChecklist = [ ...this.checklist ];
    newChecklist[checklistItem.id] = checklistItem;
    this.checklistChange.emit(newChecklist);
  }
}
