import { Component, Input, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Product, ProductInventory } from '../../../products/_models/products.models';
import { Store } from '@ngrx/store';
import { selectInventory, selectInventoryLoading } from '../../_store/inventory.reducer';
import { InventoryActions } from '../../_store/inventory.actions';
import { CommonModule } from '@angular/common';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { InventoryListItemComponent } from '../inventory-list-item/inventory-list-item.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';

@Component({
  selector: 'app-inventory-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, InventoryListItemComponent, AppLoadingDirective],
  templateUrl: './inventory-list.component.html',
  styleUrl: './inventory-list.component.scss'
})
export class InventoryListComponent implements OnInit {
  @Input() warehouseId: string | undefined = undefined;
  inventory$: Observable<ProductInventory[]> = this.store.select(selectInventory);
  loading$: Observable<boolean> = this.store.select(selectInventoryLoading);

  constructor(private store: Store) {}

  ngOnInit(): void {
    if(this.warehouseId) {
      this.store.dispatch(InventoryActions.loadInventoryByWarehouse({ warehouseId: this.warehouseId }));
    } else {
      this.store.dispatch(InventoryActions.loadInventory())
    }
  }

}
