import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceChecklistItem } from '../../_models/service.models';

@Component({
  selector: 'app-service-checklist-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './service-checklist-item.component.html',
  styleUrl: './service-checklist-item.component.scss'
})
export class ServiceChecklistItemComponent {
  @Output() itemChange = new EventEmitter<ServiceChecklistItem>();
  @Input() item!: ServiceChecklistItem;

  toggleCheck() {
    this.item = { ...this.item, isChecked: this.item.isChecked ? !this.item.isChecked : true};
    this.itemChange.emit({ ...this.item });
  }
}
