import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { Observable, EMPTY, of, from } from 'rxjs';
import { InventoryActions } from './inventory.actions';
import { InventoryService } from '../_services/inventory.service';


@Injectable()
export class InventoryEffects {

  loadInventory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryActions.loadInventory),
      concatMap(() =>
        from(this.service.getTotalQuantityByProduct()).pipe(
          map(inventory => InventoryActions.loadInventorySuccess({ inventory })),
          catchError(error => of(InventoryActions.apiFailure({ error })))
        )
      )
    );
  });

  loadInventoryByWarehouse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryActions.loadInventoryByWarehouse),
      concatMap((action) =>
        from(this.service.getInventoryByWarehouse(action.warehouseId)).pipe(
          map(inventory => InventoryActions.loadInventorySuccess({ inventory })),
          catchError(error => of(InventoryActions.apiFailure({ error })))
        )
      )
    );
  });


  constructor(private actions$: Actions, private service: InventoryService) {}
}
