import { Component, Input } from '@angular/core';
import { Recipe } from '../../_models/recipes.models';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';

@Component({
  selector: 'app-recipes-list-item',
  standalone: true,
  imports: [RouterModule, CardListItemComponent],
  templateUrl: './recipes-list-item.component.html',
  styleUrl: './recipes-list-item.component.scss'
})
export class RecipesListItemComponent {
  @Input() recipe!: Recipe;
}
