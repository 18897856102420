import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Recipe, RecipeForm, RecipeProducts, SizeEnum } from '../../_models/recipes.models';
import { Observable, Subject, map, take, takeUntil, takeWhile } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectRecipe, selectRecipesLoading } from '../../_store/recipe.reducer';
import { RecipeActions } from '../../_store/recipe.actions';
import { Product } from '../../../products/_models/products.models';
import { selectProducts } from '../../../products/_store/product.reducer';
import { ProductActions } from '../../../products/_store/product.actions';

@Component({
  selector: 'app-recipe-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './recipe-edit.component.html',
  styleUrl: './recipe-edit.component.scss'
})
export class RecipeEditComponent implements OnInit, OnDestroy {
  id: string | null = null;
  isNew: boolean = false;

  recipe$!: Observable<Recipe | undefined>;
  products$: Observable<Product[]> = this.store.select(selectProducts);
  products: Product[] = [];
  loading$: Observable<boolean> = this.store.select(selectRecipesLoading);
  private destroy$ = new Subject<void>();

  recipeForm = new FormGroup({});
  options: FormlyFormOptions = {};
  model: RecipeForm = {
    name: '',
    description: '',
    size: SizeEnum['8OZ'],
    recipe_products: []
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Recipe Name',
        placeholder: 'Enter recipe name',
        required: true
      }
    },
    {
      key: 'description',
      type: 'input',
      props: {
        label: 'Description',
        placeholder: 'Enter recipe description',
        required: true
      }
    },
    {
      key: 'size',
      type: 'select',
      props: {
        label: 'Size',
        required: true,
        options: [{ label: '8oz', value: SizeEnum['8OZ'] }, { label: '10oz', value: SizeEnum['10OZ'] }, { label: '12oz', value: SizeEnum['12OZ'] }],
      }
    },
    {
      key: 'recipe_products',
      type: 'repeat',
      props: {
        addText: 'Add Product',
        label: 'Products'
      },
      fieldArray: {
        fieldGroupClassName: 'flex flex-wrap',
        fieldGroup: [
          {
            key: 'product_id',
            type: 'select',
            className: 'basis-3/4 pr-4',
            props: {
              label: 'Product',
              options: this.products$,
              valueProp: 'id',
              labelProp: 'name',
              required: true,
            },
          },
          {
            key: 'product_quantity',
            type: 'input',
            className: 'basis-1/4',
            props: {
              type: 'number',
              label: 'Quantity',
              placeholder: 'g',
              required: true,
            },
          },
          {
            key: 'water_quantity',
            type: 'input',
            className: 'basis-full',
            props: {
              type: 'number',
              label: 'Water',
              placeholder: 'ml'
            },
            expressions: {
              hide: (field: FormlyFieldConfig) => this.hideWaterInput(field),
              'props.required': (field: FormlyFieldConfig) => !this.hideWaterInput(field)
            }
          }
        ],
      },
    }
  ];

  constructor(private route: ActivatedRoute, private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.isNew = urlSegments.some(segment => segment.path === 'new');
      this.id = this.isNew ? null : this.route.snapshot.paramMap.get('id');
      if (this.id) {
        this.recipe$ = this.store.select(selectRecipe(this.id));
        this.recipe$.pipe(takeWhile(r => r == undefined, true)).subscribe(recipe => {
          if (recipe) {
            setTimeout(() => {
              const recipeProducts = (recipe.recipe_products as RecipeProducts[]).map((rp) => ({ product_id: rp.product.id as string, product_quantity: rp.product_quantity, water_quantity: rp.water_quantity || undefined }));
              this.model = {
                ...recipe,
                recipe_products: recipeProducts
              };
              this.recipeForm.patchValue(this.model);
            });
          } else {
            this.store.dispatch(RecipeActions.getRecipeById({ id: this.id as string }));
          }
        })
      }
    });

    this.products$.pipe(take(2)).subscribe(products => {
      if (products.length < 2) {
        this.store.dispatch(ProductActions.loadProducts());
      }
      this.products = products;
    });
  }

  onSubmit(recipe: RecipeForm) {
    if (this.recipeForm.valid && this.isNew) {
      this.store.dispatch(RecipeActions.addRecipe({ recipe }));
    } else if (this.recipeForm.valid && this.id) {
      this.store.dispatch(RecipeActions.updateRecipe({ recipe: { ...recipe, id: this.id }}));
    }
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      if (!loading) {
        const url = this.id ? `/recipes/${this.id}` : '/recipes'
        this.router.navigate([url]);
      }
    });
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  hideWaterInput(field: FormlyFieldConfig): boolean {
    const productId: string = field.model?.product_id || null;
    if(productId) {
      const product = this.products.find(product => product.id === productId);
      if(product?.mix_with_water) { return false }
    }
    return true;
  }
}
