import { Component, Input, OnInit } from '@angular/core';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { MachinesListItemComponent } from '../machines-list-item/machines-list-item.component';
import { Machine } from '../../_models/machine.models';
import { Store } from '@ngrx/store';
import { MachineActions } from '../../_store/machine.actions';
import { Observable, take } from 'rxjs';
import { selectMachines, selectMachinesLoading } from '../../_store/machine.reducer';
import { CommonModule } from '@angular/common';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';

@Component({
  selector: 'app-machines-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, MachinesListItemComponent, AppLoadingDirective],
  templateUrl: './machines-list.component.html',
  styleUrl: './machines-list.component.scss'
})
export class MachinesListComponent implements OnInit {
  machines$: Observable<Machine[]> = this.store.select(selectMachines);
  loading$: Observable<boolean> = this.store.select(selectMachinesLoading);

  constructor(
    private store: Store
  ) {}

  ngOnInit(): void {
    this.machines$.pipe(take(1)).subscribe(machines => {
      if(machines.length < 2) {
        this.store.dispatch(MachineActions.loadMachines());
      }
    });
  }

}
