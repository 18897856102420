import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { Service, ServiceStatusEnum } from '../../_models/service.models';
import { Machine } from '../../../machines/_models/machine.models';

@Component({
  selector: 'app-services-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, CardListItemComponent],
  templateUrl: './services-list-item.component.html',
  styleUrl: './services-list-item.component.scss'
})
export class ServicesListItemComponent {
  @Input() service!: Service;
  @Input() machine!: Machine;
  @Input() machineId: string | undefined;

  get serviceStatusColor(): string {
    switch (this.service.service_status) {
      case ServiceStatusEnum.COMPLETED:
        return 'bg-green-500';
      case ServiceStatusEnum.NOT_COMPLETED:
        return 'bg-red-500';
      case ServiceStatusEnum.SCHEDULED:
        return 'bg-blue-500';
      case ServiceStatusEnum.IN_PROGRESS:
        return 'bg-yellow-500';
      default:
        return 'bg-red-500';
    }
  }
}
