import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Issue, IssueEnum, IssueStatusEnum } from '../../_models/issues.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { selectIssue, selectIssuesLoading } from '../../_store/issue.reducer';
import { IssueActions } from '../../_store/issue.actions';
import { Machine } from '../../../machines/_models/machine.models';
import { selectMachines, selectMachinesLoading } from '../../../machines/_store/machine.reducer';
import { MachineActions } from '../../../machines/_store/machine.actions';

@Component({
  selector: 'app-issue-edit',
  standalone: true,
  imports: [CommonModule, MainPageComponent, CardLgComponent, FormlyModule, ReactiveFormsModule, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './issue-edit.component.html',
  styleUrl: './issue-edit.component.scss'
})
export class IssueEditComponent implements OnInit, OnDestroy {
  id: string | null = null;
  isNew: boolean = true;
  issue$!: Observable<Issue | undefined>;
  machines$: Observable<Machine[]> = this.store.select(selectMachines);
  loading$: Observable<boolean> = this.store.select(selectIssuesLoading);
  machinesLoading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  private destroy$ = new Subject<void>();

  issueForm = new FormGroup({});
  options: FormlyFormOptions = {};
  model: Issue = {} as Issue;
  fields: FormlyFieldConfig[] = [
    {
      key: 'machine_id',
      type: 'select',
      props: {
        label: 'Machine',
        required: true,
        options: this.machines$,
        valueProp: 'id',
        labelProp: 'name',
      }
    },
    {
      key: 'service_issue',
      type: 'select',
      props: {
        label: 'Issue',
        required: true,
        options: Object.keys(IssueEnum).map(key => ({ label: key.replaceAll('_', ' '), value: key }))
      }
    },
    {
      key: 'details',
      type: 'textarea',
      props: {
        label: 'Details',
        placeholder: 'Ex: Milk mixer is clogged',
        required: true
      }
    },
    {
      key: 'issue_status',
      type: 'select',
      defaultValue: IssueStatusEnum.OPEN,
      props: {
        label: 'Status',
        required: true,
        options: Object.keys(IssueStatusEnum).map(key => ({ label: key.replaceAll('_', ' '), value: key }))
      }
    }
  ];

  constructor(private store: Store, private route: ActivatedRoute, private router: Router, private location: Location) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    if(this.id) {
      this.issue$ = this.store.select(selectIssue(this.id));
      this.store.dispatch(IssueActions.getIssueById({ id: this.id }));
      this.issue$.pipe(takeUntil(this.destroy$)).subscribe(issue => {
        if (issue) {
          this.model = { ...issue };
          this.issueForm.patchValue(this.model);
        }
      });
      this.isNew = false;
    }
    this.store.dispatch(MachineActions.loadMachines());
  }

  onSubmit(model: Issue) {
    if (this.issueForm.valid) {
      if(this.isNew) {
        this.store.dispatch(IssueActions.addIssue({ issue: model as Issue }));
      } else {
        this.store.dispatch(IssueActions.updateIssue({ issue: model as Issue }));
      }
      this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
        if (!loading) {
          setTimeout(() => {
            this.location.back();
          }, 200);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
