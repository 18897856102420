import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../_store/auth.actions';
import { Signup } from '../../_store/auth.model';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, CardLgComponent, ReactiveFormsModule],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent {

  signupForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  submitted = false;

  constructor(private store: Store) {}

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.valid) {
      this.store.dispatch(AuthActions.signup({ signup: this.signupForm.value as Signup }));
    }
  }

  hasError(controlName: keyof typeof this.signupForm.controls, errorName: string): boolean {
    return this.signupForm.controls[controlName].hasError(errorName) && (this.signupForm.controls[controlName].touched || this.submitted);
  }

}
