import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { ProductsListComponent } from '../products-list/products-list.component';
import { Store } from '@ngrx/store';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';
import { NavigationActionTypes } from '../../../navigation/_models/navigation.models';

@Component({
  selector: 'app-products-home',
  standalone: true,
  imports: [MainPageComponent, CardLgComponent, ProductsListComponent],
  templateUrl: './products-home.component.html',
  styleUrl: './products-home.component.scss'
})
export class ProductsHomeComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddProduct]) }));
  }
}
