import { Component, OnInit } from '@angular/core';
import { Machine, MachineRecipe } from '../../_models/machine.models';
import { CommonModule } from '@angular/common';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectMachineRecipes, selectMachineRecipesLoading } from '../../_store/machine.reducer';
import { MachineActions } from '../../_store/machine.actions';
import { ActivatedRoute } from '@angular/router';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';

@Component({
  selector: 'app-machine-recipes',
  standalone: true,
  imports: [CommonModule, CardLgComponent, AppLoadingDirective],
  templateUrl: './machine-recipes.component.html',
  styleUrl: './machine-recipes.component.scss'
})
export class MachineRecipesComponent implements OnInit {
  id: Machine['id'];
  recipes$: Observable<MachineRecipe[]> = this.store.select(selectMachineRecipes);
  recipesLoading$: Observable<boolean> = this.store.select(selectMachineRecipesLoading);
  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    if (this.id) {
      this.store.dispatch(MachineActions.getMachineRecipes({ id: this.id }));
    }
  }
}
