import { Component, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SalesListComponent } from '../sales-list/sales-list.component';
import { MenuActionsService } from '../../../navigation/_services/menu-actions.service';
import { Store } from '@ngrx/store';
import { NavigationActionTypes } from '../../../navigation/_models/navigation.models';
import { NavigationActions } from '../../../navigation/_store/navigation.actions';

@Component({
  selector: 'app-sales-home',
  standalone: true,
  imports: [MainPageComponent, SalesListComponent],
  templateUrl: './sales-home.component.html',
  styleUrl: './sales-home.component.scss'
})
export class SalesHomeComponent implements OnInit {
  constructor(private store: Store, private menuService: MenuActionsService) {
  }

  ngOnInit(): void {
    this.store.dispatch(NavigationActions.updateMenuNavigation({ menu: this.menuService.populateMenu([NavigationActionTypes.AddSale]) }));
  }
}
