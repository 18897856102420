export interface QueryFilter<T> {
  column: keyof T;
  value: string | boolean;
  operator: Operator;
}

export enum Operator {
  Equal = 'eq',
  NotEqual = 'neq',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  Like = 'like',
  ILike = 'ilike',
}

export interface OrderingParams<T> {
  column: keyof T;
  order: Order;
}

export enum Order {
  ASCENDING,
  DESCENDING
}

export interface PaginationParams {
  page: number;  // Current page number
  limit: number; // Number of items per page
}

export interface QueryStore<T> {
  filters: QueryFilter<T>[];
  pagination: PaginationParams;
  order: OrderingParams<T>;
}
