import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { IssuesListComponent } from '../issues-list/issues-list.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, concatMap, delay, map, of, takeUntil, takeWhile, timer } from 'rxjs';
import { selectIssue, selectIssuesLoading } from '../../_store/issue.reducer';
import { IssueActions } from '../../_store/issue.actions';
import { ButtonStyle } from '../../../shared/_models/styles.enums';
import { Issue, IssueStatusEnum } from '../../_models/issues.model';
import { IssuesService } from '../../_services/issues.service';

@Component({
  selector: 'app-issue',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './issue.component.html',
  styleUrl: './issue.component.scss'
})
export class IssueComponent implements OnInit, OnDestroy {
  id!: string;
  issue$!: Observable<Issue | undefined>;
  issue!: Issue | undefined;
  loading$: Observable<boolean> = this.store.select(selectIssuesLoading).pipe(
    concatMap(value =>
      value === true
        ? timer(200).pipe(map(() => true))
        : of(value)
    ));
  preventPageLoad: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store, private service: IssuesService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.issue$ = this.store.select(selectIssue(this.id));
    this.issue$.pipe(takeUntil(this.destroy$)).subscribe(issue => {
      if (issue) {
        this.issue = issue;
      } else {
        this.store.dispatch(IssueActions.getIssueById({ id: this.id as string }));
      }
    });
  }

  markAsResolved() {
    this.updateIssue({ ...this.issue, issue_status: IssueStatusEnum.RESOLVED } as Issue);
  }

  reopen() {
    this.updateIssue({ ...this.issue, issue_status: IssueStatusEnum.OPEN } as Issue);
  }

  updateIssue(issue: Issue) {
    this.preventPageLoad = true;
    this.store.dispatch(IssueActions.updateIssue({ issue: issue as Issue }));
  }

  delete() {}

  get ButtonStyle() {
    return ButtonStyle;
  }

  get IssueStatusEnum() {
    return IssueStatusEnum;
  }

  get issueStatusColor(): string {
    return this.service.getIssueStatusColor(this.issue as Issue);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
