import { Component, Input } from '@angular/core';
import { ProductInventory } from '../../../products/_models/products.models';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inventory-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, CardListItemComponent],
  templateUrl: './inventory-list-item.component.html',
  styleUrl: './inventory-list-item.component.scss'
})
export class InventoryListItemComponent {
  @Input() product!: ProductInventory;
}
