import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ModalComponentEnum, ModalData } from '../_models/modal.models';

export const ModalActions = createActionGroup({
  source: 'Modal',
  events: {
    'Show Modal': props<{ component: ModalComponentEnum; data: ModalData }>(),
    'Hide Modal': emptyProps(),
  }
});
