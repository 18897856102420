import { Product } from "../../products/_models/products.models";
import { Warehouse } from "../../warehouses/_models/warehouses.models";

export interface Transaction {
  id?: string;
  transaction_date: string;
  source_warehouse_id?: string;
  source_warehouse?: Warehouse;
  destination_warehouse_id?: string;
  destination_warehouse?: Warehouse;
  supplier_id?: string;
  customer_id?: string;
  service_id?: string;
  transaction_type: TransactionTypeEnum;
  created_at?: string;
  updated_at?: string;
  items?: TransactionItem[];
}

export interface TransactionForm extends Transaction {
  items?: TransactionItemForm[];
}

export interface TransactionItem {
  id?: number;
  transaction_id?: string;
  product_id: string;
  product?: Product;
  quantity: number;
  price?: number;
  created_at?: string;
  updated_at?: string;
}

export interface TransactionItemForm extends TransactionItem {
  quantity_type: string;
}

export enum TransactionTypeEnum {
  PURCHASE = 'PURCHASE',
  TRANSER = 'TRANSFER',
  SALE = 'SALE',
  ADJUSTMENT = 'ADJUSTMENT',
}
