import { Product } from "../../products/_models/products.models";

export interface Recipe {
  id?: string;
  name: string;
  description?: string;
  size: SizeEnum;
  recipe_products?: RecipeProducts[];
}

export enum SizeEnum {
  '8OZ' = '8OZ',
  '10OZ' = '10OZ',
  '12OZ' = '12OZ'
}

export interface RecipeProducts {
  id?: string;
  product_quantity: number;
  water_quantity?: number;
  product: Product;
  sequence_order: number;
}

export interface RecipeForm {
  id?: string;
  name: string;
  description?: string;
  size: SizeEnum;
  recipe_products:RecipeProductsForm[];
}

export interface RecipeProductsForm {
  product_quantity: number;
  water_quantity?: number;
  product_id: string;
  sequence_order?: number;
}
