import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Sale, SaleDetails } from '../_models/sales.models';
import { QueryStore } from '../../shared/_models/query.models';

export const SaleActions = createActionGroup({
  source: 'Sale',
  events: {
    'Load Sales': props<{ query?: QueryStore<Sale> }>(),
    'Load Sales Success': props<{ sales: Sale[] }>(),
    'Api Success': emptyProps(),
    'Api Failure': props<{ error: any }>(),
    'Get Sale By Id': props<{ id: string }>(),
    'Get One Success': props<{ sale: Sale }>(),
    'Add Sale': props<{ sale: Sale }>(),
    'Update Sale': props<{ sale: Sale }>(),
    'Purge Sale': props<{ id: string }>(),
    'Get Sale Details By Id': props<{ id: string}>(),
    'Get Sale Details By Service Id': props<{ service_id: string}>(),
    'Get Sale Details Success': props<{ details: SaleDetails }>(),
    'Upsert Sale': props<{ sale: Sale }>(),
    'Add Sales': props<{ sales: Sale[] }>(),
    'Upsert Sales': props<{ sales: Sale[] }>(),
    'Update Sales': props<{ sales: Update<Sale>[] }>(),
    'Delete Sale': props<{ id: string }>(),
    'Delete Sales': props<{ ids: string[] }>(),
    'Clear Sales': emptyProps(),
  }
});
