import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest, filter, map, take, takeUntil, takeWhile } from 'rxjs';
import { Sale, SaleDetails, SaleItem, SalesForm } from '../../_models/sales.models';
import { selectSaleDetails, selectSalesLoading } from '../../_store/sale.reducer';
import { selectMachineRecipes, selectMachineRecipesLoading, selectMachines, selectMachinesLoading } from '../../../machines/_store/machine.reducer';
import { Machine, MachineRecipe, SalesModeEnum } from '../../../machines/_models/machine.models';
import { SaleActions } from '../../_store/sale.actions';
import { MachineActions } from '../../../machines/_store/machine.actions';
import { SizeEnum } from '../../../recipes/_models/recipes.models';
import { DateService } from '../../../shared/_services/date.service';

@Component({
  selector: 'app-sale-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './sale-edit.component.html',
  styleUrl: './sale-edit.component.scss'
})
export class SaleEditComponent implements OnInit, OnDestroy {
  @Input() serviceMachineId : string | undefined = undefined;
  @Input() serviceId: string | null | undefined = undefined;
  @Input() newService: boolean | undefined = undefined;
  @Output() onSaleSave = new EventEmitter<any>();
  id: string | undefined = undefined;
  isNew: boolean = false;
  machineId: string | null = null;

  saleDetails$!: Observable<SaleDetails | null>;
  saleDetails!: SaleDetails | null;
  machines$: Observable<Machine[]> = this.store.select(selectMachines);
  machineRecipes$: Observable<MachineRecipe[]> = this.store.select(selectMachineRecipes);
  salesLoading$: Observable<boolean> = this.store.select(selectSalesLoading);
  machinesLoading$: Observable<boolean> = this.store.select(selectMachinesLoading);
  machineRecipesLoading$: Observable<boolean> = this.store.select(selectMachineRecipesLoading);
  private destroy$ = new Subject<void>();
  machineRecipes: MachineRecipe[] = [];
  machine!: Machine;

  saleForm = new FormGroup({});
  options: FormlyFormOptions = {};
  model: SalesForm = {
    sold_at: this.dateService.getLocalDate(),
    machine_id: '',
    sales_mode: SalesModeEnum.CREDIT_CARD,
    items: {}
  }
  fields: FormlyFieldConfig[] = [
    {
      key: 'sold_at',
      type: 'input',
      props: {
        type: 'datetime-local',
        label: 'Date of Sales Record',
        placeholder: 'Enter date',
        required: true,
      }
    },
    {
      key: 'machine_id',
      type: 'select',
      props: {
        label: 'Machine',
        options: this.machines$,
        valueProp: 'id',
        labelProp: 'name',
        required: true,
      },
      hooks: {
        onInit: (field) => {
          field.formControl?.valueChanges.subscribe(value => this.onMachineSelect(value));
        }
      }
    },
    {
      key: 'machine_counter',
      type: 'input',
      props: {
        type: 'number',
        label: 'Machine Sales Counter',
        required: true
      }
    },
    {
      key: 'total_sold',
      type: 'input',
      props: {
        type: 'number',
        label: 'Total Sold in Period',
        required: true
      }
    }
  ];

  constructor(private route: ActivatedRoute, private store: Store, private router: Router, private location: Location, private dateService: DateService) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.isNew = urlSegments.some(segment => segment.path === 'new');
      (this.fields[1].props as any)['disabled'] = !this.isNew;
      this.id = this.isNew ? undefined : this.route.snapshot.paramMap.get('id') as string;
      if (this.id) {
        this.loadExistingSaleDetails(this.id);
      }
    });
    if(this.serviceMachineId) {
      console.log(this.serviceId);
      this.fields[0].hide = true;
      this.fields[1].hide = true;
      if (this.serviceId && !this.newService) {
        this.loadExistingSaleDetails(undefined, this.serviceId);
      }
      this.onMachineSelect(this.serviceMachineId);
    }
    this.store.dispatch(MachineActions.loadMachines());
  }

  loadExistingSaleDetails(id?: string, serviceId?: string) {
    this.saleDetails$ = this.store.select(selectSaleDetails);
    this.saleDetails$.pipe(takeWhile(sd => sd == null, true)).subscribe(sd => {
      if (sd) {
        setTimeout(() => {
          let items: { [key: string]: number }  = {};
          sd.items?.forEach(item => items[item.recipe_id] = item.quantity)
          this.model = {
            sold_at: this.dateService.getLocalDate(sd.sold_at),
            machine_id: sd.machine_id,
            machine_counter: sd.machine_counter,
            total_sold: sd.total_sold,
            sales_mode: sd.sales_mode,
            items: items
          };
          this.onMachineSelect(sd.machine_id);

          this.saleForm.patchValue(this.model);
        });
      } else {
        if (id) {
          this.store.dispatch(SaleActions.getSaleDetailsById({ id }));
        } else if (serviceId) {
          this.store.dispatch(SaleActions.getSaleDetailsByServiceId({ service_id: serviceId }));
        }
      }
    });
  }

  onMachineSelect(machineId: string) {
    if(this.isNew || this.newService) {
      this.store.dispatch(MachineActions.getMachineRecipes({ id: machineId }));
      combineLatest([this.machineRecipes$, this.machineRecipesLoading$]).pipe(
        takeWhile(([_recipes, loading]) => loading !== false, true),
        filter(([_recipes, loading]) => !loading),
        map(([recipes, _loading]) => recipes)
      ).subscribe(recipes => {
        this.fields = this.fields.slice(0, 4);
        const fg: FormlyFieldConfig[] = [];
        recipes.forEach((recipe) => {
          fg.push({
            key: recipe.recipe.id,
            type: 'input',
            className: '',
            props: {
              type: 'number',
              label: `${recipe.machine_reference} | ${recipe.recipe.name}`,
              required: true
            }
          });
        });
        this.fields = [
          ...this.fields,
          {
            key: 'items',
            fieldGroupClassName: 'grid grid-cols-2 gap-4',
            fieldGroup: fg,
          }
        ];
        this.machineRecipes = recipes;
      });
      this.machines$.pipe(takeUntil(this.destroy$)).subscribe(machines => {
        let machine = machines.find(machine => machine.id === machineId);
        if (machine) { this.machine = machine }
      });
    } else {
      this.fields = this.fields.slice(0, 4);
      const fg: FormlyFieldConfig[] = [];
      this.saleDetails$.pipe(takeUntil(this.destroy$)).subscribe(sd => {
        if(sd?.items) {
          sd.items.forEach((item) => {
            fg.push({
              key: item.recipe_id,
              type: 'input',
              className: '',
              props: {
                type: 'number',
                label: `${item.machine_reference ? item.machine_reference + ' | ' : ''}${item.recipe?.name}`,
                required: true
              }
            });
          });
        }
        this.saleDetails = sd;
        this.id = sd?.id;
      });
      this.fields = [
        ...this.fields,
        {
          key: 'items',
          fieldGroupClassName: 'grid grid-cols-2 gap-4',
          fieldGroup: fg,
        }
      ];
    }
  }

  onSubmit(saleForm: SalesForm) {
    if (this.saleForm.valid) {
      const keys = Object.keys(saleForm.items);
      const sale: Partial<Sale> = {
        id: this.id,
        sold_at: this.dateService.getUTCDate(saleForm.sold_at),
        machine_id: saleForm.machine_id || this.serviceMachineId,
        service_id: this.serviceId ? this.serviceId : undefined,
        total_sold: saleForm.total_sold as number,
        machine_counter: saleForm.machine_counter as number,
      };
      if (this.isNew || this.newService) {
        sale.customer_id = this.machine.customer_id as string;
        sale.sales_mode = this.machine.sales_mode as SalesModeEnum;
        sale.items = keys.map((key) => {
          const recipe = this.machineRecipes.find(recipe => recipe.recipe.id === key) as MachineRecipe
          return {
            sale_id: this.id,
            recipe_id: recipe.recipe.id as string,
            quantity: saleForm.items[key],
            size: this.machine.size as SizeEnum,
            unit_price: recipe.price,
            machine_reference: recipe.machine_reference
          }
        });
      } else {
        sale.customer_id = (this.saleDetails as SaleDetails).customer_id;
        sale.sales_mode = (this.saleDetails as SaleDetails).sales_mode;
        sale.items = keys.map((key) => {
          const itemToUpdate = (this.saleDetails as SaleDetails).items?.find((item) => item.recipe_id === key) as SaleItem;
          return {
            sale_id: this.id,
            recipe_id: key,
            quantity: saleForm.items[key],
            size: itemToUpdate.size,
            unit_price: itemToUpdate.unit_price,
            machine_reference: itemToUpdate.machine_reference
          }
        });
      }
      this.store.dispatch(SaleActions.updateSale({ sale: sale as Sale }));
      this.salesLoading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
        if (!loading) {
          if(this.serviceId) {
            this.onSaleSave.emit();
          } else {
            this.location.back();
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
