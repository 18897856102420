<app-main-page>
  <app-card-lg [title]="'Recipes'" [routerLink]="'/recipes'">

  </app-card-lg>
  <app-card-lg [title]="'Sales'" [routerLink]="'/sales'">

  </app-card-lg>
  <app-card-lg [title]="'Services'" [routerLink]="'/services'">

  </app-card-lg>
  <app-card-lg [title]="'Issues'" [routerLink]="'/issues'"></app-card-lg>
</app-main-page>
