import { createFeature, createReducer, on } from '@ngrx/store';
import { NavigationActions } from './navigation.actions';
import { NavigationAction } from '../_models/navigation.models';

export const navigationFeatureKey = 'navigation';

export interface State {
  menu: NavigationAction[] | null;
}

export const initialState: State = {
  menu: null,
};

export const reducer = createReducer(
  initialState,
  on(NavigationActions.updateMenuNavigation, (state, action) => ({ ...state, menu: action.menu }))
);

export const navigationFeature = createFeature({
  name: navigationFeatureKey,
  reducer,
});

export const {
  selectMenu,
} = navigationFeature;

