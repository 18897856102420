import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps, FormlyModule } from '@ngx-formly/core';

interface InputProps extends FormlyFieldProps {}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<FormlyInputComponent>;
}

@Component({
  selector: 'app-formly-input',
  standalone: true,
  imports: [FormlyModule, ReactiveFormsModule, CommonModule],
  templateUrl: './formly-input.component.html',
  styleUrl: './formly-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyInputComponent extends FieldType<FieldTypeConfig<InputProps>> {
  get type() {
    return this.props.type || 'text';
  }
}
