import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Service } from '../_models/service.models';
import { QueryStore } from '../../shared/_models/query.models';

export const ServiceActions = createActionGroup({
  source: 'Service',
  events: {
    'Load Services': props<{ query?: QueryStore<Service> }>(),
    'Load Services Success': props<{ services: Service[] }>(),
    'Api Success': props<{ id?: string }>(),
    'Api Failure': props<{ error: any }>(),
    'Get Service By Id': props<{ id: string }>(),
    'Get One Success': props<{ service: Service }>(),
    'Upsert Service': props<{ service: Service }>(),
    'Update Service': props<{ service: Service }>(),
    'Purge Service': props<{ id: string }>(),
  }
});
