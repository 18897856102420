import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { Recipe } from '../../_models/recipes.models';
import { Observable, takeWhile } from 'rxjs';
import { selectRecipe, selectRecipesLoading } from '../../_store/recipe.reducer';
import { RecipeActions } from '../../_store/recipe.actions';
import { ButtonStyle } from '../../../shared/_models/styles.enums';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-recipe',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent],
  templateUrl: './recipe.component.html',
  styleUrl: './recipe.component.scss'
})
export class RecipeComponent implements OnInit {
  id: Recipe['id'];
  recipe$!: Observable<Recipe | undefined>;
  recipe!: Recipe | undefined;
  loading$: Observable<boolean> = this.store.select(selectRecipesLoading);

  constructor(private route: ActivatedRoute, private store: Store) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.recipe$ = this.store.select(selectRecipe(this.id));
    this.recipe$.pipe(takeWhile(p => p == undefined, true)).subscribe(recipe => {
      if (recipe) {
        this.recipe = recipe;
      } else {
        this.store.dispatch(RecipeActions.getRecipeById({ id: this.id as string }));
      }
    });
  }

  delete() {

  }

  get ButtonStyle() {
    return ButtonStyle;
  }
}
