import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CommonModule, Location } from '@angular/common';
import { Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import { Product, ProductUnitEnum } from '../../_models/products.models';
import { selectProduct, selectProductsLoading } from '../../_store/product.reducer';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProductActions } from '../../_store/product.actions';

@Component({
  selector: 'app-product-edit',
  standalone: true,
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, MainPageComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './product-edit.component.html',
  styleUrl: './product-edit.component.scss'
})
export class ProductEditComponent implements OnInit, OnDestroy {
  id: string | null = null;
  isNew: boolean = false;

  product$!: Observable<Product | undefined>;
  loading$: Observable<boolean> = this.store.select(selectProductsLoading);
  private destroy$ = new Subject<void>();

  productForm = new FormGroup({});
  model: Product = {
    name: '',
    description: '',
    unit: ProductUnitEnum.GRAM,
    package_size: 0,
    mix_with_water: false,
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Product Name',
        placeholder: 'Enter product name',
        required: true
      }
    },
    {
      key: 'description',
      type: 'input',
      props: {
        label: 'Description',
        placeholder: 'Enter description',
        required: true
      }
    },
    {
      key: 'unit',
      type: 'select',
      props: {
        label: 'Unit',
        placeholder: 'Select unit',
        options: Object.keys(ProductUnitEnum).map(key => ({ label: key.replaceAll('_', ' '), value: (ProductUnitEnum as any)[key] })),
        required: true
      }
    },
    {
      key: 'package_size',
      type: 'input',
      props: {
        type: 'number',
        label: 'Package size',
        placeholder: 'Enter location',
        required: true
      }
    },
    {
      key: 'mix_with_water',
      type: 'boolean',
      props: {
        label: 'Mix with water',
        required: true
      }
    }
  ]


  constructor(private route: ActivatedRoute, private store: Store, private location: Location) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.destroy$)).subscribe(urlSegments => {
      this.isNew = urlSegments.some(segment => segment.path === 'new');
      this.id = this.isNew ? null : this.route.snapshot.paramMap.get('id');
      if (this.id) {
        this.product$ = this.store.select(selectProduct(this.id));
        this.product$.pipe(takeWhile(p => p == undefined, true)).subscribe(product => {
          if (product) {
            setTimeout(() => {
              this.productForm.patchValue({...product});
            });
          } else {
            this.store.dispatch(ProductActions.getProductById({ id: this.id as string }));
          }
        });
      }
    });
  }

  onSubmit(product: Product) {
    if (this.productForm.valid && this.isNew) {
      this.store.dispatch(ProductActions.addProduct({ product }));
    } else if (this.productForm.valid && this.id) {
      this.store.dispatch(ProductActions.updateProduct({ product: { ...product, id: this.id }}));
    }
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      if (!loading) {
        this.location.back();
      }
    })
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
