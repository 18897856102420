import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MainPageComponent } from '../../../shared/_components/main-page/main-page.component';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { SubmitButtonComponent } from '../../../shared/_components/buttons/submit-button/submit-button.component';
import { Observable, take, takeWhile } from 'rxjs';
import { Sale, SaleDetails, SaleItem } from '../../_models/sales.models';
import { Store } from '@ngrx/store';
import { selectSaleDetails, selectSaleDetailsLoading } from '../../_store/sale.reducer';
import { SaleActions } from '../../_store/sale.actions';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { SalesService } from '../../_services/sales.service';
import { ButtonStyle } from '../../../shared/_models/styles.enums';

@Component({
  selector: 'app-sale',
  standalone: true,
  imports: [CommonModule, RouterModule, MainPageComponent, CardLgComponent, SubmitButtonComponent, AppLoadingDirective],
  templateUrl: './sale.component.html',
  styleUrl: './sale.component.scss'
})
export class SaleComponent implements OnInit {
  id!: string;
  saleDetails$: Observable<SaleDetails | null> = this.store.select(selectSaleDetails);
  saleDetails!: SaleDetails | null;
  loading$: Observable<boolean> = this.store.select(selectSaleDetailsLoading);

  constructor(private route: ActivatedRoute, private store: Store){}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.store.dispatch(SaleActions.getSaleDetailsById({ id: this.id as string }));
    this.saleDetails$.pipe(take(2)).subscribe(sd => this.saleDetails = sd);
  }

  getTotalRevenue(items: SaleItem[] | undefined): number {
    let revenue = 0;
    if(items) {
      items.forEach(item => {
        revenue += item.quantity * item.unit_price;
      });
    }
    return revenue / 100;
  }

  delete() {}

  get ButtonStyle() {
    return ButtonStyle;
  }
}
