import { Injectable } from '@angular/core';
import { Operator, Order, OrderingParams, PaginationParams, QueryFilter, QueryStore } from '../_models/query.models';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor() { }

  applyQueryConfig<T>(query: any, config: QueryStore<T>) {
    query = this.applyPagination(query, config.pagination);
    query = this.applyOrdering(query, config.order);
    query = this.applyFilters(query, config.filters);
    return query;
  }

  applyPagination<T>(query: any, params: PaginationParams): any {
    const { page, limit } = params;
    const offset = (page - 1) * limit;
    return query.range(offset, offset + limit - 1);
  }

  applyOrdering<T>(query: any, params: OrderingParams<T>): any {
    return query.order(params.column, { ascending: params.order === Order.ASCENDING });
  }

  applyFilters<T>(query: any, filters: QueryFilter<T>[]) {
    filters.forEach(filter => {
      switch (filter.operator) {
        case Operator.Equal:
            query = query.eq(filter.column, filter.value);
            break;
        case Operator.NotEqual:
          query = query.neq(filter.column, filter.value);
          break;
        case Operator.LessThan:
          query = query.lt(filter.column, filter.value);
          break;
        case Operator.LessThanOrEqual:
          query = query.lte(filter.column, filter.value);
          break;
        case Operator.GreaterThan:
          query = query.gt(filter.column, filter.value);
          break;
        case Operator.GreaterThanOrEqual:
          query = query.gte(filter.column, filter.value);
          break;
        case Operator.Like:
          query = query.like(filter.column, `%${filter.value}%`);
          break;
        case Operator.ILike:
          query = query.ilike(filter.column, `%${filter.value}%`);
          break;
        default:
          break;
      }
    });

    return query;
  }
}
