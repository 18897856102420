import { createFeature, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Tenant } from './tenant.model';
import { TenantActions } from './tenant.actions';

export const tenantsFeatureKey = 'tenants';

export interface State extends EntityState<Tenant> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Tenant> = createEntityAdapter<Tenant>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(TenantActions.addTenant,
    (state, action) => adapter.addOne(action.tenant, state)
  ),
  on(TenantActions.upsertTenant,
    (state, action) => adapter.upsertOne(action.tenant, state)
  ),
  on(TenantActions.addTenants,
    (state, action) => adapter.addMany(action.tenants, state)
  ),
  on(TenantActions.upsertTenants,
    (state, action) => adapter.upsertMany(action.tenants, state)
  ),
  on(TenantActions.updateTenant,
    (state, action) => adapter.updateOne(action.tenant, state)
  ),
  on(TenantActions.updateTenants,
    (state, action) => adapter.updateMany(action.tenants, state)
  ),
  on(TenantActions.deleteTenant,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(TenantActions.deleteTenants,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(TenantActions.loadTenantsSuccess,
    (state, action) => adapter.setAll(action.tenants, state)
  ),
  on(TenantActions.clearTenants,
    state => adapter.removeAll(state)
  ),
);

export const tenantsFeature = createFeature({
  name: tenantsFeatureKey,
  reducer,
  extraSelectors: ({ selectTenantsState }) => ({
    ...adapter.getSelectors(selectTenantsState)
  }),
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = tenantsFeature;
