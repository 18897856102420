<textarea
  class="shadow appearance-none border text-sm rounded w-full py-2 px-3 text-gray-500 placeholder:text-gray-400 leading-tight focus:outline-none focus:shadow-md focus:ring-1"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [cols]="props.cols"
  [rows]="props.rows"
  [ngClass]="{'border-red-400': showError, 'border-gray-300': !showError}"
  [attr.aria-describedby]="id + '-formly-validation-error'"
  [attr.aria-invalid]="showError"
>
</textarea>
