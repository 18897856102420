<div class="fixed flex z-10 inset-x-0 top-0 left-0 right-0 bg-blue-900 text-white shadow-sm py-2 pt-safe-top">
  <app-header-back-button class="flex"></app-header-back-button>

  <div class="flex items-center flex-grow justify-center p-2">
    <span class="font-semibold">CAFÉBEC</span>
  </div>

  <button [class.invisible]="!users$" (click)="logout()" class="flex px-4 items-center justify-center">
    <i class="fas fa-user"></i>
  </button>
</div>
