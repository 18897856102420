<div>
  <app-header />
</div>
<div
  [@routeAnimations]="prepareRoute(outlet)"
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd()"
  class=""
>
	<router-outlet #outlet="outlet"></router-outlet>
</div>
<app-menubar *ngIf="showMenu" />
<ng-container *ngIf="modalComponent$ | async as component">
  <ng-template #modalContainer></ng-template>
</ng-container>
