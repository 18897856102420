import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Warehouse } from '../../_models/warehouses.models';
import { selectWarehouses, selectWarehousesLoading } from '../../_store/warehouse.reducer';
import { WarehouseActions } from '../../_store/warehouse.actions';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { CardLgComponent } from '../../../shared/_components/card-lg/card-lg.component';
import { AppLoadingDirective } from '../../../shared/_directives/app-loading.directive';
import { WarehousesListItemComponent } from '../warehouses-list-item/warehouses-list-item.component';


@Component({
  selector: 'app-warehouses-list',
  standalone: true,
  imports: [CommonModule, CardLgComponent, WarehousesListItemComponent, AppLoadingDirective],
  templateUrl: './warehouses-list.component.html',
  styleUrl: './warehouses-list.component.scss'
})
export class WarehousesListComponent implements OnInit {
  warehouses$: Observable<Warehouse[]> = this.store.select(selectWarehouses);
  loading$: Observable<boolean> = this.store.select(selectWarehousesLoading);

  constructor(
    private store: Store
  ) {}

  ngOnInit(): void {
    this.warehouses$.pipe(take(1)).subscribe(warehouses => {
      if(warehouses.length < 2) {
        this.store.dispatch(WarehouseActions.loadWarehouses())
      }
    });
  }
}
