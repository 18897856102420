import { trigger, animate, transition, style, query, group } from '@angular/animations';

export const resetRoute = [
  style({ position: 'relative' }),
  query(
      ':enter, :leave',
    [
        style({
              position: 'fixed', // using absolute makes the scroll get stuck in the previous page's scroll position on the new page
              top: '0px',
              marginTop: '0px',
              paddingTop: '0px',
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 1,
              zIndex: 0,
          }),
    ],
    { optional: true }
  ),
];

export const slideInAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    ...resetRoute,
    group([
      query(':leave', [
        animate('300ms ease', style({ transform: 'translateX({{ offsetXLeave }}%)' })),
      ], { optional: true }),
      query(':enter', [
        style({ transform: 'translateX({{ offsetXEnter }}%)' }),
        animate('300ms ease', style({ transform: 'translateX(0%)' })),
      ], { optional: true })
    ])
  ], { params: { offsetXEnter: '100', offsetXLeave: '-100' } })
]);

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('* <=> *', [
//       ...resetRoute,
//       group([
//         query(':leave', [
//           animate('300ms ease-out', style({ transform: 'translateX(-100%)' })),
//         ], { optional: true }),
//         query(':enter', [
//           style({ transform: 'translateX(100%)' }),
//           animate('300ms ease-out', style({ transform: 'translateX(0%)' })),
//         ], { optional: true }),
//       ]),
//     ]),
//   ]);

export const tabSlideAnimation = trigger('tabSlideAnimation', [
  transition(':increment', group([
    query(':enter', [
      style({ transform: 'translateX(150%)', position: 'fixed', width: '100%' }),
      animate('0.3s ease-in-out', style('*'))
    ], { optional: true }),
    query(':leave', [
      style({ position: 'fixed', width: 'calc(100% - 32px)' }),
      animate('0.3s ease-in-out', style({ transform: 'translateX(-100%)' }))
    ], { optional: true })
  ])),
  transition(':decrement', group([
    query(':enter', [
      style({ transform: 'translateX(-150%)', position: 'fixed', width: '100%' }),
      animate('0.3s ease-in-out', style('*'))
    ], { optional: true }),
    query(':leave', [
      style({ position: 'fixed', width: 'calc(100% - 32px)' }),
      animate('0.3s ease-in-out', style({ transform: 'translateX(100%)' }))
    ], { optional: true })
  ]))
]);
