import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Issue } from '../_models/issues.model';
import { IssueActions } from './issue.actions';
import { Order, QueryStore } from '../../shared/_models/query.models';

export const issuesFeatureKey = 'issues';

export interface State extends EntityState<Issue> {
  // additional entities state properties
  loading: boolean;
  error: any;
  query: QueryStore<Issue>;
}

export const adapter: EntityAdapter<Issue> = createEntityAdapter<Issue>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  query: {
    filters: [],
    pagination: {
      page: 1,
      limit: 5,
    },
    order: {
      column: 'created_at',
      order: Order.DESCENDING
    }
  }
});

export const reducer = createReducer(
  initialState,
  on(IssueActions.loadIssues,
    (state, action) => ({ ...state, loading: true, query: action.query ? action.query : state.query })
  ),
  on(IssueActions.loadIssuesSuccess,
    (state, action) => adapter.setAll(action.issues, { ...state, loading: false })
  ),
  on(IssueActions.getIssueById, (state) => ({ ...state, loading: true })),
  on(IssueActions.getOneSuccess, (state, action) => adapter.upsertOne(action.issue, { ...state, loading: false })),
  on(IssueActions.apiSuccess, (state) => ({ ...state, loading: false })),
  on(IssueActions.apiFailure, (state, action) => ({ ...state, loading: false, error: action.error })),
  on(IssueActions.addIssue, (state) => ({ ...state, loading: true })),
  on(IssueActions.updateIssue, (state) => ({ ...state, loading: true })),
  on(IssueActions.upsertIssue, (state) => ({ ...state, loading: true })),
  on(IssueActions.purgeIssue, (state, action) => adapter.removeOne(action.id, { ...state, loading: false })),
  on(IssueActions.deleteIssue, (state) => ({ ...state, loading: true })),

  on(IssueActions.addIssues,
    (state, action) => adapter.addMany(action.issues, state)
  ),
  on(IssueActions.upsertIssues,
    (state, action) => adapter.upsertMany(action.issues, state)
  ),
  on(IssueActions.updateIssues,
    (state, action) => adapter.updateMany(action.issues, state)
  ),
  on(IssueActions.deleteIssues,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(IssueActions.clearIssues,
    state => adapter.removeAll(state)
  ),
);

export const issuesFeature = createFeature({
  name: issuesFeatureKey,
  reducer,
  extraSelectors: ({ selectIssuesState }) => ({
    ...adapter.getSelectors(selectIssuesState)
  }),
});

export const {
  selectIds: selectIssueIds,
  selectEntities: selectIssueEntities,
  selectAll: selectIssues,
  selectLoading: selectIssuesLoading,
  selectError: selectIssueError,
  selectQuery: selectIssuesQuery,
  selectTotal,
} = issuesFeature;

export const selectIssue = (id: string) => createSelector(
  selectIssueEntities,
  entities => entities[id]
)
