import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { Issue, IssueStatusEnum } from '../../_models/issues.model';
import { Machine } from '../../../machines/_models/machine.models';
import { IssuesService } from '../../_services/issues.service';

@Component({
  selector: 'app-issues-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, CardListItemComponent],
  templateUrl: './issues-list-item.component.html',
  styleUrl: './issues-list-item.component.scss'
})
export class IssuesListItemComponent {
  @Input() issue!: Issue;
  @Input() machine!: Machine;
  @Input() machineId: string | undefined;

  constructor(private service: IssuesService) {}

  get issueStatusColor(): string {
    return this.service.getIssueStatusColor(this.issue);
  }
}
