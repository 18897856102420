import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getLocalDate(date?: string) {
    let d = new Date(Date.now());
    if (date) {
      d = new Date(date);
    }
    d.setTime(d.getTime() - d.getTimezoneOffset() * 60000);
    return d.toISOString().slice(0, -8);
  }

  getUTCDate(date: string) {
    let d = new Date(date);
    return d.toISOString().slice(0, -8);
  }
}
