import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardListItemComponent } from '../../../shared/_components/card-list-item/card-list-item.component';
import { Transaction } from '../../_models/transactions.model';

@Component({
  selector: 'app-transactions-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, CardListItemComponent],
  templateUrl: './transactions-list-item.component.html',
  styleUrl: './transactions-list-item.component.scss'
})
export class TransactionsListItemComponent {
  @Input() transaction!: Transaction;
}
