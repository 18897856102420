import { Injectable } from '@angular/core';
import { SupabaseService } from '../../shared/_services/supabase.service';
import { QueryService } from '../../shared/_services/query.service';
import { SupabaseClient } from '@supabase/supabase-js';
import { State } from '../_store/issue.reducer';
import { Issue, IssueStatusEnum } from '../_models/issues.model';
import { ApiErrorService } from '../../shared/_services/api-error.service';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {
  private supabase: SupabaseClient;

  constructor(
    private sbService: SupabaseService,
    private queryService: QueryService,
    private apiErrorService: ApiErrorService,
  ) { this.supabase = this.sbService.supabase }

  async getIssues(config: State['query'] | undefined): Promise<Issue[]> {
    let query = this.supabase.from('issues').select(`
      id,
      machine_id,
      machine: machine_id (id, name),
      service_id,
      service_issue,
      details,
      issue_status,
      created_at,
      updated_at
    `);
    if (config) {
      query = this.queryService.applyQueryConfig(query, config);
    }
    const { data, error } = await query;
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Issues');
      throw error;
    };
    return data as any as Issue[];
  }

  async getIssueById(id: string): Promise<Issue> {
    const { data, error } = await this.supabase.from('issues').select(`
      id,
      machine_id,
      machine: machine_id (id, name),
      service_id,
      service_issue,
      details,
      issue_status,
      created_at,
      updated_at
    `).eq('id', id).single();
    if (error) {
      this.apiErrorService.handleApiError(error, 'Get Issue by Id');
      throw error;
    };
    return data as any as Issue;
  }

  async createIssue(issue: Issue) {
    const { data, error } = await this.supabase.from('issues').insert(this.stripIssueToSubmit(issue));
    if (error) {
      this.apiErrorService.handleApiError(error, 'Create Issue');
      throw error;
    };
    return data;
  }

  async updateIssue(issue: Issue) {
    const { data, error } = await this.supabase
      .from('issues')
      .update(this.stripIssueToSubmit(issue))
      .eq('id', issue.id).returns<Issue>();
      if (error) {
        this.apiErrorService.handleApiError(error, 'Update Issue');
        throw error;
      };
    return data;
  }

  async deleteIssue(id: string) {
    const { data, error } = await this.supabase.from('issues').delete().eq('id', id);
    if (error) {
      this.apiErrorService.handleApiError(error, 'Delete Issue');
      throw error;
    };
    return data;
  }

  stripIssueToSubmit(issue: Issue): Issue {
    return {
      id: issue.id || undefined,
      machine_id: issue.machine_id,
      service_id: issue.service_id,
      service_issue: issue.service_issue,
      details: issue.details,
      issue_status: issue.issue_status
    };
  }

  getIssueStatusColor(issue: Issue): string {
    switch (issue.issue_status) {
      case IssueStatusEnum.RESOLVED:
        return 'bg-green-500';
      case IssueStatusEnum.OPEN:
        return 'bg-red-500';
      case IssueStatusEnum.CLOSED_WONT_FIX:
        return 'bg-gray-500';
      default:
        return 'bg-red-500';
    }
  }


}
