<app-full-page>
  <div [ngClass]="{'flex': previousStep, 'hidden': !previousStep}" (click)="goToPreviousStep()" class="fixed justify-center items-center pt-safe-top top-2 h-12 w-full active:bg-blue-800 text-gray-300">
    <i class="fa-solid fa-chevron-up text-3xl"></i>
  </div>
  <div class="fixed flex flex-col w-full pt-safe-top top-12 bottom-12"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd()"
  >
    @for (step of serviceGuide; track step) {
      <div *ngIf="step.id === currentStep" [@slideUpDownAnimation]="{value: 'slideUpDown', params: provideOffsets }" class="bg-blue-900 fixed p-4 w-full">
        <h1 class="text-yellow-400 font-semibold text-3xl mb-8">{{step.name}}</h1>
        @if (step.action == serviceSteps.IDENTIFY_MACHINE) {
          <app-machines-list-select
            [machineId]="machineId"
            (onSelectMachine)="selectMachine($event)"
          ></app-machines-list-select>
        } @else if (step.action === serviceSteps.RECORD_SALES) {
          <app-sale-edit [serviceMachineId]="machineId" [serviceId]="id" [newService]="isNewSale" (onSaleSave)="onSaleSave($event)"></app-sale-edit>
        } @else if (step.action === serviceSteps.EDIT_INVENTORY) {
          <app-transaction-edit [serviceMachineId]="machineId" [serviceId]="id" [newService]="isNewTransaction" (onTransactionSave)="onTransactionSave($event)"></app-transaction-edit>
        } @else if (step.action === serviceSteps.REPORT_ISSUE) {
          <app-service-issues [serviceMachineId]="machineId" [serviceId]="id" [newService]="isNewIssues"></app-service-issues>
        } @else if (step.checklist) {
          <app-service-checklist [checklist]="step.checklist" (checklistChange)="checklistChange($event)"></app-service-checklist>
        }
      </div>
    }
  </div>
  <div [ngClass]="{'flex': nextStep, 'hidden': (!nextStep || nextStep === 2 || (nextStep === 4 && isNewSale))}" (click)="goToNextStep()" class="fixed flex justify-center items-center bottom-0 h-12 w-full active:bg-blue-800 text-gray-300 pb-safe-bottom">
    <i class="fa-solid fa-chevron-down text-3xl"></i>
  </div>
  <div
    *ngIf="currentStep === 13"
    (click)="completeService()"
    class="fixed flex justify-center align-middle items-center bottom-0 h-12 w-full bg-green-700 text-white pb-safe-bottom pt-safe-bottom">
      COMPLETE SERVICE
  </div>
</app-full-page>
